import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaCaretRight } from "react-icons/fa";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  FaEdit,
  FaEye,
  FaMobile,
  FaPhone,
  FaRupeeSign,
  FaTrash,
  FaUser,
  FaUserAlt,
  FaUserCircle,
  FaWindowClose,
} from "react-icons/fa";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";

export default function Admin() {
  const [UserName, setUserName] = useState();
  const [Email, setEmail] = useState();
  const [Mobile, setMobile] = useState();
  const [Gender, setGender] = useState();
  const [Password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [UserType, setUserType] = useState("Admin");
  const [UsersAccountSetting, setUsersAccountSetting] = useState(0);
  const [LeadManagement, setLeadManagement] = useState(0);
  const [ContactManagement, setContactManagement] = useState(0);
  const [SalesDepartment, setSalesDepartment] = useState(0);
  const [ProjectManagement, setProjectManagement] = useState(0);
  // const [ProjectName,setProject]=useState(0)
  const [CstmrManagement, setCstmrManagement] = useState(0);
  const [StoresManagement, setStoresManagement] = useState(0);
  const [MIS_Report, setMIS_Report] = useState(0);
  const [Analitics, setAnalitics] = useState(0);
  const [id, setId] = useState();
  const [control, setControl] = useState();
  const [Project, setProject] = useState("");

  const handleChangeProject = (e) => {
    setProject(e.target.value);
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [subValue, setSubValue] = React.useState("1");
  const handleSubChange = (event, newSubValue) => {
    setSubValue(newSubValue);
  };

  // https://buildfastapi.kollagroup.in/account-setting/api/v1/add-account
  // https://buildfastapi.kollagroup.in/account-setting/api/v1/
  console.log(Project);

  const add_account = () => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/account-setting/api/v1/add-account",
        {
          UserName,
          Email,
          Mobile,
          Gender,
          Password,
          UserType,
          UsersAccountSetting,
          LeadManagement: isLeadManagement,
          ContactManagement: isCustomerManagement,
          ProjectManagement,
          ProjectName: Project,
          CstmrManagement,
          MIS_Report,
          Storemanagment: StoresManagement,
          Analitics,
          Add: isadd,
          Delete: isdelete,
          Edit: isupdate,
          View: isview,
          Primary: isPrimary,
          Secondary: isSecondary,
          Allocation: isAllocation,
          Customers: isCustomers,
          Billing: isBilling,
          Payment: isPayment,
          PurchaseMaster: isPurchaseMaster,
          PurchaseEntry: isPurchaseEntry,
          StoresEntry: isStoresEntry,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          get_account();
          Swal.fire({
            icon: "success",
            title: "Success !",
            text: "Data added successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              // window.location.reload()
              setValidated(false);

              return;
            }
          });
        }
      });
  };

  const [response, setResponse] = useState([]);

  const get_account = () => {
    axios
      .get(
        "https://buildfastapi.kollagroup.in/account-setting/api/v1/get-account"
      )
      .then((res) => {
        if (res.data.status === 200) {
          setResponse(res.data.data);
        }
      });
  };

  React.useEffect(() => {
    get_account();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);
  const [isVeiw, setIsVeiw] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setValidated(false);
  };

  const [Validated, setValidated] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_account();
    }

    setValidated(true);
  };

  const deleteAlert = (id) => {
    handleClose2();
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to delete this data",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        basicDataDelete(id);
      }
    });
  };

  const basicDataDelete = (id) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/account-setting/api/v1/delete-account",
        {
          id,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          get_account();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Basic data is deleted successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const editAlert = (Id) => {
    setOpen1(false);
    handleClose2();
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to edit this data",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        accountDataEdit(id);
      }
    });
  };

  const accountDataEdit = (id) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/account-setting/api/v1/account-edit",
        {
          UserName,
          Email,
          Mobile,
          Gender,
          Password,
          UserType,
          UsersAccountSetting,
          LeadManagement,
          ContactManagement,
          ProjectManagement,
          CstmrManagement,
          MIS_Report,
          Analitics,
          id,
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.status === 200) {
            get_account();
            setIsVeiw(false);
            handleClose();
            Swal.fire({
              icon: "success",
              title: "Successfull !",
              text: "Basic data is updated successfully !",
              confirmButtonColor: "rgb(126, 110, 228)",
              cancelButtonColor: "#d33",
              showCancelButton: false,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                return;
              }
            });
          }
        }
      });
  };
  const [open4, setOpen4] = useState(false);
  const [open1, setOpen1] = useState(false);

  const viewData = () => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/account-setting/api/v1/view-account",
        {
          id,
        }
      )
      .then((res) => {
        setUserName(res.data.UserName);
        setEmail(res.data.Email);
        setMobile(res.data.Mobile);
        setGender(res.data.Gender);
        setPassword(res.data.Password);
        setUserType(res.data.UserType);
        setUsersAccountSetting(res.data.UsersAccountSetting);
        setLeadManagement(res.data.LeadManagement);
        setContactManagement(res.data.ContactManagement);
        setProjectManagement(res.data.ProjectManagement);
        setCstmrManagement(res.data.CstmrManagement);
        setStoresManagement(res.data.StoresManagement);
        setMIS_Report(res.data.MIS_Report);
        setAnalitics(res.data.Analitics);
      });
  };

  const searchaccount = (text) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/account-setting/api/v1/search-account",
        {
          text,
        }
      )
      .then((res) => {
        setResponse(res.data);
      });
  };

  const [isadd, setIsadd] = useState(0);
  const [isview, setIsView] = useState(0);
  const [isdelete, setIsdelete] = useState(0);
  const [isupdate, setIsupdate] = useState(0);

  const viewhandle = () => {
    setIsView(isview === 1 ? 0 : 1);
    console.log("check", isview === 1 ? 0 : 1);
  };

  const addhandle = () => {
    setIsadd(isadd === 1 ? 0 : 1);
    console.log("check", isadd === 1 ? 0 : 1);
  };

  const updatehandle = () => {
    setIsupdate(isupdate === 1 ? 0 : 1);
    console.log("check", isupdate === 1 ? 0 : 1);
  };

  const deletehandle = () => {
    setIsdelete(isdelete === 1 ? 0 : 1);
    console.log("check", isdelete === 1 ? 0 : 1);
  };

  const [open3, setOpen3] = useState(false);

  const handleClose3 = () => {
    setOpen3(false);
  };

  const [isLeadManagement, setIsLeadManagement] = useState(0);
  const [isPrimary, setIsPrimary] = useState(0);
  const [isSecondary, setIsSecondary] = useState(0);
  const [isAllocation, setIsAllocation] = useState(0);

  const [isCustomerManagement, setIsCustomerManagement] = useState(0);

  const [isCustomers, setIsCustomers] = useState(0);
  const [isBilling, setIsBilling] = useState(0);
  const [isPayment, setIsPayment] = useState(0);

  // const handleCheckbox1Change = (type) => {
  //     switch (type) {
  //         case 'LeadManagement':
  //             setIsCustomerManagement(!isCustomerManagement);
  //             setIsPrimary(!isCustomerManagement);
  //             setIsSecondary(!isCustomerManagement);
  //             setIsAllocation(!isCustomerManagement);
  //             break;
  //         case 'Customers':
  //             setIsCustomers(!isCustomers);
  //             break;
  //         case 'Billing':
  //             setIsBilling(!isBilling);
  //             break;
  //         case 'Payment':
  //             setIsPayment(!isPayment);
  //             break;
  //         default:
  //             break;
  //     }
  // };
  const [isStoresManagement, setIsStoresManagement] = useState(0);
  const [isPurchaseMaster, setIsPurchaseMaster] = useState(0);
  const [isPurchaseEntry, setIsPurchaseEntry] = useState(0);
  const [isStoresEntry, setIsStoresEntry] = useState(0);

  // const handleCheckbox2Change = (type) => {
  //     switch (type) {
  //         case 'LeadManagement':
  //             setIsCustomerManagement(!isStoresManagement);
  //             setIsPurchaseMaster(!isStoresManagement);
  //             setIsPurchaseEntry(!isStoresManagement);
  //             setIsPurchaseEntry(!isStoresManagement);
  //             break;
  //         case 'Purchase Master':
  //             setIsPurchaseMaster(!isPurchaseMaster);
  //             break;
  //         case 'Purchase Entry':
  //             setIsPurchaseEntry(!isPurchaseEntry);
  //             break;
  //         case 'Stores Entry':
  //             setIsStoresEntry(!isStoresEntry);
  //             break;
  //         default:
  //             break;
  //     }
  // };

  const [page, setPage] = useState(5);
  const [cpage, setCpage] = useState(1);

  const pagehandle = (e, val) => {
    setCpage(val);
  };

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = response.slice(indexOffpage, indexOflpage);

  const [projectName, setProjectName] = useState([]);

  const getProjectNames = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic")
      .then((res) => {
        setProjectName(res.data.data);
        console.log(projectName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getProjectNames();
  }, []);

  // Sample list

  // Convert list to JSON string and store in sessionStorage


   sessionStorage.setItem("project_name", JSON.stringify(projectName));
  console.log(sessionStorage.getItem("project_name"));
  // Filter users based on selected project
  // const filteredUsers = selectedProject
  //   ? users.filter((user) => user.ProjectName === selectedProject)
  //   : users;


  const [ProjectUser,setProjectUsers]=useState('')
  


  const searchProjectWiseCustomers = () => {
    console.log(`Searching for project: ${Project}`); // Log the project name

    axios
      .get(
        `https://buildfastapi.kollagroup.in/account-setting/api/v1/users/${ProjectUser}`
      )
      .then((res) => {
        console.log(`Received data:`, res.data);
        response(res.data);
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  React.useEffect(()=>{
    searchProjectWiseCustomers()
  },[])


  return (
    <div>
      {!isVeiw && (
        <div className="leadManagementTable">
          <Paper elevation={0}>
            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }}>
              <Typography
                style={{
                  color: "rgba(41, 16, 105, 1)",
                  marginBottom: 3,
                  marginLeft: 15.5,
                  fontSize: 18,
                  color: "gray",
                  fontWeight: "500",
                }}
              >
                Search Filter
              </Typography>

              <div
                style={{
                  height: "0.1rem",
                  borderBottom: "0.2px solid lightgray",
                }}
              ></div>
              <div
                className="card mt-2"
                style={{
                  border: "none",
                  paddingLeft: 15,
                  paddingRight: 15,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className='d-flex flex-row align-items-center'>
                  <input
                    onChange={(e) => searchaccount(e.target.value)}
                    style={{
                      height: "2.3rem",
                      color: "gray",
                      borderRadius: "0.3rem",
                      outline: "none",
                      width: "26rem",
                      border: "0.7px solid lightgray",
                    }}
                    placeholder="Search user.."
                  />
 <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              style={{ width: "350px",marginLeft:'25px' }}
                            >
                              <Typography
                                style={{ color: "gray", fontSize: 13 }}
                              >
                                Select Project
                              </Typography>
                              <Form.Select
                                value={ProjectUser}
                                onChange={(e) => setProjectUsers(e.target.value)}
                                style={{ padding: "0.42rem", marginBottom: 14 }}
                              >
                              
                              {projectName.map((item) => (
                              <option
                                key={item.ProjectName}
                                value={item.ProjectName}
                              >
                                {item.ProjectName}
                              </option>
                              ))}
                           
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please Enter Valid manager
                              </Form.Control.Feedback>
                            </Form.Group>


                </div>
                {(sessionStorage.getItem("Add") === "1" ||
                  sessionStorage.getItem("superAdmin")) && (
                  <div>
                    <Button
                      onClick={() => setOpen(true)}
                      style={{
                        width: "9rem",
                        height: "2.3rem",
                        backgroundColor: "rgb(126, 110, 228)",
                        color: "white",
                      }}
                    >
                      + Add User
                    </Button>
                  </div>
                )}
              </div>
              <Table style={{ position: "relative", top: "1.3rem" }}>
                <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                  <TableRow>
                    <TableCell
                      align={"start"}
                      style={{
                        minWidth: 0,
                        fontSize: 14,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      User
                    </TableCell>
                    <TableCell
                      align={"start"}
                      style={{
                        minWidth: 0,
                        fontSize: 14,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align={"start"}
                      style={{
                        minWidth: 0,
                        fontSize: 14,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align={"start"}
                      style={{
                        minWidth: 0,
                        fontSize: 14,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      Mobile
                    </TableCell>

                    <TableCell
                      align={"start"}
                      style={{
                        minWidth: 0,
                        fontSize: 14,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      Gender
                    </TableCell>

                    {/* <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Password
                                    </TableCell> */}

                    <TableCell
                      align={"start"}
                      style={{
                        minWidth: 0,
                        fontSize: 14,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      User Type
                    </TableCell>

                    <TableCell
                      align={"left"}
                      style={{
                        top: 21,
                        fontWeight: "600",
                        color: "gray",
                        fontSize: 13,
                        width: 21,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {response.filter((row=>row.ProjectName===ProjectUser)).map((row) => {
                    let color = "";
                    let widths = "";

                    if (row.lead === "Not Customer") {
                      color = "red";
                      widths = false;
                    }

                    if (row.lead === "Customer") {
                      color = "green";
                      widths = true;
                    }

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align={"start"} style={{ top: 57 }}>
                          <div
                            style={{
                              border: `3px solid rgb(126, 110, 228)`,
                              padding: "0.1rem",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              height: 35,
                              width: 37,
                              borderRadius: "50%",
                            }}
                          >
                            <FaUser
                              style={{
                                color: "rgb(126, 110, 228)",
                                fontSize: 20,
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12 }}
                        >
                          <button
                            className="shadow"
                            style={{
                              fontSize: 10.7,
                              border: "2px solid rgb(126, 110, 228)",
                              backgroundColor: "rgb(126, 110, 228)",
                              borderRadius: "1.2rem",
                              color: "white",
                              cursor: "auto",
                              paddingLeft: 4,
                              paddingRight: 4,
                            }}
                          >
                            {row.UserName}
                          </button>
                        </TableCell>
                        <TableCell align={"start"} style={{ fontSize: 12 }}>
                          <MailOutlineIcon
                            style={{
                              fontSize: 15,
                              marginTop: "-0.1rem",
                              marginRight: 7,
                            }}
                          />
                          {row.Email}{" "}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12 }}
                        >
                          <button
                            className="shadow"
                            style={{
                              fontSize: 10.7,
                              border: "2px solid rgb(126, 110, 228)",
                              backgroundColor: "rgb(126, 110, 228)",
                              borderRadius: "1.2rem",
                              color: "white",
                              cursor: "auto",
                              paddingLeft: 4,
                              paddingRight: 4,
                            }}
                          >
                            <FaPhone
                              style={{
                                marginRight: 3,
                                color: "white",
                                marginTop: "-0.16rem",
                                fontSize: 9,
                              }}
                            />
                            {row.Mobile}
                          </button>
                        </TableCell>
                        <TableCell align={"start"} style={{ fontSize: 12 }}>
                          {row.Gender}{" "}
                        </TableCell>
                        {/* <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'green', borderRadius: '1.2rem', color: 'white', cursor: 'auto', paddingLeft: 4, paddingRight: 4 }}><FaLock style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Password}</button>
                                                </TableCell> */}

                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12 }}
                        >
                          <button
                            style={{
                              fontSize: 10.7,
                              border: "2px solid rgb(126, 110, 228)",
                              backgroundColor: "white",
                              borderRadius: "1.2rem",
                              cursor: "auto",
                              paddingLeft: 4,
                              paddingRight: 4,
                            }}
                          >
                            <FaUserAlt
                              style={{
                                marginRight: 3,
                                color: "rgb(126, 110, 228)",
                                marginTop: "-0.16rem",
                                fontSize: 9,
                              }}
                            />
                            {row.UserType}
                          </button>
                        </TableCell>

                        <TableCell
                          align={"start"}
                          style={{
                            top: 57,
                            fontSize: 12,
                            color: "rgb(126, 110, 228) ",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {/* <Button onClick={() => allocateAlert(row.Id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Allocate</Button> */}
                            {/* <div>
                                                            <IconButton
                                                                id="fade-button"
                                                                aria-controls={open2 ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open2 ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    handleClick(e.currentTarget)
                                                                    setId(row.id)
                                                                }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open2}
                                                                onClose={handleClose2}
                                                                TransitionComponent={Fade}
                                                            >

                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {

                                                                    handleClose2()
                                                                    viewData(id)

                                                                }}><BorderColorIcon style={{ marginRight: 4 }} /></MenuItem>

                                                                <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><DeleteIcon style={{ marginRight: 4 }} /></MenuItem>
                                                            </Menu>
                                                        </div>  */}

                            <div>
                              <IconButton
                                id="fade-button"
                                aria-controls={open2 ? "fade-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open2 ? "true" : undefined}
                                onClick={(e) => {
                                  handleClick(e.currentTarget);
                                  setId(row.id);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="fade-menu"
                                MenuListProps={{
                                  "aria-labelledby": "fade-button",
                                }}
                                anchorEl={anchorEl}
                                open={open2}
                                onClose={handleClose2}
                                TransitionComponent={Fade}
                              >
                                {(sessionStorage.getItem("Edit") === "1" ||
                                  sessionStorage.getItem("superAdmin")) && (
                                  <MenuItem
                                    style={{ color: "gray" }}
                                    onClick={() => {
                                      handleClose2();
                                      viewData(id);
                                      setOpen1(true);
                                    }}
                                  >
                                    <FaEdit style={{ marginRight: 4 }} /> Edit
                                  </MenuItem>
                                )}
                                {(sessionStorage.getItem("View") === "1" ||
                                  sessionStorage.getItem("superAdmin")) && (
                                  <MenuItem
                                    style={{ color: "gray" }}
                                    onClick={() => {
                                      viewData(id);
                                      handleClose2();
                                      setIsVeiw(true);
                                    }}
                                  >
                                    <FaEye style={{ marginRight: 4 }} /> View
                                  </MenuItem>
                                )}

                                {(sessionStorage.getItem("Delete") === "1" ||
                                  sessionStorage.getItem("superAdmin")) && (
                                  <MenuItem
                                    onClick={() => deleteAlert(id)}
                                    style={{ color: "gray" }}
                                  >
                                    <FaTrash style={{ marginRight: 4 }} />{" "}
                                    Delete
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {response.length === 0 && (
                <Box
                  className="card"
                  style={{
                    border: "none",
                    height: "58vh",
                    width: "100%",
                    boxShadow: "none",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
                    width="145px"
                  />
                  <Typography>0 customers</Typography>
                </Box>
              )}
            </TableContainer>
          </Paper>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: 56,
              marginTop: 16,
            }}
          >
            <Pagination
              count={Math.ceil(response.length / page)}
              page={cpage}
              onChange={pagehandle}
              color="secondary"
              sx={{ "& .Mui-selected": { backgroundColor: "blue" } }}
            />
          </div>
        </div>
      )}
      {isVeiw && (
        <div
          className="leadManagementTable leadManagementTable1 p-4"
          style={{
            backgroundColor: "white",
            borderRadius: "0.4rem",
            position: "relative",
          }}
        >
          <FaWindowClose
            onClick={() => {
              setIsVeiw(false);
            }}
            style={{
              position: "absolute",
              right: 10,
              top: 12,
              color: "rgb(126, 110, 228)",
              fontSize: 21,
              cursor: "pointer",
              zIndex: 10000,
            }}
          ></FaWindowClose>
          <div
            className="card"
            style={{
              backgroundColor: "white",
              borderRadius: "0.4rem",
              border: "none",
              boxShadow: "none",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 26,
                fontWeight: "bold",
                color: "rgb(126, 110, 228)",
              }}
            >
              <img
                src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                width="145px"
              />
            </Typography>
            <Typography>users information details</Typography>
          </div>
          <br />
          <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>
            <div
              className="card"
              style={{
                paddingTop: 43,
                border: "1px solid rgb(126, 110, 228)",
                borderRadius: "0.3rem",
                padding: 11,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                className="card"
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.4rem",
                  border: "none",
                  boxShadow: "none",
                  alignItems: "start",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                >
                  <span style={{ fontSize: 17, color: "black" }}>
                    UserName:
                  </span>{" "}
                  {UserName}
                </Typography>
                <Typography
                  style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                >
                  <span style={{ fontSize: 17, color: "black" }}>Email:</span>{" "}
                  {Email}
                </Typography>
                <Typography
                  style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                >
                  <span style={{ fontSize: 17, color: "black" }}>Mobile:</span>{" "}
                  {Mobile}
                </Typography>
                <Typography
                  style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                >
                  <span style={{ fontSize: 17, color: "black" }}>Gender:</span>{" "}
                  {Gender}
                </Typography>
                {/* <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Project Manager:</span> {ProjectManagement}</Typography> */}
                <Typography
                  style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                >
                  <span style={{ fontSize: 17, color: "black" }}>
                    Password:
                  </span>{" "}
                  {Password}
                </Typography>
                {/* <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Confirm Password:</span> {confirmPassword}</Typography> */}
                {/* <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Control:</span> {control}</Typography> */}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Dialog
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
          maxWidth="xl"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    color: "rgb(86, 63, 146)",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="secondary"
                  >
                    <Tab
                      label="Add Account"
                      value="1"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Permission"
                      value="2"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <div style={{ width: "41rem" }}>
                    <Form
                      noValidate
                      validated={Validated}
                      onSubmit={formValidation}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Add account
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            User Name
                          </Typography>
                          <Form.Control
                            required
                            value={UserName}
                            onChange={(e) => setUserName(e.target.value)}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Name
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Email
                          </Typography>
                          <Form.Control
                            required
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Email
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Mobile
                          </Typography>
                          <Form.Control
                            required
                            value={Mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Mobile
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <div class="radio-wrapper">
                            <div
                              className="radio-container d-flex"
                              style={{ marginBottom: 14 }}
                            >
                              <Typography
                                style={{ color: "gray", fontSize: 13 }}
                              >
                                GENDER
                              </Typography>
                              <div
                                style={{ marginRight: "5rem", marginLeft: 30 }}
                              >
                                <input
                                  onChange={(e) => setGender(e.target.value)}
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  id="male"
                                />
                                <label for="male">MALE</label>
                              </div>
                              <div>
                                <input
                                  onChange={(e) => setGender(e.target.value)}
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                />
                                <label for="female">FEMALE</label>
                              </div>
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Project Management
                          </Typography>
                          <Form.Select
                            value={UserType}
                            onChange={(e) => setUserType(e.target.value)}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          >
                            <option>User Type</option>
                            <option>Admin</option>
                            <option>Manager</option>
                            <option>Sr.Executive</option>
                            <option>Jr.Executive</option>
                            <option>Accountant</option>
                            <option>Sales Executive</option>
                            <option>Sales Manager</option>
                            <option>Sales Head</option>
                            <option>CRM Head</option>
                            <option>CRM Executive</option>
                            <option>Agent</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid manager
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Select Project
                          </Typography>
                          <Form.Select
                            value={Project}
                            onChange={handleChangeProject}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          >
                            <option>Select Project</option>
                            {projectName.map((item) => (
                              <option
                                key={item.ProjectName}
                                value={item.ProjectName}
                              >
                                {item.ProjectName}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid manager
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography
                            htmlFor="inputPassword5"
                            style={{ color: "gray", fontSize: 13 }}
                          >
                            Password
                          </Typography>
                          <Form.Control
                            type="password"
                            id="inputPassword5"
                            // pattern='/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(paswrd)'
                            aria-describedby="passwordHelpBlock"
                            onChange={(e) => setPassword(e.target.value)}
                            value={Password}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please Enter valid Password
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography
                            htmlFor="inputPassword5"
                            style={{ color: "gray", fontSize: 13 }}
                          >
                            {" "}
                            Confirm Password
                          </Typography>
                          <Form.Control
                            type="password"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter valid Confirm Password
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Typography
                          style={{
                            fontSize: 21,
                            borderBottom: "1px solid black",
                          }}
                        >
                          Control
                        </Typography>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography>Lead Management:</Typography>
                            <input
                              onChange={(e) =>
                                setIsLeadManagement(e.target.checked ? 1 : 0)
                              }
                              checked={isLeadManagement === 1}
                              type="checkbox"
                              id="primaryCheckbox"
                              name="primaryCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Primary</Typography>
                            <input
                              onChange={(e) =>
                                setIsPrimary(e.target.checked ? 1 : 0)
                              }
                              checked={isPrimary === 1}
                              type="checkbox"
                              id="primaryCheckbox"
                              name="primaryCheckbox"
                            />
                          </div>
                          {/* <div>
                                                                    <Typography>Secondary</Typography>
                                                                    <input
                                                                        onChange={(e) => setIsSecondary(e.target.checked ? 1 : 0)}
                                                                        checked={isSecondary === 1}
                                                                        type="checkbox"
                                                                        id="secondaryCheckbox"
                                                                        name="secondaryCheckbox"
                                                                    />
                                                                </div> */}
                          <div>
                            <Typography>Allocation</Typography>
                            <input
                              onChange={(e) =>
                                setIsAllocation(e.target.checked ? 1 : 0)
                              }
                              checked={isAllocation}
                              type="checkbox"
                              id="allocationCheckbox"
                              name="allocationCheckbox"
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                           <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Customr Management</Typography>
                          <input
                            onChange={(e) =>
                              setCstmrManagement(e.target.checked ? 1 : 0)
                            }
                            checked={CstmrManagement === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>
                          <div>
                            <Typography>Customers</Typography>
                            <input
                              onChange={(e) =>
                                setIsCustomers(e.target.checked ? 1 : 0)
                              }
                              checked={isCustomers}
                              type="checkbox"
                              id="customersCheckbox"
                              name="customersCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Billing</Typography>
                            <input
                              onChange={(e) =>
                                setIsBilling(e.target.checked ? 1 : 0)
                              }
                              checked={isBilling}
                              type="checkbox"
                              id="billingCheckbox"
                              name="billingCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Payment</Typography>
                            <input
                              onChange={(e) =>
                                setIsPayment(e.target.checked ? 1 : 0)
                              }
                              checked={isPayment}
                              type="checkbox"
                              id="paymentCheckbox"
                              name="paymentCheckbox"
                            />
                          </div>
                        </div>
                        
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography>Stores Management:</Typography>
                            <input
                              onChange={(e) =>
                                setIsStoresManagement(e.target.checked ? 1 : 0)
                              }
                              checked={isStoresManagement === 1}
                              type="checkbox"
                              id="primaryCheckbox"
                              name="primaryCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Purchase Master</Typography>
                            <input
                              onChange={(e) =>
                                setIsPurchaseMaster(e.target.checked ? 1 : 0)
                              }
                              checked={isPurchaseMaster}
                              type="checkbox"
                              id="purchasemasterCheckbox"
                              name="purchasemasterCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Purchase Entry</Typography>
                            <input
                              onChange={(e) =>
                                setIsPurchaseEntry(e.target.checked ? 1 : 0)
                              }
                              checked={isPurchaseEntry}
                              type="checkbox"
                              id="purchaseentryCheckbox"
                              name="purchaseentryCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Stores Entry</Typography>
                            <input
                              onChange={(e) =>
                                setIsStoresEntry(e.target.checked ? 1 : 0)
                              }
                              checked={isStoresEntry}
                              type="checkbox"
                              id="storesentryCheckbox"
                              name="storesentryCheckbox"
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Users Account Setting</Typography>
                          <input
                            onChange={(e) =>
                              setUsersAccountSetting(e.target.checked ? 1 : 0)
                            }
                            checked={UsersAccountSetting === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                          />
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Sales Department</Typography>
                          <input
                            onChange={(e) => setSalesDepartment(e.target.value)}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>
                        {/* 
                                                            <div style={{ width: '50%', display: 'flex', marginTop: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography >Project Management</Typography>
                                                                <input onChange={(e) => setProjectManagement(e.target.checked ? 1 : 0)} checked={ProjectManagement === 1} type="checkbox" id="vehicle1" name="vehicle1" value={1} />
                                                            </div> */}
                        {/* <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Customr Management</Typography>
                          <input
                            onChange={(e) =>
                              setCstmrManagement(e.target.checked ? 1 : 0)
                            }
                            checked={CstmrManagement === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div> */}
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Stores Management</Typography>
                          <input
                            onChange={(e) =>
                              setStoresManagement(e.target.checked ? 1 : 0)
                            }
                            checked={StoresManagement === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                          />
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>MIS Report</Typography>
                          <input
                            onChange={(e) =>
                              setMIS_Report(e.target.checked ? 1 : 0)
                            }
                            checked={MIS_Report === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>
                        <div
                          className="mb-3"
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Analitics</Typography>
                          <input
                            onChange={(e) =>
                              setAnalitics(e.target.checked ? 1 : 0)
                            }
                            checked={Analitics === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>
                      </Row>
                      <div className="d-flex">
                        <div>
                          <Button
                            type="submit"
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              setOpen(false);
                              setValidated(false);
                            }}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </TabPanel>
                {/* <FaCaretRight onClick={() => window.location.reload()} style={{ marginTop: '-0.3rem', cursor: 'pointer', color: 'rgb(126, 110, 228)' }} /> */}
                <TabPanel value="2">
                  <TabContext value={subValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleSubChange}
                        aria-label="lab API sub tabs example"
                        indicatorColor="secondary"
                      >
                        <Tab
                          label="Admin"
                          value="1"
                          disabled={UserType !== "Admin"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="Manager"
                          value="2"
                          disabled={UserType !== "Manager"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <br />
                        <Tab
                          label="Sr.Executive"
                          value="3"
                          disabled={UserType !== "Sr.Executive"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="Jr.Executive"
                          value="4"
                          disabled={UserType !== "Jr.Executive"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6em",
                          }}
                        />
                        <Tab
                          label="Accountant"
                          value="5"
                          disabled={UserType !== "Accountant"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="Sales Executive"
                          value="6"
                          disabled={UserType !== "Sales Executive"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="Sales Manager"
                          value="7"
                          disabled={UserType !== "Sales Manager"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="Sales Head"
                          value="8"
                          disabled={UserType !== "Sales Head"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="CRM Head"
                          value="9"
                          disabled={UserType !== "CRM Head"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="CRM Executive"
                          value="10"
                          disabled={UserType !== "CRM Executive"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                        <Tab
                          label="Agent"
                          value="11"
                          disabled={UserType !== "Agent"}
                          style={{
                            color: "rgb(86, 63, 146)",
                            fontSize: "0.6rem",
                          }}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div
                        className="d-flex flex-column gap-2 "
                        disabled={UserType !== "Admin"}
                      >
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={viewhandle}
                            checked={isview === 1}
                            disabled={UserType !== "Admin"}
                            id="View"
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={addhandle}
                            checked={isadd === 1}
                            disabled={UserType !== "Admin"}
                            id="Add"
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            disabled={UserType !== "Admin"}
                            id="Edit"
                            name="Edit"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            disabled={UserType !== "Admin"}
                            id="Delete"
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2" disabled={UserType !== "Manager"}>
                      {UserType}
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={viewhandle}
                            checked={isview === 1}
                            disabled={UserType !== "Manager"}
                            id="View"
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={addhandle}
                            checked={isadd === 1}
                            disabled={UserType !== "Manager"}
                            id="Add"
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            disabled={UserType !== "Manager"}
                            id="Update"
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            disabled={UserType !== "Manager"}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="5">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="6">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="7">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="8">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="9">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="10">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="11">
                      <div className="d-flex flex-column gap-2 ">
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="View"
                            onChange={viewhandle}
                            checked={isview === 1}
                            name="View"
                          />
                          <label htmlFor="View">View</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Add"
                            onChange={addhandle}
                            checked={isadd === 1}
                            name="Add"
                          />
                          <label htmlFor="Add">Add</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Update"
                            onChange={updatehandle}
                            checked={isupdate === 1}
                            name="Update"
                          />
                          <label htmlFor="Update">Update</label>
                        </div>
                        <div className="d-flex align-items-baseline gap-2">
                          <input
                            type="checkbox"
                            id="Delete"
                            onChange={deletehandle}
                            checked={isdelete === 1}
                            name="Delete"
                          />
                          <label htmlFor="Delete">Delete</label>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </TabPanel>
              </TabContext>
            </Box>
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
          maxWidth="xl"
          open={open1}
          onClose={() => setOpen1(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    color: "rgb(86, 63, 146)",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="secondary"
                  >
                    <Tab
                      label="Add Account"
                      value="1"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Permission"
                      value="2"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <div style={{ width: "41rem" }}>
                    <Form noValidate>
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Update account
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            User Name{UserName}
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setUserName(e.target.value)}
                            value={UserName}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Name
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Email
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={Email}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Email
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Mobile
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setMobile(e.target.value)}
                            type="text"
                            value={Mobile}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Mobile
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <div class="radio-wrapper">
                            <div
                              className="radio-container d-flex"
                              style={{ marginBottom: 14 }}
                            >
                              <Typography
                                style={{ color: "gray", fontSize: 13 }}
                              >
                                GENDER
                              </Typography>
                              <div
                                style={{ marginRight: "5rem", marginLeft: 30 }}
                              >
                                <input
                                  onChange={(e) => setGender(e.target.value)}
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  id="male"
                                />
                                <label for="male">MALE</label>
                              </div>
                              <div>
                                <input
                                  onChange={(e) => setGender(e.target.value)}
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                />
                                <label for="female">FEMALE</label>
                              </div>
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Project Manager
                          </Typography>
                          <Form.Select
                            required
                            onChange={(e) => setUserType(e.target.value)}
                            value={UserType}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          >
                            <option disabled={true}>User Type</option>
                            <option>Admin</option>
                            <option>Manager</option>
                            <option>Sr.Executive</option>
                            <option>Jr.Executive</option>
                            <option>Accountant</option>
                            <option>Sales Executive</option>
                            <option>Sales Manager</option>
                            <option>Sales Head</option>
                            <option>CRM Head</option>
                            <option>CRM Executive</option>
                            <option>Agent</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid manager
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Password
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            type="text"
                            value={Password}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter valid Password
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Typography
                          style={{
                            fontSize: 21,
                            borderBottom: "1px solid black",
                          }}
                        >
                          Control
                        </Typography>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography>Lead Management:</Typography>
                          </div>
                          <div>
                            <Typography>Primary</Typography>
                            <input
                              onChange={() => handleCheckboxChange("Primary")}
                              checked={isPrimary}
                              type="checkbox"
                              id="primaryCheckbox"
                              name="primaryCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Secondary</Typography>
                            <input
                              onChange={() => handleCheckboxChange("Secondary")}
                              checked={isSecondary}
                              type="checkbox"
                              id="secondaryCheckbox"
                              name="secondaryCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Allocation</Typography>
                            <input
                              onChange={() =>
                                handleCheckboxChange("Allocation")
                              }
                              checked={isAllocation}
                              type="checkbox"
                              id="allocationCheckbox"
                              name="allocationCheckbox"
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography>Customer Management:</Typography>
                          </div>
                          <div>
                            <Typography>Customers</Typography>
                            <input
                              onChange={() =>
                                handleCheckbox1Change("Customers")
                              }
                              checked={isCustomers}
                              type="checkbox"
                              id="customersCheckbox"
                              name="customersCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Billing</Typography>
                            <input
                              onChange={() => handleCheckbox1Change("BIlling")}
                              checked={isBilling}
                              type="checkbox"
                              id="billingCheckbox"
                              name="billingCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Payment</Typography>
                            <input
                              onChange={() => handleCheckbox1Change("Payment")}
                              checked={isPayment}
                              type="checkbox"
                              id="paymentCheckbox"
                              name="paymentCheckbox"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Typography>Stores Management:</Typography>
                          </div>
                          <div>
                            <Typography>Purchase Master</Typography>
                            <input
                              onChange={() =>
                                handleCheckbox2Change("Purchase Master")
                              }
                              checked={isPurchaseMaster}
                              type="checkbox"
                              id="purchasemasterCheckbox"
                              name="purchasemasterCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Purchase Entry</Typography>
                            <input
                              onChange={() =>
                                handleCheckbox2Change("Purchase Entry")
                              }
                              checked={isPurchaseEntry}
                              type="checkbox"
                              id="purchaseentryCheckbox"
                              name="purchaseentryCheckbox"
                            />
                          </div>
                          <div>
                            <Typography>Stores Entry</Typography>
                            <input
                              onChange={() =>
                                handleCheckbox2Change("Stores Entry")
                              }
                              checked={isStoresEntry}
                              type="checkbox"
                              id="storesentryCheckbox"
                              name="storesentryCheckbox"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Users Account Setting</Typography>
                          <input
                            onChange={(e) =>
                              setUsersAccountSetting(e.target.checked ? 1 : 0)
                            }
                            checked={UsersAccountSetting === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                          />
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Sales Department</Typography>
                          <input
                            onChange={(e) => setSalesDepartment(e.target.value)}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>

                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Project Management</Typography>
                          <input
                            onChange={(e) =>
                              setProjectManagement(e.target.checked ? 1 : 0)
                            }
                            checked={ProjectManagement === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>

                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>MIS Report</Typography>
                          <input
                            onChange={(e) =>
                              setMIS_Report(e.target.checked ? 1 : 0)
                            }
                            checked={MIS_Report === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>
                        <div
                          className="mb-3"
                          style={{
                            width: "50%",
                            display: "flex",
                            marginTop: 4,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Analitics</Typography>
                          <input
                            onChange={(e) =>
                              setAnalitics(e.target.checked ? 1 : 0)
                            }
                            checked={Analitics === 1}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={1}
                          />
                        </div>
                      </Row>
                      <div className="d-flex">
                        <div>
                          <Button
                            onClick={() => editAlert(id)}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Update
                          </Button>
                        </div>
                        <div>
                          <Button
                            onClick={() => setOpen1(false)}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
