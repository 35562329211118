
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Row from "react-bootstrap/Row";
import {
  FaBlenderPhone,
  FaEdit,
  FaPhoneSquare,
  FaTrash,
  FaEye,
  FaWindowClose,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import { FaMobileAlt } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import './primary.css'
import CustomerDetailDialog from "./CustomerDetailDialog";


export default function LeadFollows() {
  const [id, setId] = useState();
  const [wapp, setWapp] = useState();
  const [isWhatsappNo, setIsWhatsappNo] = useState(false);
  const [whatsappMobile, setWhatsappMobile] = useState("");
  const handleWhatsappCheckboxChange = (event) => {
    setIsWhatsappNo(event.target.value === "No");
  };

  const [mobile, setMobile] = useState("");
  const [sourceType, setSourceType] = useState("Website");

  const [comment, setComment] = useState();
  const [location, setLocation] = useState();
  const [rname, setRName] = useState();
  const [rucin,setRucin]=useState()
  const [rmobile, setRMobile] = useState();
  const [showReferenceFields, setShowReferenceFields] = useState(false);
  const handleSourceTypeChange = (e) => {
    const selectedSourceType = e.target.value;
    setPst(selectedSourceType);
    setShowReferenceFields(selectedSourceType === "Reference");
  };

  const [intrest, setIntrest] = useState("");
  const [cpName, setCPName] = useState("");
  const [cpMobile,setCPMobile]=useState("")
  const [cpId, setCPId] = useState("");

  const handleIntrestChange = (e) => {
    const selectedIntrest = e.target.value;
    setIntrest(selectedIntrest);
    // Reset the CP Name and CP ID fields when selecting "No"
    if (selectedIntrest === "No") {
      setCPName("");
      setCPId("");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setValidated(false);
  };
  const [Validated, setValidated] = useState(false);

  const [open1, setOpen1] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // intrstTab()
      add_basic();
    }

    setValidated(true);
  };

  const [Validatedtabi, setValidatedtabi] = useState(false);

  const formValidationtabi = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      intrstTab();
    }

    setValidatedtabi(true);
  };

  const BulkUpload=()=>{

  }

  const [Validatedtabin, setValidatedtabin] = useState(false);

  const formValidationtabin = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      notintrstTab();
    }

    setValidatedtabin(true);
  };
  const [Validatedtabcb, setValidatedtabcb] = useState(false);

  const formValidationtabcb = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      callbackTab();
    }

    setValidatedtabcb(true);
  };
  const [pname, setPname] = useState("");
  const [Pemail, setPemail] = useState("");
  const [pmobile, setPmobile] = useState("");
  const [pgender, setPgender] = useState("");
  const [ptym, setPtym] = useState("");
  const [pst, setPst] = useState("");

  const viewData = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/view-lead", {
        id,
      })
      .then((res) => {
        console.log(res.data);
        setPname(res.data.name);
        setPemail(res.data.email);
        setPmobile(res.data.mobile);
        setPgender(res.data.gender);
        setPst(res.data.sourceType);
        setPtym(res.data.pretime);
      });
  };

  const PrName = sessionStorage.getItem("ProjectName");
  //   https://buildfastapi.kollagroup.in/api/v1/lead-managements

  const excutiveEmail = sessionStorage.getItem("emailExcutive");
  const excutiveName = sessionStorage.getItem("user_name");
  console.log(excutiveName)

  // https://buildfastapi.kollagroup.in/api/v1/lead-managements

  // const add_basic = () => {
  //   axios
  //     .post("https://buildfastapi.kollagroup.in/api/v1/lead-managements", {
  //       name: pname,
  //       email: Pemail,
  //       mobile: pmobile,
  //       gender: pgender,
  //       pretime: ptym,
  //       sourceType: pst,
  //       location,
  //       refName: rname,
  //       refucin:rucin,
  //       refMobile: rmobile,
  //       channelPartner: intrest,
  //       cpName,
  //       cpId,
  //       commentBox: comment,
  //       ProjectName: PrName,
  //       salesExecutiveEmail: excutiveEmail,

  //       salesExecutive: excutiveName,
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setOpen(false);
  //         getlm();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success !",
  //           text: "Data is added successfully !",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         }).then((result) => {
  //           if (result.value) {
  //             return;
  //           }
  //         });
  //       }
  //     });
  // };

  // https://buildfastapi.kollagroup.in/api/v1/lead-managements


  const add_basic = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/lead-managements", {
        name: pname,
        email: Pemail,
        mobile: pmobile,
        gender: pgender,
        pretime: ptym,
        sourceType: pst,
        location,
        refName: rname,
        refucin: rucin,
        refMobile: rmobile,
        channelPartner: intrest,
        cpName,
        cpId,
        commentBox: comment,
        ProjectName: PrName,
        salesExecutiveEmail: excutiveEmail,
        salesExecutive: excutiveName,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          getlm();
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data is added successfully!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          // Handle conflict case
          Swal.fire({
            icon: "error",
            title: "Conflict!",
            text: "A lead with this email or mobile number already exists.",
            confirmButtonColor: "rgb(126, 110, 228)",
            confirmButtonText: "Ok",
          });
        } else {
          // Handle other errors
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred. Please try again later.",
            confirmButtonColor: "rgb(126, 110, 228)",
            confirmButtonText: "Ok",
          });
        }
      });
  };
  

  const editlead = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/lead-edit", {
        name: pname,
        email: Pemail,
        mobile: pmobile,
        gender: pgender,
        pretime: ptym,
        sourceType: pst,
        id,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          getlm();
        }
      });
  };

  const add_primary = () => {
    setOpen(false);
    Swal.fire({
      icon: "success",
      title: "Success !",
      text: "Data added successfully !",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })``;
  };

  const editAlert = () => {
    setOpen1(false);
    handleClose2();
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to edit this data",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        editlead(id);
      }
    });
  };

  const deleteAlert = (id) => {
    handleClose2();
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to delete this data",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        leadDataDelete(id);
      }
    });
  };

  const leadDataDelete = (id) => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/delete-lead", {
        id,
      })
      .then((res) => {
        if (res.data.status === 200) {
          getlm();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Lead is deleted successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  // buildfastapi.kollagroup.in/api/v1/

  const [data, setData] = useState([]);

  localStorage.setItem('adminCount',data.length)

  const getlm = () => {
    axios.get("https://buildfastapi.kollagroup.in/api/v1/lead-data").then((res) => {
      setData(res.data.data);
      console.log(res.data.data);
    });
  };



  React.useEffect(() => {
    getlm();
  }, []);


  


  // https://buildfastapi.kollagroup.in/api/v1

  const [isVeiw, setIsVeiw] = useState(false);

  const leadDataSearch = (text) => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/search-lead", {
        text,
      })
      .then((res) => {
        setData(res.data);
      });
  };

  const [page, setPage] = useState(5);
  const [cpage, setCpage] = useState(1);

  const pagehandle = (e, val) => {
    setCpage(val);
  };

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = data.slice(indexOffpage, indexOflpage);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openi, setOpeni] = React.useState(false);

  const handleClickOpeni = () => {
    setOpeni(true);
  };

  const handleClosei = () => {
    setOpeni(false);
  };

  const [openin, setOpenin] = React.useState(false);

  const handleClickOpenin = () => {
    setOpenin(true);
  };

  const handleClosein = () => {
    setOpeni(false);
  };

  const [openc, setOpenc] = React.useState(false);

  const handleClickOpenc = () => {
    setOpenc(true);
  };

  const handleClosec = () => {
    setOpenc(false);
  };

  // https://buildfastapi.kollagroup.in/api/v1/intrst-tab

  const [idate, setIdate] = useState("");
  const [icomment, setIcomment] = useState("");

  const intrstTab = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/intrst-tab", {
        id,
        intrestDate: idate,
        intrestComment: icomment,
      })
      .then((res) => {
        if (res.status === 200) {
          getlm();
          handleClosei();

          setValidatedtabi(false);
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Selected customer is interested!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  // https://buildfastapi.kollagroup.in/api/v1/notintrst-tab

  const [incb, setIncb] = useState("");
  // const [reasonNotIntr,setReasonNotIntr]=useState("")
  const [reasonNotIntr, setReasonNotIntr] = useState('');
  const [saveForLater, setSaveForLater] = useState(false);

  // Handle change for the dropdown
  const handleReasonChange = (e) => {
    setReasonNotIntr(e.target.value);
    // Reset saveForLater checkbox when changing the reason
    if (e.target.value !== 'Save For Later') {
      setSaveForLater(false);
    }
  };

  // Handle change for the checkbox
  const handleCheckboxChange = () => {
    setSaveForLater(!saveForLater);
  };


  // https://buildfastapi.kollagroup.in/api/v1/notintrst-tab

  const notintrstTab = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/notintrst-tab", {
        id,
        notintrstComment: incb,
        notintrstReason:reasonNotIntr,
        saveforLater:saveForLater
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getlm();
          setOpenin(false);

          setValidatedtabin(false);
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Selected customer is Not-interested!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  // https://buildfastapi.kollagroup.in/api/v1/sitevisit-tab

  const [commentSiteVisit,setCommentSiteVisti]=useState('')

  const [intrstFloor,setIntrstFloor]=useState('')

const handleChangeIntrstFloor=(e)=>{
  setIntrstFloor(e.target.value)
}

// https://buildfastapi.kollagroup.in/api/v1/sitevisit-tab

  const siteVisitTab = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/sitevisit-tab", {
        id,
        Occupation: occupation,
        budget: budget,
        aptsize: apartsize,
        facing: apartfacing,
        cornor: Corner,
        ucon: underConstruction,
        rmdate: readyToMove,
        commentSiteVisit:commentSiteVisit,
        interestfloor:intrstFloor,
    
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getlm();
          setOpensv(false);

          setValidatedtabin(false);
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Selected customer is move Site Visit!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const [cbd, setCbd] = useState("");
  const [cbc, setCbc] = useState("");

  const callbackTab = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/callback-tab", {
        id,
        cbDate: cbd,
        cbComment: cbc,
      })
      .then((res) => {
        
        if (res.data.statusCode === 200) {
          getlm();
          handleClosec();
          setValidatedtabcb(false);
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Selected customer said to callBack!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const convrtintr = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/cnvrttinstrest", { id })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getlm();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Selected customer converted to intrest!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const [Validatedsv, setValidatedsv] = useState(false);

  const [opensv, setOpensv] = useState(false);
  const handleClickOpensv = () => {
    setOpensv(true);
  };

  const handleClosesv = () => {
    setOpensv(false);
  };

  const formValidationsv = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      siteVisitTab();
      // add_sitevisit()
    }

    setValidatedsv(true);
  };

  const [pexecutive, setPExecutive] = useState("");

  const [occupation, setOccupation] = useState();
  const [apartsize, setApartSize] = useState();
  const [apartfacing, setApartFacing] = useState();
  const [budget, setBudget] = useState();
  const [Corner, setCorner] = useState();

  const [flatDetails, setFlatDetails] = useState("");
  const [underConstruction, setUnderConstruction] = useState("");
  const [readyToMove, setReadyToMove] = useState("");
  const [nname, setNname] = useState("");
  const [nmobile, setNmobile] = useState("");
  const [nemail, setNemail] = useState("");
  const [nst, setNst] = useState("");
  const [leadloc, setLeadloc] = useState("");
  const [nrefname, setNrefname] = useState("");
  const [nrefmob, setNrefmob] = useState("");
  const [ncp, setNcp] = useState("");
  const [ncpname, setNcpname] = useState("");
  const [ncpid, setNcpid] = useState("");
  const [ncb, setNcb] = useState("");

  const handleFlatDetailsChange = (e) => {
    const selectedFlatDetails = e.target.value;
    setFlatDetails(selectedFlatDetails);
    // Reset the fields when changing the selected flat details
    setUnderConstruction("");
    setReadyToMove("");
  };

  const [res, setRes] = React.useState([]);

  const getLeadname = () => {
    axios.get("https://buildfastapi.kollagroup.in/api/v1/lead-data").then((res) => {
      setRes(res.data.data);
      // console.log("leaddata",res.data.data);
      // setIsFilter(true)
    });
  };

  React.useEffect(() => {
    getLeadname();
    get_units();
    // customerObjsi()
    get_unit();
    get_floor();
    get_floorf();
    customerObjsib();
    customerObj();
  }, []);

  const [typicaluzdrop, setTypicaluzdrop] = useState([]);

  const get_units = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/project/api/v1/typicalunits")
      .then((res) => {
        setTypicaluzdrop(res.data);
        console.log(res.data.data);
      });
  };

  // const get_units = () => {
  //   axios
  //     .post("https://buildfastapi.kollagroup.in/project/api/v1/gettypical-unitsize",)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setTypicaluzdrop(res.data.data);
  //         console.log(res.data.data);
  //       }
  //     });
  // };

  const [siteId, setSiteId] = useState("");

  // const add_sitevisit = () => {

  //     axios.post("http://localhost:3002/add-sitevistfrm", {
  //       name:nname,mobile:nmobile, email:nemail, location:leadloc, sourceType:nst, reference:nrefname, channelPartner:ncp,executive:pexecutive,  Occupation:occupation, budget:budget, aptsize:apartsize, facing:apartfacing, cornor:Corner, ucon:underConstruction, rmdate:readyToMove,Id:siteId
  //     }).then((res) => {
  //         if (res.status === 200) {
  //             setOpensv(false)
  //             setValidatedsv(false)
  //             customerObjsi()
  //             Swal.fire({
  //                 icon: 'success',
  //                 title: "Success !",
  //                 text: "Data is added successfully !",
  //                 confirmButtonColor: "rgb(126, 110, 228)",
  //                 cancelButtonColor: "#d33",
  //                 showCancelButton: false,
  //                 confirmButtonText: "Ok"
  //             }).then((result) => {
  //                 if (result.value) {
  //                     return
  //                 }
  //             })
  //         }
  //     })
  // }
  const [salessource, setSalesSource] = useState("");

   

  const getdetls = (value) => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/get-lead-details", {
        value,
      })
      .then((res) => {
        // setNid(res.data.Id)
        console.log(res.data.Location);
        setNname(res.data.name);
        setNmobile(res.data.mobile);
        setNemail(res.data.email);
        setNst(res.data.sourceType);
        setLeadloc(res.data.location);
        setNrefname(res.data.refName);
        setNrefmob(res.data.refMobile);
        setNcp(res.data.channelPartner);
        setNcpname(res.data.cpName);
        setNcpid(res.data.cpId);
        setNcb(res.data.commentBox);
        setSalesSource(excutiveName);
      });
  };

  const [customerRessi, setCustomerResponsesi] = React.useState([]);

  // const customerObjsi = () => {
  //     axios.get('https://buildfastapi.kollagroup.in/api/v1/getsitform').then((res) => {
  //         setCustomerResponsesi(res.data)
  //         console.log(customerRessi)
  //     })
  // }


  
  const [clickedName, setClickedName] = useState(sessionStorage.getItem("clickedName") || "")

  const handleRowClick = (itemName) => {
    sessionStorage.setItem("clickedName", itemName);
    setClickedName(itemName);
    console.log("Stored clicked name:", itemName); // Debug log
  };

  // const clickedName = sessionStorage.getItem("clickedName");
  // console.log("Retrieved clicked name:", clickedName); // Debug log

  React.useEffect(() => {
    const storedName = sessionStorage.getItem("clickedName");
    if (storedName) {
      setClickedName(storedName);
    }
  }, []);


  const filteredItems = clickedName
    ? data.filter((item) => item.name === clickedName)
    : [];

 

  const [Validatedbf, setValidatedbf] = useState(false);

  const [openbf, setOpenbf] = useState(false);

  const handleClickOpenbf = () => {
    setOpenbf(true);
  };

  const handleClosebf = () => {
    setOpenbf(false);
  };

  const formValidationbf = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_blockform();
    }

    setValidatedbf(true);
  };

  const [alternatemob, setAlternateMob] = useState("");
  const [aadharnumber, setAadharNumber] = useState("");
  const [pannumber, setPanNumber] = useState("");
  const [peraddress, setPerAddress] = useState("");
  const [addresscom, setAddressCom] = useState("");
  const [paymentsource, setPaymentSource] = useState("");
  const [chequenumber, setChequeNumber] = useState("");
  const [transferdetails, setTransferDetails] = useState("");
  const [amountinwords, setAmountInWords] = useState("");
  const [Amount, setAmount] = useState("");
  const [tflatno, setTowardsFlatNumber] = useState("");
  const [tfloorno, setTowardsFloorNumber] = useState("");
  const [ttowerno, setTowardsTowerNumber] = useState("");

  const [signature, setSignature] = useState("");

  const [sameaspaddress, setSameaspaddress] = useState(false);

  const handlecheck = () => {
    if (sameaspaddress) {
      setAddressCom("");
    } else {
      setAddressCom(peraddress);
    }
    setSameaspaddress(!sameaspaddress);
  };

  // https://buildfastapi.kollagroup.in/project/api/v1/unit-show  http://localhost:3003/unit-show
 
  const [unitdata, setUnitdata] = useState([]);
  const get_unit = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/project/api/v1/unit-show")
      .then((res) => {
        setUnitdata(res.data);
        console.log(res.data);
      });
  };

  const [responset, setResponset] = useState([]);

  const get_floor = () => {
    axios
      .post("https://buildfastapi.kollagroup.in/project/api/v1/towers-getall")
      .then((res) => {
        if (res.data.status === 200) {
          setResponset(res.data.data);
          console.log(res.data.data);
        }
      });
  };

  const [responsef, setResponsef] = useState([]);

  const get_floorf = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/project/api/v1/get-floor")
      .then((res) => {
        if (res.data.status === 200) {
          setResponsef(res.data.data);
          console.log(res.data.data)
        }
      });
  };


  const getCurrentDateTime = () => {
    const current = new Date();
    const year = current.getFullYear();
    const month = String(current.getMonth() + 1).padStart(2, "0");
    const day = String(current.getDate()).padStart(2, "0");
    const hours = String(current.getHours()).padStart(2, "0");
    const minutes = String(current.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Set the default date and time when the component mounts
  React.useEffect(() => {
    setPtym(getCurrentDateTime());
  }, []);



  const [transactionId, setTransactionId] = useState("");
  const [screenshot, setScreenshot] = useState("");

  //31-07-2024

  // const add_blockform = () => {
  //   console.log({
  //     name: nname,
  //     mobile: nmobile,
  //     alternateMobile: alternatemob,
  //     email: nemail,
  //     permanentAddress: peraddress,
  //     addComm: addresscom,
  //     aadharNo: aadharnumber,
  //     panNo: pannumber,
  //     paySource: paymentsource,
  //     cheqNo: chequenumber,
  //     transferDetail: transferdetails,
  //     amtInWords: amountinwords,
  //     amt: Amount,
  //     tflatNo: tflatno,
  //     ttowerNo: ttowerno,
  //     salesSOurce: salessource,
  //     transactionId:transactionId,
  //     screenshot:screenshot
  //   });
  //   const formdata = new FormData();
  //   formdata.append("name", nname);
  //   formdata.append("mobile", nmobile);
  //   formdata.append("alternateMobile", alternatemob);
  //   formdata.append("email", nemail);
  //   formdata.append("permanentAddress", peraddress);
  //   formdata.append("addComm", addresscom);
  //   formdata.append("aadharNo", aadharnumber);
  //   formdata.append("panNo", pannumber);
   
  //   formdata.append("tflatNo", tflatno);
  //   formdata.append("ttowerNo", ttowerno);
  //   formdata.append("salesSource", excutiveName);

  //   formdata.append("ProjectName", Pname);
  //   formdata.append("salesExecutiveEmail", emailExcutive);
  //   formdata.append("tfloor", floorb);
  //   formdata.append('transactionId',transactionId);
  //   formdata.append('screenshot',screenshot);
  //   formdata.append('facing',facingFlat)
  //   formdata.append('corner',cornerbb)


  //   // https://buildfastapi.kollagroup.in/create-customer/api/v1/add-blockform
  //   axios
  //     .post(
  //       "https://buildfastapi.kollagroup.in/create-customer/api/v1/add-blockform",
  //       formdata
  //     )
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setOpenbf(false);
  //         setOpenblockForm(false)
  //         customerObjsib();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success !",
  //           text: "Data is added successfully !",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         }).then((result) => {
  //           if (result.value) {
  //             return;
  //           }
  //         });
  //       }
  //     });
  // };

  const [gstPercentage, setGstPercentage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);


  const handleGstChange = (e) => {
    const percentage = parseFloat(e.target.value) || 0;
    setGstPercentage(percentage);
    calculateTotalAmount(percentage);
  };

  const calculateTotalAmount = (gst) => {
    let total = 0;
    selectedPaymentsBlock.forEach(paymentSource => {
      if (paymentSourcesBlock[paymentSource]?.amtPaid) {
        total += parseFloat(paymentSourcesBlock[paymentSource].amtPaid);
      }
    });
    const gstAmount = (total * gst) / 100;
    setTotalAmount(total + gstAmount);
  };
  
  // 04-10-2024

  // const add_blockform = () => {
  //   const formdata = new FormData();
  //   formdata.append("name", nname);
  //   formdata.append("mobile", nmobile);
  //   formdata.append("alternateMobile", alternatemob);
  //   formdata.append("email", nemail);
  //   formdata.append("permanentAddress", peraddress);
  //   formdata.append("addComm", addresscom);
  //   formdata.append("aadharNo", aadharnumber);
  //   formdata.append("panNo", pannumber);
  //   formdata.append("tflatNo", tflatno);
  //   formdata.append("ttowerNo", ttowerno);
  //   formdata.append("salesSource", excutiveName);
  //   formdata.append("ProjectName", Pname);
  //   formdata.append("tfloor", floorb);
  //   formdata.append("salesExecutiveEmail", emailExcutive);
  //   formdata.append("facing", facingFlat);
  //   formdata.append("corner", cornerbb);
  //   formdata.append("sig", signature);
  
  //   console.log('Payment Sources Block:', paymentSourcesBlock);
  //   // Serialize paymentSourcesBlock and append it to the form data
  //   formdata.append("paySources", JSON.stringify(paymentSourcesBlock));
  
  //   for (const [source, details] of Object.entries(paymentSourcesBlock)) {
  //     if (details.screenshot) {
  //       formdata.append(`${source}_screenshot`, details.screenshot);
  //     }
  //     if (details.chequeImage) {
  //       formdata.append(`${source}_chequeImage`, details.chequeImage);
  //     }
  //   }
  //   formdata.append("gstPercentage", gstPercentage);
  //   formdata.append("gstAmount", totalAmount);

  //   // https://buildfastapi.kollagroup.in/create-customer/api/v1/add-blockform
  
  //   axios
  //     .post("https://buildfastapi.kollagroup.in/create-customer/api/v1/add-blockform", formdata)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setOpenbf(false);
  //         setOpenblockForm(false);
  //         customerObjsib();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success!",
  //           text: "Data is added successfully!",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         }).then((result) => {
  //           if (result.value) {
  //             return;
  //           }
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.status === 400) {
  //         // Display error message from the server
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error!",
  //           text: error.response.data.error || "An error occurred. Please try again.",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         });
  //       } else {
  //         // Handle other types of errors
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error!",
  //           text: "An unexpected error occurred. Please try again later.",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         });
  //       }
  //       console.error("Error adding block form:", error);
  //     });
  // };
  

  const add_blockform = () => {
    const formdata = new FormData();
    formdata.append("name", nname);
    formdata.append("mobile", nmobile);
    formdata.append("alternateMobile", alternatemob);
    formdata.append("email", nemail);
    formdata.append("permanentAddress", peraddress);
    formdata.append("addComm", addresscom);
    formdata.append("aadharNo", aadharnumber);
    formdata.append("panNo", pannumber);
    formdata.append("tflatNo", tflatno);
    formdata.append("ttowerNo", ttowerno);
    formdata.append("salesSource", excutiveName);
    formdata.append("ProjectName", Pname);
    formdata.append("tfloor", floorb);
    formdata.append("salesExecutiveEmail", emailExcutive);
    formdata.append("facing", facingFlat);
    formdata.append("corner", cornerbb);
    formdata.append("sig", signature);
  
    console.log('Payment Sources Block:', paymentSourcesBlock);
    // Serialize paymentSourcesBlock and append it to the form data
    formdata.append("paySources", JSON.stringify(paymentSourcesBlock));
  
    for (const [source, details] of Object.entries(paymentSourcesBlock)) {
      if (details.screenshot) {
        formdata.append(`${source}_screenshot`, details.screenshot);
      }
      if (details.chequeImage) {
        formdata.append(`${source}_chequeImage`, details.chequeImage);
      }
    }
    
    formdata.append("gstPercentage", gstPercentage);
    formdata.append("gstAmount", totalAmount);
  
    // https://buildfastapi.kollagroup.in/create-customer/api/v1/add-blockform
  
    axios
      .post("https://buildfastapi.kollagroup.in/create-customer/api/v1/add-blockform", formdata)
      .then((res) => {
        if (res.data.status === 200) {
          setOpenbf(false);
          setOpenblockForm(false);
          customerObjsib();
          
          // Show success message
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data is added successfully!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              // After success, call WhatsApp API
              const whatsappAPIUrl = `https://emedha.com/kolla/sendluxblock.php?name=${nname}&mobile=91${nmobile}&param1=${tflatno}&param2=${ttowerno.split('-')[0].trim()} - ${ttowerno.split('-')[1].trim().replace(/[^0-9]/g, '')}`;

  
              // Sending WhatsApp message
              axios.get(whatsappAPIUrl)
                .then((response) => {
                  console.log("WhatsApp message sent successfully", response.data);
                })
                .catch((error) => {
                  console.error("Error sending WhatsApp message", error);
                });
            }
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Display error message from the server
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error.response.data.error || "An error occurred. Please try again.",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else {
          // Handle other types of errors
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An unexpected error occurred. Please try again later.",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        }
        console.error("Error adding block form:", error);
      });
  };
  

  const [customerRessib, setCustomerResponsesib] = React.useState([]);

  const customerObjsib = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/create-customer/api/v1/getblockform")
      .then((res) => {
        setCustomerResponsesib(res.data);
      });
  };

  console.log(customerRessib)


  const customerSet = new Set(
    customerRessib.map(({ name, mobile }) => `${name}:${mobile}`)
  );
  
  // Filter sitevisit data
  const filteredSiteVisit = data.filter(item => {
    const key = `${item.name}:${item.mobile}`;
    return !customerSet.has(key);
  });
  
  console.log(filteredSiteVisit);

  const [validatedblock,setValidatedblockForm]=useState(false)
  const [openblockForm,setOpenblockForm]=useState(false)


  const handleClickOpenblockForm=()=>{
    setOpenblockForm(true);
  }

  const handleCloseBlockForm=()=>{
    setOpenblockForm(false)
  }


  const formValidationblockForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill out all required fields.',
        // footer: '<a href="#">Why do I see this message?</a>',
        customClass: {
          container: 'swal2-container' // Custom class for z-index control
        }
      });
    } else {
      add_blockform();
    }

    setValidatedblockForm(true);
  };


  const [validatedBulkUplaod,setValidatedBulkUpload]=useState(false)
  const [openBulkUploadForm,setOpenBulkUploadForm]=useState(false)


  const handleClickOpenBulkUploadForm=()=>{
    setOpenBulkUploadForm(true);
  }

  const handleCloseBulkUploadForm=()=>{
    setOpenBulkUploadForm(false)
  }


  const formValidationBulkUplaodForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      bulkuploadHandle();
    }

    setValidatedBulkUpload(true);
  };

  const[bfile,setBfile] = useState(null)
  // const bulkuploadhandle = () => {
  //   const data = new FormData()
  //   data.append("excel", bfile)
  //   axios.post('http://localhost:3002/addmemberbulk',data)
  //   .then((res) => {
  //     if (res.data.status === 200) {
  //         toggleOpen1();
  //         Swal.fire({
  //             icon: 'success',
  //             title: 'Success',
  //             text: 'Data submitted successfully!',
  //         }).then(() => {
  //           window.location.reload();
  //       });
  //     }
  // })
  // .catch((error) => {
  //     console.error('Error:', error);
  // });
  // }


  const bulkuploadHandle = () => {
    if (bfile) { // Check if file is selected
      const data = new FormData();
      data.append("excel", bfile);

      axios.post('https://buildfastapi.kollagroup.in/api/v1/addmemberbulk', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Data submitted successfully!',
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message || 'An error occurred.',
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to upload file.',
        });
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please select a file to upload.',
      });
    }
  };


  const [Validatedbok, setValidatedbok] = useState(false);
  const [validatedbook,setValidatedbookForm]=useState(false)

  const [openbok, setOpenbok] = useState(false);
  const [openbookForm,setOpenbookForm]=useState(false)

  const handleClickOpenbok = () => {
    setOpenbok(true);
  };

  const handleClickOpenbookForm=()=>{
    setOpenbookForm(true);
  }

  const handleCloseBookForm=()=>{
    setOpenbookForm(false)
  }

  const handleClosebok = () => {
    setOpenbok(false);
  };

  const formValidationbok = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      bookcus();
    }

    setValidatedbok(true);
  };


  
  const [ValidatedbokOne, setValidatedbokOne] = useState(false);
  const [validatedbookOne,setValidatedbookFormOne]=useState(false)

  const [openbokOne, setOpenbokOne] = useState(false);
  const [openbookFormOne,setOpenbookFormOne]=useState(false)

  const handleClickOpenbokOne = () => {
    setOpenbokOne(true);
  };

  const handleClickOpenbookFormOne=()=>{
    setOpenbookFormOne(true);
  }

  const handleCloseBookFormOne=()=>{
    setOpenbookFormOne(false)
  }

  const handleClosebokOne = () => {
    setOpenbokOne(false);
  };

  const formValidationbokOne = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      bookcus();
    }

    setValidatedbokOne(true);
  };



  const formValidationbookForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      bookcus();
    }

    setValidatedbookForm(true);
  };

  const [pnameb, setPNameb] = useState("");
  const [blockTotal,setBlockAmt]=useState("")
  const [capname,setCapName]=useState("")
  const [Pemailb, setPEmailb] = useState("");
  const [pmobileb, setPMobileb] = useState("");
  const [alternatemobb, setAlternateMobb] = useState("");
  const [aadharnumberb, setAadharNumberb] = useState("");
  const [pannumberb, setPanNumberb] = useState("");
  const [addressb, setAddressb] = useState("");
  const [currentaddressb, setCurrentAddressb] = useState("");
  const [amountpaidb,setAmountPaidb] = useState("");
  const [towerb, setTowerb] = useState("");
  const [floorb, setFloorb] = useState("");
  const [flatnob, setFlatNob] = useState("");
  const [facingb, setFacingbb] = useState("");
  console.log(facingb)
  const [b, setCornerb] = useState("");

  const [financeTypeb, setFinanceTypeb] = useState("");
  
    
  const [paymentSources, setPaymentSources] = useState({
    UPI: { transactionId: "", screenshot: null, amtPaid: "" },
    Bank: { transactionId: "", screenshot: null, amtPaid: "" },
    Cheque: { chequeNumber: "", chequeImage: null, amtPaid: "" },
    Online: { transferDetails: "", screenshot: null, amtPaid: "" },
  });

  const [selectedPayments, setSelectedPayments] = useState([]);

  const [gstPercentageBook, setGstPercentageBook] = useState(0);
  const [totalAmountBook, setTotalAmountBook] = useState(0);


  const handlePaymentSourceChange = (e) => {
    const { value } = e.target;
    setSelectedPayments(prev =>
      prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]
    );
  };

  const handleInputChange = (e, source, field) => {
    const { value } = e.target;
    setPaymentSources(prev => ({
      ...prev,
      [source]: { ...prev[source], [field]: value }
    }));
    calculateTotalAmountBook(gstPercentageBook)

  };

  const handleFileChange = (e, source, field) => {
    setPaymentSources(prev => ({
      ...prev,
      [source]: { ...prev[source], [field]: e.target.files[0] }
    }));
  };


  


  const handleGstChangeBook = (e) => {
    const percentage = parseFloat(e.target.value) || 0;
    setGstPercentageBook(percentage);
    calculateTotalAmountBook(percentage);
  };

  const calculateTotalAmountBook = (gst) => {
    let total = 0;
    selectedPayments.forEach(paymentSource => {
      if (paymentSources[paymentSource]?.amtPaid) {
        total += parseFloat(paymentSources[paymentSource].amtPaid);
      }
    });
    const gstAmount = (total * gst) / 100;
    setTotalAmountBook(total + gstAmount);
  };


  const [paymentSourcesBlock, setPaymentSourcesBlock] = useState({
    UPI: { transactionId: "", screenshot: null, amtPaid: "" },
    Bank: { transactionId: "", screenshot: null, amtPaid: "" },
    Cheque: { chequeNumber: "", chequeImage: null, amtPaid: "" },
    Online: { transferDetails: "", screenshot: null, amtPaid: "" },
  });

  const [selectedPaymentsBlock, setSelectedPaymentsBlock] = useState([]);

  const handlePaymentSourceChangeBlock = (e) => {
    const { value } = e.target;
    setSelectedPaymentsBlock(prev =>
      prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]
    );
  };

  

  const handleFileChangeBlock = (e, source, field) => {
    setPaymentSourcesBlock(prev => {
      const updated = {
        ...prev,
        [source]: { ...prev[source], [field]: e.target.files[0] }
      };
      console.log('Updated File State:', updated); // Add this line
      return updated;
    });
  };
  

  // const handleInputChangeBlock = (e, source, field) => {
  //   const { value } = e.target;
  //   setPaymentSourcesBlock(prev => {
  //     const updated = {
  //       ...prev,
  //       [source]: { ...prev[source], [field]: value }
  //     };
  //     console.log('Updated State:', updated); // Add this line
  //     return updated;
  //   });
 
  // };

  const handleInputChangeBlock = (e, source, field) => {
    const { value, type, files } = e.target;
    setPaymentSourcesBlock(prev => ({
      ...prev,
      [source]: {
        ...prev[source],
        [field]: type === "file" ? files[0] : value
      }
    }));
    calculateTotalAmount(gstPercentage);
  };
  


  // https://buildfastapi.kollagroup.in/create-customer/api/v1/get-blockform-details
  

  const getdetlsb = (value) => {
    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/get-blockform-details",
        {
          value,
        }
      )
      .then((res) => {
        setPNameb(res.data.name);
        setBlockAmt(res.data.blockTotal)
        console.log(res.data.blockTotal)
        setPMobileb(res.data.mobile);
        setPEmailb(res.data.email);
        setAlternateMobb(res.data.alternateMobile);
        setAddressb(res.data.permanentAddress);
        setCurrentAddressb(res.data.addComm);
        setAadharNumberb(res.data.aadharNo);
        setPanNumberb(res.data.panNo);
        setFlatNob(res.data.tflatNo);
        setTowerb(res.data.ttowerNo);
        setFloorb(res.data.tfloor);
        console.log(res.data.tfloor)
        setFacingbb(res.data.facing)
        console.log(res.data.facing)
      });
  };

  //   https://buildfastapi.kollagroup.in/create-customer/api/v1/customer

  // const bookcus = () => {
  //   axios
  //     .post("http://localhost:3003/customer", {
  //       customerName: pnameb,
  //       mobileNumber: pmobileb,
  //       email: Pemailb,
  //       floor: floorb,
  //       flatNo: flatnob,
  //       Tower: towerb,
  //       Cornor: b,
  //       addres: addressb,
  //       curAddres: currentaddressb,
  //       aadharNo: aadharnumberb,
  //       panNo: pannumberb,
  //       amtPaid: amountpaidb,
  //       loan: financeTypeb,
  //       aadharImg: aadharImg,
  //       panImg: panImg,
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setOpenbok(false);
  //         customerObj();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success !",
  //           text: "Data is added successfully !",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         }).then((result) => {
  //           if (result.value) {
  //             return;
  //           }
  //         });
  //       }
  //     });
  // };

  const [cpname,setCpname]=useState()

  console.log(cpname)

  const [cpmobile,setcpmobile]=useState()
  const [cpemail,setcpemail]=useState()
  const [reraNo,setReraNo]=useState()
  const [cpaadhar,setCpAadharNumber]=useState()


  const add_channelp = () => {
    // Assuming cpname, cpmobile, cpemail, reraNo, cpaadhar are defined and have values
  
    const requestData = {
      name: cpname,
      mobile: cpmobile,
      email: cpemail,
      rerano: reraNo,
      aadhar: cpaadhar,
      projectName:Pname,
    };
  
    console.log('Request Data:', requestData); // Optional: Log the request data for debugging https://buildfastapi.kollagroup.in/api/v1/add-channelp
  
    // http://localhost:3002/add-channelp

    axios
      .post('https://buildfastapi.kollagroup.in/api/v1/add-channelp', requestData)
      .then((res) => {
        console.log('Response from server:', res.data); // Optional: Log response for debugging
        if (res.data.success) {
          setOpenChannelPForm(false);
  
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Data is added successfully!',
            confirmButtonColor: 'rgb(126, 110, 228)',
            cancelButtonColor: '#d33',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.value) {
              // Optional: Perform any additional actions after success
            }
          });
        } else {
          // Handle server response indicating failure if needed
          console.error('Error adding channel partner:', res.data.message);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to add channel partner. Please try again.',
            confirmButtonColor: 'rgb(126, 110, 228)',
            cancelButtonColor: '#d33',
            showCancelButton: false,
            confirmButtonText: 'Ok',
          });
        }
      })
      .catch((error) => {
        console.error('Error adding channel partner:', error);
        // Handle error scenarios (e.g., show error message to the user)
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Failed to add channel partner. Please try again.',
          confirmButtonColor: 'rgb(126, 110, 228)',
          cancelButtonColor: '#d33',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      });
  };

  const [validatedChannelP,setValidatedChannelPForm]=useState(false)
  const [openChannerlPForm,setOpenChannelPForm]=useState(false)


  const handleClickChannelPForm=()=>{
    setOpenChannelPForm(true);
  }

  const handleCloseChannelPForm=()=>{
    setOpenChannelPForm(false)
  }


  const formValidationChannelPForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_channelp();
    }

    setValidatedChannelPForm(true);
  };






const [carPark,setCarPark]=useState('')
const [errorMessage, setErrorMessage] = useState('')

const [transactionIdb, setTransactionIdBok] = useState("");
const [screenshotb, setScreenshotBok] = useState("");

const [paymentsourceb, setPaymentSourceBok] = useState("");
const [chequenumberb, setChequeNumberBok] = useState("");
const [transferdetailsb, setTransferDetailsBok] = useState("");

  // const bookcus = () => {
  //   const formData = new FormData();
  //   formData.append("customerName", pnameb);
  //   formData.append("mobileNumber", pmobileb);
  //   formData.append("email", Pemailb);
  //   formData.append("floor", floorb);
  //   formData.append("flatNo", flatnob);
  //   formData.append("Tower", towerb);
  //   formData.append("Cornor", Corner);
  //   formData.append("addres", addressb);
  //   formData.append("curAddres", currentaddressb);
  //   formData.append("aadharNo", aadharnumberb);
  //   formData.append("panNo", pannumberb);
  //   formData.append("amtPaid", amountpaidb);
  //   formData.append("loan", financeTypeb);
  //   formData.append("ProjectName", Pname);
  //   formData.append("salesSource", salessource);
  //   formData.append("salesExecutiveEmail", emailExcutive);
  //   formData.append("crmExecutive", CrmExecutive);
  //   formData.append("carPark",carPark)
  //   formData.append('transactionId',transactionIdb);
  //   formData.append('screenshot',screenshotb);
  //   formData.append("paySource", paymentsourceb);
  //   formData.append("cheqNo", chequenumberb);
  //   formData.append("transferDetail", transferdetailsb);
  //   if (aadharImg) formData.append("aadharImg", aadharImg);
  //   if (panImg) formData.append("panImg", panImg);

  //   if (financeTypeb === "Customized") {
  //     formData.append("installmentDetails", JSON.stringify(installments));
  //   }
  //   // https://buildfastapi.kollagroup.in/create-customer/api/v1/customer

  //   axios
  //     .post(
  //       "http://localhost:3003/customer",

  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setOpenbok(false);
  //         setOpenbookForm(false)
  //         customerObj();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success!",
  //           text: "Data is added successfully!",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         }).then((result) => {
  //           if (result.value) {
  //             return;
  //           }
  //         });
  //       } else {
  //         setErrorMessage("Failed to add data. Please try again later.");
  //         setOpenbookForm(false)
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error!",
  //           text: errorMessage,
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         });
  //         console.error("Error adding customer data:", errorMessage);
  //       }
  //     })
  //     .catch((error) => {
  //       setOpenbookForm(false)
  //       setErrorMessage("Failed to add data. Please try again later.");
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error!",
  //         text: errorMessage,
  //         confirmButtonColor: "rgb(126, 110, 228)",
  //         cancelButtonColor: "#d33",
  //         showCancelButton: false,
  //         confirmButtonText: "Ok",
  //       });
  //       console.error("Error adding customer data:", error);
  //     });

        
    
  // };

  //04-10-2024


  // const bookcus = () => {
  //   const formData = new FormData();
  
  //   formData.append("customerName", pnameb);
  //   formData.append("coapplicantName",capname)
  //   formData.append("mobileNumber", pmobileb);
  //   formData.append("email", Pemailb);
  //   formData.append("floor", floorb);
  //   formData.append("flatNo", flatnob);
  //   formData.append("Tower", towerb);
  //   formData.append("Cornor", Corner);
  //   formData.append("addres", addressb);
  //   formData.append("curAddres", currentaddressb);
  //   formData.append("aadharNo", aadharnumberb);
  //   formData.append("panNo", pannumberb);

  //   formData.append("loan", financeTypeb);
  //   formData.append("ProjectName", Pname);
  //   formData.append("salesSource", salessource);
  //   formData.append("salesExecutiveEmail", emailExcutive);
  //   formData.append("crmExecutive", CrmExecutive);
  //   formData.append("carPark", carPark);
  //   formData.append("gstPercentage", gstPercentageBook);
  //   formData.append("gstAmount", totalAmountBook);
  
    
  //   // Add payment sources
  //   formData.append("paySources", JSON.stringify(paymentSources)); 
  //   for (const [source, details] of Object.entries(paymentSources)) {
  //     if (details.screenshot) {
  //       formData.append(`${source}_screenshot`, details.screenshot);
  //     }
  //     if (details.chequeImage) {
  //       formData.append(`${source}_chequeImage`, details.chequeImage);
  //     }
  //   }
  
    
  //   // Pay sources as an array of strings https://buildfastapi.kollagroup.in/create-customer/api/v1/customer

  
  //   if (aadharImg) formData.append("aadharImg", aadharImg);
  //   if (panImg) formData.append("panImg", panImg);
   
  
  //   if (financeTypeb === "Customized") {
  //     formData.append("installmentDetails", JSON.stringify(installments));
  //   }
  
  //   axios
  //     .post(
  //       "https://buildfastapi.kollagroup.in/create-customer/api/v1/customer",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setOpenbok(false);
  //         setOpenbookForm(false);
  //         setOpenbokOne(false)
  //         customerObj();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success!",
  //           text: "Data is added successfully!",
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         }).then((result) => {
  //           if (result.value) {
  //             return;
  //           }
  //         });
  //       } else {
  //         setErrorMessage("Failed to add data. Please try again later.");
  //         setOpenbookForm(false);
  //         setOpenbokOne(false)
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error!",
  //           text: errorMessage,
  //           confirmButtonColor: "rgb(126, 110, 228)",
  //           cancelButtonColor: "#d33",
  //           showCancelButton: false,
  //           confirmButtonText: "Ok",
  //         });
  //         console.error("Error adding customer data:", errorMessage);
  //       }
  //     })
  //     .catch((error) => {
  //       setOpenbookForm(false);
  //       setOpenbokOne(false)
  //       setErrorMessage("Failed to add data. Please try again later.");
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error!",
  //         text: errorMessage,
  //         confirmButtonColor: "rgb(126, 110, 228)",
  //         cancelButtonColor: "#d33",
  //         showCancelButton: false,
  //         confirmButtonText: "Ok",
  //       });
  //       console.error("Error adding customer data:", error);
  //     });
  // };
  

  // Extract transaction details from the payment sources
const getTransactionId = () => {
  // Check for the first non-empty transaction ID
  if (paymentSources?.Cheque?.chequeNumber) {
    return paymentSources.Cheque.chequeNumber; // If chequeNumber is filled
  } else if (paymentSources?.Bank?.transactionId) {
    return paymentSources.Bank.transactionId; // If Bank transactionId is filled
  } else if (paymentSources?.UPI?.transactionId) {
    return paymentSources.UPI.transactionId; // If UPI transactionId is filled
  }
  return "N/A"; // Default if none are filled
};

// Get the transaction ID based on the available data


  const bookcus = () => {
    const formData = new FormData();
  
    formData.append("customerName", pnameb);
    formData.append("coapplicantName", capname);
    formData.append("mobileNumber", pmobileb);
    formData.append("email", Pemailb);
    formData.append("floor", floorb);
    formData.append("flatNo", flatnob);
    formData.append("Tower", towerb);
    formData.append("Cornor", Corner);
    formData.append("addres", addressb);
    formData.append("curAddres", currentaddressb);
    formData.append("aadharNo", aadharnumberb);
    formData.append("panNo", pannumberb);
  
    formData.append("loan", financeTypeb);
    formData.append("ProjectName", Pname);
    formData.append("salesSource", salessource);
    formData.append("salesExecutiveEmail", emailExcutive);
    formData.append("crmExecutive", CrmExecutive);
    formData.append("carPark", carPark);
    formData.append("gstPercentage", gstPercentageBook);
    formData.append("gstAmount", totalAmountBook);
  
    // Add payment sources
    formData.append("paySources", JSON.stringify(paymentSources));
    for (const [source, details] of Object.entries(paymentSources)) {
      if (details.screenshot) {
        formData.append(`${source}_screenshot`, details.screenshot);
      }
      if (details.chequeImage) {
        formData.append(`${source}_chequeImage`, details.chequeImage);
      }
    }
  
    if (aadharImg) formData.append("aadharImg", aadharImg);
    if (panImg) formData.append("panImg", panImg);
  
    if (financeTypeb === "Customized") {
      formData.append("installmentDetails", JSON.stringify(installments));
    }
  
    axios
      .post(
        "https://buildfastapi.kollagroup.in/create-customer/api/v1/customer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setOpenbok(false);
          setOpenbookForm(false);
          setOpenbokOne(false);
          customerObj();


          const transactionIds = getTransactionId();
  
          // WhatsApp API Integration
          const whatsappAPIUrl = `https://emedha.com/kolla/sendluxbook.php?name=${pnameb}&mobile=91${pmobileb}&param1=${totalAmountBook}&param2=${transactionIds}&param3=${flatnob}&param4=${towerb.split('-')[0].trim()} - ${towerb.split('-')[1].trim().replace(/[^0-9]/g, '')}&param5=${towerb}&param6=${floorb}`;
  
          // Send WhatsApp message via API
          axios.get(whatsappAPIUrl)
            .then((response) => {
              console.log('WhatsApp message sent successfully', response);
            })
            .catch((error) => {
              console.error('Error sending WhatsApp message:', error);
            });
  
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data is added successfully!",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        } else {
          setErrorMessage("Failed to add data. Please try again later.");
          setOpenbookForm(false);
          setOpenbokOne(false);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: errorMessage,
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
          console.error("Error adding customer data:", errorMessage);
        }
      })
      .catch((error) => {
        setOpenbookForm(false);
        setOpenbokOne(false);
        setErrorMessage("Failed to add data. Please try again later.");
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessage,
          confirmButtonColor: "rgb(126, 110, 228)",
          cancelButtonColor: "#d33",
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
        console.error("Error adding customer data:", error);
      });
  };
  


  const [customerRes, setCustomerResponse] = React.useState([]);

  const customerObj = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
        console.log(customerRes)
      });
  };

  console.log(customerRes)

  const customerResSet = new Set(
    customerRes.map(({ customerName, mobileNumber, flatNo }) => `${customerName}:${mobileNumber}:${flatNo}`)
  );
  
  // Filter customerRessib data
  const filteredCustomerRessib = customerRessib.filter(item => {
    const key = `${item.name}:${item.mobile}:${item.tflatNo}`;
    return !customerResSet.has(key);
  });
  
  console.log(filteredCustomerRessib);

  //   const PrName=  sessionStorage.getItem('ProjectName')
  //   console.log(PrName)
  const Pname = sessionStorage.getItem("ProjectName");

  const [aadharImg, setAadharImg] = useState(null);
  const [panImg, setPanImg] = useState(null);

  // const [uploadAadhar, setUploadAadhar] = useState();
  // const [uploadPan, setUploadPan] = useState();

  const handleAadharImgChange = (e) => {
    setAadharImg(e.target.files[0]); // Update state with selected Aadhar image file
  };

  const handlePanImgChange = (e) => {
    setPanImg(e.target.files[0]); // Update state with selected PAN image file
  };

  // const storedName = sessionStorage.getItem("itemName");

  // const handleRowClick = (itemName) => {
  //   let clickedNames = JSON.parse(sessionStorage.getItem("clickedNames")) || [];
  //   console.log('Initial clicked names:', clickedNames); // Debug log

  //   if (!clickedNames.includes(itemName)) {
  //     clickedNames.push(itemName);
  //     sessionStorage.setItem("clickedNames", JSON.stringify(clickedNames));
  //     console.log('Updated clicked names:', clickedNames); // Debug log
  //   }
  // };

  // const clickedNames = JSON.parse(sessionStorage.getItem("clickedNames")) || [];


  const handleBookRowClick = (itemName) => {
    sessionStorage.setItem("clickedBookName", itemName);
    console.log("Stored clicked name:", itemName); // Debug log
  };

  const clickedBookName = sessionStorage.getItem("clickedBookName");
  console.log("Retrieved clicked name:", clickedName); // Debug log

  const filteredBookItems = clickedBookName
    ? customerRessib.filter((item) => item.name === clickedBookName)
    : [];


    const handleBookRowClickOne=(itemName)=>{
      sessionStorage.setItem("clickedBookNameOne", itemName);
      console.log("Stored clicked name:", itemName);
    }

    const clickedBookNameOne = sessionStorage.getItem("clickedBookNameOne");
    console.log("Retrieved clicked name:", clickedBookNameOne); // Debug log
  
    const filteredBookItemsOne = clickedBookNameOne
      ? customerRes.filter((item) => item.customerName === clickedBookNameOne)
      : [];

      console.log(filteredBookItemsOne)

  const emailExcutive = sessionStorage.getItem("emailExcutive");
  console.log(emailExcutive)
  const [projectName, setProjectName] = useState("");

  const [CrmExecutive, setCrmExecutvie] = useState("");
  const [CRMExecutiveList, setCrmList] = useState([]);

  const get_account = () => {
    axios
      .get("https://buildfastapi.kollagroup.in/account-setting/api/v1/get-account")
      .then((res) => {
        if (res.data.status === 200) {
          setCrmList(res.data.data);
          console.log(res.data.data)
        }
      });
  };
  React.useEffect(() => {
    get_account();
  }, []);

  console.log(Pname)

  const crmHead = CRMExecutiveList.find(
    (executive) => executive.ProjectName === Pname && executive.UserType.includes('CRM Head')
  );

  const [installments, setInstallments] = useState([]);

  const handleFinanceTypeChange = (e) => {
    setFinanceTypeb(e.target.value);
    if (e.target.value === "Customized") {
      setInstallments([{ dueAmount: "", expectedDate: "" }]);
    } else {
      setInstallments([]);
    }
  };

  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = installments.map((installment, i) => {
      if (i === index) {
        return { ...installment, [field]: value };
      }
      return installment;
    });
    setInstallments(newInstallments);
  };

  const handleInstallmentCountChange = (e) => {
    const count = parseInt(e.target.value);
    const newInstallments = Array(count)
      .fill()
      .map(() => ({ dueAmount: "", expectedDate: "" }));
    setInstallments(newInstallments);
  };

  
// const handleClickOpenCancelForm = (id) => {
//   // Send API request to update the blocked status to 'Cancelled'
//   axios.put(`https://buildfastapi.kollagroup.in/create-customer/api/v1/api/updateBlockedStatus/${id}`, { status: 'Cancelled' })
//     .then(response => {
//       console.log('Status updated:', response.data);
     
//     })
//     .catch(error => {
//       console.error('Error updating status:', error);
//     });
// };



const handleClickOpenCancelForm = (id) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You are about to cancel this item!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, cancel it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.isConfirmed) {
      // Send API request to update the blocked status to 'Cancelled'
      axios.put(`https://buildfastapi.kollagroup.in/create-customer/api/v1/api/updateBlockedStatus/${id}`, { status: 'Cancelled' })
        .then(response => {
          console.log('Status updated:', response.data);
          // Show success alert
          Swal.fire('Cancelled!', 'The status has been updated to "Cancelled".', 'success');
        })
        .catch(error => {
          console.error('Error updating status:', error);
          // Show error alert
          Swal.fire('Error!', 'An error occurred while updating the status. Please try again.', 'error');
        });
    } else {
      // User clicked "Cancel"
      Swal.fire('Cancelled', 'The status was not updated.', 'info');
    }
  });
};


   
  // apartsize



  // Reset unit size selection

  const filteredSizes = typicaluzdrop.filter(item => item.pName === Pname);


 const [uniqueFacings,setFilteredFacings]=useState([])

 const handleUnitSizeChange = (e) => {
  const unitSize = e.target.value;
  setApartSize(unitSize);

  console.log("Selected Unit Size:", unitSize);

  const facings = typicaluzdrop
    .filter(item => item.unitsize.toString() === unitSize && item.pName === Pname)
    .map(item => item.facing);

  console.log("Facings Found:", facings);


  setFilteredFacings([...new Set(facings)]);
};


const [projectNames, setProjectNames] = useState([]);

const [ProjectUser,setProjectUsers]=useState('')

const getProjectNames = () => {
  axios
    .get("https://buildfastapi.kollagroup.in/project/api/v1/get-basic")
    .then((res) => {
      setProjectNames(res.data.data);
      console.log(projectName);
    })
    .catch((err) => {
      console.log(err);
    });
};

React.useEffect(() => {
  getProjectNames();
}, []);



const [maxLastDigit, setMaxLastDigit] = useState(0);
const [response3p, setResponse3p] = useState([]);
const [floorDatap, setFloorDatap] = useState([]);

const get_floorp = (text) => {
  axios.post("https://buildfastapi.kollagroup.in/project/api/v1/get-pwfloor", { text }).then((res) => {
      if (res.data.status === 200) {
          setResponse3p(res.data.data);
          console.log('floorcount', res.data.data.map((a) => a.selectType));

          // Correctly extract the floor number from selectType
          const maxFloorNumber = Math.max(...res.data.data.map(a => {
              // Split the string by '-' and get the last part as the floor number
              const floorNumber = parseInt(a.selectType.split('-').pop());
              return isNaN(floorNumber) ? 0 : floorNumber;
          }));

          console.log('Maximum floor number:', maxFloorNumber);

         
          setMaxLastDigit(maxFloorNumber);
          setFloorDatap(Array.from({ length: maxFloorNumber }, () => ({ floorAmount: '' })));
      }
  });
}

React.useEffect(()=>{
  get_floorp(Pname)
},[])



// tflatno
const [facingFlat,setFacing]=useState('')
const [cornerbb,setCronerbb]=useState('')
console.log(cornerbb)

// console.log(facingFlat)

// const handleUnitChange = (e) => {
//   const unitName = e.target.value;
//   console.log(unitName)

// setTowardsFlatNumber(unitName);

//   // Find the selected unit's facing
//   const selectedUnitData = unitdata.find((unit) => unit.unit_name === unitName);
//   setFacing(selectedUnitData ? selectedUnitData.Facing : "");
// }


const handleUnitChange = (e) => {
  const unitName = e.target.value;
  console.log("Selected Unit:", unitName);

  // Log the entire unitdata to check its structure
  console.log("Unit Data:", unitdata);

  setTowardsFlatNumber(unitName);

  // Find the selected unit's facing
  const selectedUnitData = unitdata.find((unit) => unit.unit_name === unitName);
  console.log("Selected Unit Data:", selectedUnitData);

  // Log the facing value
  const facingValue = selectedUnitData ? selectedUnitData.Facing : "";
  console.log("Facing Value:", facingValue);
  
  setFacing(facingValue);
  const foundCornor = unitdata.find((item) => item.unit_name === unitName);
  console.log(foundCornor)
    if (foundCornor) {
      setCronerbb(foundCornor.cornor); // Set cornor value if found
    } else {
      setCronerbb(""); // Set default or empty if not found
    }
  
};

// Log facingFlat state whenever it changes
React.useEffect(() => {
  console.log("Facing Flat State Updated:", facingFlat);
}, [facingFlat]);


const [facingFlatB,setFacingB]=useState('')

const handleUnitChangeBook = (e) => {
  const unitName = e.target.value;
  console.log("Selected Unit:", unitName);

  // Log the entire unitdata to check its structure
  console.log("Unit Data:", unitdata);

  setFlatNob(unitName);

  // Find the selected unit's facing
  const selectedUnitData = unitdata.find((unit) => unit.unit_name === unitName);
  console.log("Selected Unit Data:", selectedUnitData);

  const foundCornor = unitdata.find((item) => item.unit_name === unitName);
  console.log(foundCornor)
    if (foundCornor) {
      setCronerbb(foundCornor.cornor); // Set cornor value if found
    } else {
      setCronerbb(""); // Set default or empty if not found
    }

  // Log the facing value flatnob
  const facingValue = selectedUnitData ? selectedUnitData.Facing : "";
  console.log("Facing Value:", facingValue);
  
  setFacingB(facingValue);
};

// Log facingFlat state whenever it changes 

React.useEffect(() => {
  console.log("Facing Flat State Updated:", facingFlatB);
}, [facingFlatB]);




const [cornorOptions, setCornorOptions] = useState([]);


React.useEffect(() => {
  // Build options dynamically based on typicaluzdrop data
  const options = typicaluzdrop.map(item => ({
    unitsizeNum: item.unitsizeNum,
    cornor: item.cornor
  }));

  setCornorOptions(options);
  
  // Set default selected cornor based on the first item in typicaluzdrop, if available
  if (typicaluzdrop.length > 0) {
    setCorner(typicaluzdrop[0].cornor);
  }
}, [typicaluzdrop]);

const handleCornorChange = (e) => {
  setCorner(e.target.value);
};

const isManager = sessionStorage.getItem('userType') === "Manager";


const fetchChannelPartner = async (mobile) => {
  try {
    // Replace with your actual API endpoint
    const response = await axios.get(`https://buildfastapi.kollagroup.in/api/v1/channel-partners?mobile=${mobile}`);
    const partner = response.data;
    console.log(response.data)

    if (partner) {
      setCPName(partner.name);
      setCPId(partner.channelId);
    } else {
      setCPName('');
      setCPId('');
    }
  } catch (error) {
    console.error('Error fetching channel partner data', error);
    setCPName('');
    setCPId('');
  }
};

// Function to handle mobile number change
const handleMobileChange = (e) => {
  const mobile = e.target.value;
  setCPMobile(mobile);

  if (mobile) {
    fetchChannelPartner(mobile);
  } else {
    setCPName('');
    setCPId('');
  }
};

React.useEffect(()=>{
  fetchChannelPartner()
},[])


// const [isAadharVerified, setIsAadharVerified] = useState(false);
// const [aadharOTP, setAadharOTP] = useState('');
// const [otpSent, setOtpSent] = useState(false);

// const handleSendAadharOTP = async () => {
//   try {
//     const response = await fetch('http://localhost:3002/api/send-aadhar-otp', {
//       method: 'POST',
//       body: JSON.stringify({
//         aadhaarNumber: aadharnumberb, // Ensure this is correctly formatted
//       }),
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });

//     if (response.ok) {
//       const data = await response.json();
//       if (data.success) {
//         setOtpSent(true);
//         // Handle successful OTP request
//       } else {
//         console.error('Failed to send OTP:', data.message);
//         // Handle failed OTP request
//       }
//     } else {
//       const errorData = await response.json();
//       console.error('Error sending OTP:', errorData);
//       // Handle error response
//     }
//   } catch (error) {
//     console.error('Error sending OTP:', error);
//   }
// };


// const handleVerifyAadharOTP = async () => {
//   try {
//     const response = await fetch('http://localhost:3002/api/verify-aadhar-otp', {
//       method: 'POST',
//       body: JSON.stringify({ otp: aadharOTP }), // Ensure `aadharOTP` is a valid string
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });

//     if (response.ok) {
//       const data = await response.json();
//       if (data.success) {
//         setIsAadharVerified(true);
//         // Handle successful OTP verification
//       } else {
//         console.error('Failed to verify OTP:', data);
//         // Handle failed OTP verification
//       }
//     } else {
//       const errorData = await response.json();
//       console.error('Error verifying OTP:', errorData);
//       // Handle error response
//     }
//   } catch (error) {
//     console.error('Error verifying OTP:', error);
//   }
// };


const [isAadharVerified, setIsAadharVerified] = useState(false);
const [aadharOTP, setAadharOTP] = useState('');
const [otpSent, setOtpSent] = useState(false);


// https://buildfastapi.kollagroup.in/api/v1/api/send-aadhar-otp

const handleSendAadharOTP = async () => {
  try {
    const response = await fetch('https://buildfastapi.kollagroup.in/api/v1/api/send-aadhar-otp', {
      method: 'POST',
      body: JSON.stringify({
        aadhaarNumber: aadharnumberb,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (data.status === 'SUCCESS') {
        setOtpSent(true); // Update state to show OTP input field
        // Handle successful OTP request
      } else {
        console.error('Failed to send OTP:', data.message);
        // Handle failed OTP request
      }
    } else {
      const errorData = await response.json();
      console.error('Error sending OTP:', errorData);
      // Handle error response
    }
  } catch (error) {
    console.error('Error sending OTP:', error);
  }
};

// https://buildfastapi.kollagroup.in/api/v1/api/verify-aadhar-otp

const handleVerifyAadharOTP = async () => {
  try {
    const response = await fetch('https://buildfastapi.kollagroup.in/api/v1/api/verify-aadhar-otp', {
      method: 'POST',
      body: JSON.stringify({ otp: aadharOTP }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status===200) {
      const data = await response.json();
      if (data.status==="VALID") {
        setIsAadharVerified(true); // Update state to show verification success
        // Handle successful OTP verification
      
      } else {
        console.error('Failed to verify OTP:', data.message);
        // Handle failed OTP verification
      }
    } else {
      const errorData = await response.json();
      console.error('Error verifying OTP:', errorData);
      // Handle error response
    }
  } catch (error) {
    console.error('Error verifying OTP:', error);
  }
};

const [carParkAmounts, setCarParkAmounts] = useState({});


React.useEffect(() => {
  // Fetch car parking amounts from the backend
  axios.get('https://buildfastapi.kollagroup.in/billing/api/v1/api/car-parking-amounts', { params: { Pname } })
    .then(response => setCarParkAmounts(response.data))
    .catch(error => console.error('Error fetching car parking amounts:', error));
}, [Pname]);




// const handleCarParkChange = (e) => {
//   const selectedCarPark = e.target.value;
  
//   // Send update request only if the selection has changed
//   if (selectedCarPark !== oldCarPark) {
//     axios.put('http://localhost:4000/api/update-floor-unit', {
//       flatnob,
//       newCarParkType: selectedCarPark,
//       oldCarParkType: oldCarPark,
//       projName: Pname
//     })
//     .then(response => {
//       console.log('Floor Unit updated successfully');
//       setTotalValue(response.data.totalValue);
//       // Update oldCarPark after a successful update
//       setOldCarPark(selectedCarPark);
//     })
//     .catch(error => console.error('Error updating Floor Unit:', error));
//   }

//   setCarPark(selectedCarPark);
// };

const [amount, setCAmount] = useState(0);
const [totalValue, setTotalValue] = useState(null);
const [oldCarPark, setOldCarPark] = useState('');

const [baseTotalValue, setBaseTotalValue] = useState(null);

// // Fetch baseTotalValue when the component mounts or flatnob changes
// React.useEffect(() => {
//   if (flatnob) {
//     axios.get('http://localhost:4000/api/get-base-total-value', {
//       params: { flatnob: flatnob }
//     })
//     .then(response => {
//       if (response.data.baseTotalValue !== undefined) {
//         setBaseTotalValue(response.data.baseTotalValue);
//       } else {
//         console.error('No baseTotalValue data received');
//       }
//     })
//     .catch(error => {
//       console.error('Error fetching base total value:', error);
//     });
//   }
// }, [flatnob]);

// // Fetch car park amount and update totalValue when carPark or baseTotalValue changes
// React.useEffect(() => {
//   if (carPark && baseTotalValue !== null) {
//     axios.get('http://localhost:4000/api/get-carpark-amount', {
//       params: { flatnob: flatnob, carParkType: carPark }
//     })
//     .then(response => {
//       if (response.data.amount !== undefined) {
//         const newAmount = response.data.amount;
//         setCAmount(newAmount);

//         // Calculate updated total value
//         const updatedTotalValue = baseTotalValue + newAmount;
//         setTotalValue(updatedTotalValue);
//       } else {
//         console.error('No amount data received');
//       }
//     })
//     .catch(error => {
//       console.error('Error fetching car park amount:', error);
//     });
//   }
// }, [carPark, baseTotalValue]);

// const handleCarParkChange = (e) => {
//   const selectedCarPark = e.target.value;
//   setCarPark(selectedCarPark);
// };

// const handleAmountChange = (e) => {
//   setCAmount(parseFloat(e.target.value) || 0);
// };

// const handleUpdate = () => {
//   axios.post('http://localhost:4000/api/calculate-updated-total', {
//     flatnob: flatnob,
//     carParkType: carPark,
//     newAmount: amount
//   })
//   .then(response => {
//     if (response.data.updatedTotalValue !== undefined) {
//       setTotalValue(response.data.updatedTotalValue);
//     } else {
//       console.error('No updatedTotalValue data received');
//     }
//   })
//   .catch(error => {
//     console.error('Error calculating updated total:', error);
//   });
// };

React.useEffect(() => {
  if (flatnob) {
    axios.get('https://buildfastapi.kollagroup.in/billing/api/v1/api/get-base-total-value', {
      params: { flatnob }
    })
    .then(response => {
      if (response.data.baseTotalValue !== undefined) {
        setBaseTotalValue(response.data.baseTotalValue);
        // Update total value with initial amount
        setTotalValue(response.data.baseTotalValue + amount);
      } else {
        console.error('No baseTotalValue data received');
      }
    })
    .catch(error => {
      console.error('Error fetching base total value:', error);
    });
  }
}, [flatnob]);

// Fetch car park amount and update total value when carPark changes
React.useEffect(() => {
  if (carPark && baseTotalValue !== null) {
    axios.get('https://buildfastapi.kollagroup.in/billing/api/v1/api/get-carpark-amount', {
      params: { flatnob, carParkType: carPark }
    })
    .then(response => {
      if (response.data.amount !== undefined) {
        const newAmount = response.data.amount;
        setCAmount(newAmount);

        // Calculate and set the total value based on baseTotalValue and newAmount
        setTotalValue(baseTotalValue + newAmount);
      } else {
        console.error('No amount data received');
      }
    })
    .catch(error => {
      console.error('Error fetching car park amount:', error);
    });
  }
}, [carPark, baseTotalValue]);

// Handle car park type change
const handleCarParkChange = (e) => {
  const selectedCarPark = e.target.value;
  setCarPark(selectedCarPark);
};

// Handle amount input change
const handleAmountChange = (e) => {
  const newAmount = parseFloat(e.target.value) || 0;
  setCAmount(newAmount);

  // Update total value based on the new amount
  if (baseTotalValue !== null) {
    setTotalValue(baseTotalValue + newAmount);
  }
};

// Handle update button click
const handleUpdate = () => {
  axios.post('https://buildfastapi.kollagroup.in/billing/api/v1/api/calculate-updated-total', {
    flatnob,
    carParkType: carPark,
    newAmount: amount
  })
  .then(response => {
    if (response.data.updatedTotalValue !== undefined) {
      setTotalValue(response.data.updatedTotalValue);
    } else {
      console.error('No updatedTotalValue data received');
    }
  })
  .catch(error => {
    console.error('Error calculating updated total:', error);
  });
};


const [openblockPop, setOpenblockPop] = useState(false);
  const [selectedCustomerblockPop, setSelectedCustomerblockPop] = useState(null);

  const handleClickOpenblockPop = (customer) => {
    setSelectedCustomerblockPop(customer);
    setOpenblockPop(true);
  };

  const handleCloseblockPop = () => {
    setOpenblockPop(false);
    setSelectedCustomerblockPop(null);
  };


  const [openSitePop, setOpenSitePop] = useState(false);
  const [selectedCustomerSitePop, setSelectedCustomerSitePop] = useState(null);

  const handleClickOpenSitePop = (customer) => {
    setSelectedCustomerSitePop(customer);
    setOpenSitePop(true);
  };

  const handleCloseSitePop = () => {
    setOpenSitePop(false);
    setSelectedCustomerSitePop(null);
  };

  const [openBookPop, setOpenBookPop] = useState(false);
  const [selectedCustomerBookPop, setSelectedCustomerBookPop] = useState(null);

  const handleClickOpenBookPop = (customer) => {
    setSelectedCustomerBookPop(customer);
    setOpenBookPop(true);
  };

  const handleCloseBookPop = () => {
    setOpenBookPop(false);
    setSelectedCustomerBookPop(null);
  };

  const [filters, setFilters] = useState({
    agent: '',
    source: '',
    date: '',
    time: '',
  });


  const [fleads, setfLeads] = useState([]); // Array to hold all the data
  const [filteredLeads, setFilteredLeads] = useState([]); // Array to hold filtered data
  console.log(filteredLeads)
  const [agents, setAgents] = useState([]); // Assuming you get agents data from an API
  const [agent, setAgent] = useState(""); // State for selected agent filter
  const [source, setSource] = useState(""); // State for selected source filter
  const [date, setDate] = useState(""); // State for selected date filter


  const fetchLeads = async () => {
    try {
      const response = await axios.get("https://buildfastapi.kollagroup.in/api/v1/api/leads", {
        params: {
          agent,
          source,
          date,
          projectName:ProjectUserB
        },
      });
      setfLeads(response.data); // Set leads data to the state
      console.log("Filtered Leads:", response.data);
      setFilteredLeads(response.data); // Set initial filtered data
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  // Handling filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "agent") {
      setAgent(value);
    } else if (name === "source") {
      setSource(value);
    } else if (name === "date") {
      setDate(value);
    }
  };

  // Trigger filter on change of any filter
  React.useEffect(() => {
    fetchLeads(); // Refetch leads with current filters
  }, [agent, source, date]);



  console.log(sessionStorage.getItem("ProjectName"));

  const [filteredLeadsToday, setFilteredLeadsToday] = useState([]); // Array to hold filtered data
  console.log(filteredLeadsToday)
   // Assuming you get agents data from an API
  const [agent1, setAgent1] = useState(""); // State for selected agent filter
  const [source1, setSource1] = useState(""); // State for selected source filter
  const [date1, setDate1] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }); // State for selected date filter



  const fetchLeads1 = async () => {
    try {
      const response = await axios.get("https://buildfastapi.kollagroup.in/api/v1/api/leads/today", {
        params: {
          agent1,
          source1,
          date1,
          projectName:ProjectUserB
        },
      });
      ; // Set leads data to the state
      console.log("Filtered Leads:", response.data);
      setFilteredLeadsToday(response.data); // Set initial filtered data
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  // Handling filter changes
  const handleFilterChange1 = (e) => {
    const { name, value } = e.target;
    if (name === "agent1") {
      setAgent1(value);
    } else if (name === "source1") {
      setSource1(value);
    } else if (name === "date1") {
      setDate1(value);
    }
  };




  // Trigger filter on change of any filter
  React.useEffect(() => {
    fetchLeads1(); // Refetch leads with current filters
  }, [agent1, source1, date1]);


  const [filteredLeadsUn, setFilteredLeadsUn] = useState([]); // Array to hold filtered data
  console.log(filteredLeadsUn)
   // Assuming you get agents data from an API
  const [agent2, setAgent2] = useState(""); // State for selected agent filter
  const [source2, setSource2] = useState(""); // State for selected source filter
  const [date2, setDate2] = useState("")


  const fetchLeads2 = async () => {
    try {
      const response = await axios.get("https://buildfastapi.kollagroup.in/api/v1/api/leads/untouch", {
        params: {
          agent2,
          source2,
          date2,
          projectName:ProjectUserB
        },
      });
      ; // Set leads data to the state
      console.log("Filtered Leads:", response.data);
      setFilteredLeadsUn(response.data); // Set initial filtered data
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  // Handling filter changes
  const handleFilterChange2 = (e) => {
    const { name, value } = e.target;
    if (name === "agent2") {
      setAgent2(value);
    } else if (name === "source2") {
      setSource2(value);
    } else if (name === "date2") {
      setDate2(value);
    }
  };




  // Trigger filter on change of any filter
  React.useEffect(() => {
    fetchLeads2(); // Refetch leads with current filters
  }, [agent2, source2, date2]);


  
  const [filteredLeadsIn, setFilteredLeadsIn] = useState([]); // Array to hold filtered data
  console.log(filteredLeadsIn)
   // Assuming you get agents data from an API
  const [agent3, setAgent3] = useState(""); // State for selected agent filter
  const [source3, setSource3] = useState(""); // State for selected source filter
  const [date3, setDate3] = useState("")


  const fetchLeads3 = async () => {
    try {
      const response = await axios.get("https://buildfastapi.kollagroup.in/api/v1/api/leads/in", {
        params: {
          agent3,
          source3,
          date3,
          projectName:ProjectUserB
        },
      });
      ; // Set leads data to the state
      console.log("Filtered Leads:", response.data);
      setFilteredLeadsIn(response.data); // Set initial filtered data
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  // Handling filter changes
  const handleFilterChange3 = (e) => {
    const { name, value } = e.target;
    if (name === "agent3") {
      setAgent3(value);
    } else if (name === "source3") {
      setSource3(value);
    } else if (name === "date3") {
      setDate3(value);
    }
  };




  // Trigger filter on change of any filter
  React.useEffect(() => {
    fetchLeads3(); // Refetch leads with current filters
  }, [agent3, source3, date3]);


  const [filteredLeadsMissed, setFilteredLeadsMissed] = useState([]); // Array to hold filtered data
  console.log(filteredLeadsMissed)
   // Assuming you get agents data from an API
  const [agent4, setAgent4] = useState(""); // State for selected agent filter
  const [source4, setSource4] = useState(""); // State for selected source filter
 


  const fetchLeads4 = async () => {
    try {
      const response = await axios.get("https://buildfastapi.kollagroup.in/api/v1/api/leads/missed", {
        params: {
          agent4,
          source4,

          projectName:ProjectUserB
        },
      });
      ; // Set leads data to the state
      console.log("Filtered Leads:", response.data);
      setFilteredLeadsMissed(response.data); // Set initial filtered data
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  // Handling filter changes
  const handleFilterChange4 = (e) => {
    const { name, value } = e.target;
    if (name === "agent4") {
      setAgent4(value);
    } else if (name === "source4") {
      setSource4(value);
    }
  };




  // Trigger filter on change of any filter
  React.useEffect(() => {
    fetchLeads4(); // Refetch leads with current filters
  }, [agent4, source4]);



  console.log()


  const ProjectUserB=  sessionStorage.getItem("ProjectName");;

  const searchProjectWiseCustomers = () => {

    // https://buildfastapi.kollagroup.in/account-setting/api/v1

    axios
      .get(
        `https://buildfastapi.kollagroup.in/account-setting/api/v1/users/se/${ProjectUserB}`
      )
      .then((res) => {
        console.log(`Received data:`, res.data);
   
        setAgents(res.data)

        console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  React.useEffect(()=>{
    searchProjectWiseCustomers()
  },[])



  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [siteVisits, setSiteVisits] = useState([]);


  const fetchLeads5 = async () => {
    try {
      const response = await axios.get("https://buildfastapi.kollagroup.in/api/v1/api/dailyreport", {
        params: {
          startDate,
          endDate,

          projectName:ProjectUserB
        },
      });
      ; // Set leads data to the state
      console.log("Filtered Leads:", response.data);
      setSiteVisits(response.data);
      
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };


  React.useEffect(() => {
    fetchLeads5(); // Refetch leads with current filters
  }, [startDate, endDate]);


  return (
    <>
      <div>
        {!isVeiw && (
          <div className="leadManagementTable">
             {/* <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              style={{ width: "350px",marginLeft:'25px' }}
                            >
                              <Typography
                                style={{ color: "gray", fontSize: 13 }}
                              >
                                Select Project
                              </Typography>
                              <Form.Select
                                value={ProjectUser}
                                onChange={(e) => setProjectUsers(e.target.value)}
                                style={{ padding: "0.42rem", marginBottom: 14 }}
                              >
                              
                              {projectNames.map((item) => (
                              <option
                                key={item.ProjectName}
                                value={item.ProjectName}
                              >
                                {item.ProjectName}
                              </option>
                              ))}
                           
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please Enter Valid manager
                              </Form.Control.Feedback>
                            </Form.Group> */}
            <Typography
              style={{
                color: "rgba(41, 16, 105, 1)",
                marginBottom: 6,
                marginLeft: 15.5,
                fontSize: 18,
                color: "gray",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Lead Details
            </Typography>

            <Paper elevation={0}>
              {/* <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} > */}

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      color: "rgb(86, 63, 146)",
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      indicatorColor="secondary"
                    >
                      <Tab
                        label=" Leads"
                        value="1"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Today's Follow-ups"
                        value="2"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                    
                      <Tab
                        label="Untouched Leads"
                        value="4"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Interested Leads"
                        value="5"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                        <Tab
                        label="Missed Follow-ups"
                        value="3"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />

                      <Tab
                        label="Daily Report"
                        value="6"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                    
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div
                      style={{
                        height: "0.1rem",
                        borderBottom: "0.2px solid lightgray",
                      }}
                    ></div>
                    <div
                      className="card mt-2"
                      style={{
                        border: "none",
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                     
                     <div>
      <label>Agent:</label>
      <select name="agent" onChange={handleFilterChange}>
        <option value="">All</option>
        {agents.map((agent) => (
          <option key={agent.id} value={agent.UserName}>
            {agent.UserName}
          </option>
        ))}
      </select>
    </div>

      <div>
        <label>Source:</label>
        <select name="source" onChange={handleFilterChange}>
          <option value="">All</option>
          <option>Online</option>
                            <option>Walk In</option>
                            <option>Reference</option>
                            <option>Online/Website</option>
                            <option>OTH</option>
                            <option>Paper Ad</option>
        </select>
      </div>

      <div>
        <label>Date:</label>
        <input type="date" name="date" onChange={handleFilterChange} />
      </div>

    

                      <div>
                        {/* <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add New Lead</Button> */}
                        <div
                          style={{
                            width: "9rem",
                            height: "2.3rem",
                            fontWeight: "bold",
                          }}
                        >
                          Total Leads :{" "}
                          {filteredLeads.length}
    </div>
      
              
                      </div>
                    </div>
                    <Table style={{ position: "relative", top: "1.3rem" }}>
                      <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                        <TableRow>
                          {/* <TableCell
                                            align={"start"}
                                            style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
                                        >
                                            Lead
                                        </TableCell> */}
                          <TableCell
                            align={"start"}
                            style={{
                              minWidth: 0,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            Name
                          </TableCell>
                          
                        
                          <TableCell
                            align={"start"}
                            style={{
                              minWidth: 0,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            Call
                          </TableCell>
                          {/* <TableCell

                                            align={"start"}
                                            style={{ top: 57, fontWeight: '600' }}
                                        >
                                            Whatsapp Mobile
                                        </TableCell> */}
                          <TableCell
                            align={"left"}
                            style={{ top: 57, fontWeight: "600" }}
                          >
                            Gender
                          </TableCell>
                          <TableCell
                            align={"start"}
                            style={{
                              minWidth: 0,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align={"left"}
                            style={{ top: 57, fontWeight: "600" }}
                          >
                            Created Date
                          </TableCell>
                          <TableCell
                            align={"start"}
                            style={{
                              minWidth: 0,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            Source Type
                          </TableCell>
                          <TableCell
        align={"start"}
        style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
      >
        Channel Partner
      </TableCell>
                          <TableCell
                            align={"start"}
                            style={{
                              minWidth: 0,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            Comment
                          </TableCell>

                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      
                          {filteredLeads
 


                          .map((item) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item.Id}
                            >
                            
                              <TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12 }}
                              >
                                {item.name}
                                {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12 }}
                              >
                                <a
                                  href={`tel:${item.mobile}`}
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <FaPhone />
                                </a>
                                {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12 }}
                              >
                                {item.gender}
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12 }}
                              >
                                {item.location}
                              
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12, width: 100 }}
                              >
                                {item.pretime}
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12 ,width: 70 }}
                              >
                                {item.sourceType}
                                {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
                              </TableCell>
                              <TableCell
            align={"start"}
            style={{ top: 57, fontSize: 12 }}
          >
            {item.channelPartner === "Yes" ? item.cpName : "No"}
          </TableCell>
                             
<TableCell
                                align={"start"}
                                style={{ top: 57, fontSize: 12, width: 100 }}
                              >
                                {item.commentBox}
                              </TableCell>
                           
                             

                    
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {data.length === 0 && (
                      <Box
                        className="card"
                        style={{
                          border: "none",
                          height: "58vh",
                          width: "100%",
                          boxShadow: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
                          width="145px"
                        />
                        <Typography>0 customers</Typography>
                      </Box>
                    )}

                    {/* </TableContainer> */}
                    {/* </Paper> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: 56,
                        marginTop: 16,
                      }}
                    >
                      <Pagination
                        count={Math.ceil(filteredLeads.length / page)}
                        page={cpage}
                        onChange={pagehandle}
                        color="secondary"
                        sx={{ "& .Mui-selected": { backgroundColor: "blue" } }}
                      />
                    </div>
                  </TabPanel>
                  {/* </TabPanel> */}
                  <TabPanel value="2">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Today Follow Details
                      </Typography>
                      <div
                      className="card mt-2"
                      style={{
                        border: "none",
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
      <label>Agent:</label>
      <select name="agent1" onChange={handleFilterChange1}>
        <option value="">All</option>
        {agents.map((agent) => (
          <option key={agent.id} value={agent.UserName}>
            {agent.UserName}
          </option>
        ))}
      </select>
    </div>

      <div>
        <label>Source:</label>
        <select name="source1" onChange={handleFilterChange1}>
          <option value="">All</option>
          <option>Online</option>
                            <option>Walk In</option>
                            <option>Reference</option>
                            <option>Online/Website</option>
                            <option>OTH</option>
                            <option>Paper Ad</option>
        </select>
      </div>

      <div>
        <label>Date:</label>
        <input type="date" name="date1" onChange={handleFilterChange1} />
      </div>
      
                      <div style={{ fontWeight: "bold" }}>
                        Total  Leads :{" "}
                        {filteredLeadsToday
        
        .length}
                      </div>
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Mobile
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Call
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Comment
                            </TableCell>
                            <TableCell
                                  align={"start"}
                                  style={{ top: 57,  fontWeight: "600" }}
                                >
                                  source Type 
                                </TableCell>
                           

                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredLeadsToday
                            .map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.email}`}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                   {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.mobile}`}
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  <a
                                    href={`tel:${item.mobile}`}
                                    style={{ textAlign: "center" }}
                                  >
                                    <FaPhone />
                                  </a>
                                  {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.intrestComment}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.sourceType}
                                </TableCell>
                               
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "rgb(126, 110, 228) ",
                                  }}
                                >
                                

                                  <div style={{ display: "flex" }}>
                                    {/* <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                    {
                                                                                        (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={handleClickOpensv}> Site Visit</MenuItem>
                                                                                        )
                                                                                    }
                                                                                    
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={handleClickOpenc}>Call Back</MenuItem>
                                                                                        


                                                                                    

                                                                                            <MenuItem onClick={handleClickOpenin} style={{ color: 'gray' }} > Not Interested</MenuItem>
                                                                                       

                                                                                </Menu>
                                                                            </div> */}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Missed Follow-ups
                      </Typography>
                      <div
                      className="card mt-2"
                      style={{
                        border: "none",
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
      <label>Agent:</label>
      <select name="agent4" onChange={handleFilterChange4}>
        <option value="">All</option>
        {agents.map((agent) => (
          <option key={agent.id} value={agent.UserName}>
            {agent.UserName}
          </option>
        ))}
      </select>
    </div>

      <div>
        <label>Source:</label>
        <select name="source4" onChange={handleFilterChange4}>
          <option value="">All</option>
          <option>Online</option>
                            <option>Walk In</option>
                            <option>Reference</option>
                            <option>Online/Website</option>
                            <option>OTH</option>
                            <option>Paper Ad</option>
        </select>
      </div>

                      <div style={{ fontWeight: "bold" }}>
                        Total Missed Follow Leads :{" "}
                        {filteredLeadsMissed
        .length}
                      </div> </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Mobile
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Call
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Source Type
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                            Follow-Up Status
                            </TableCell>

                          
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredLeadsMissed
                            .map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.email}`}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                   {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.mobile}`}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  <a
                                    href={`tel:${item.mobile}`}
                                    style={{
                                      textAlign: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <FaPhone />
                                  </a>
                                  {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.sourceType}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12, width: 300 }}
                                >
                                 {item.follow_up_status}
                                </TableCell>
                               
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel value="4">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Untouched Leads
                      </Typography>
                      <div
                      style={{
                        height: "0.1rem",
                        borderBottom: "0.2px solid lightgray",
                      }}
                    ></div>
                    <div
                      className="card mt-2"
                      style={{
                        border: "none",
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                     
                     <div>
      <label>Agent:</label>
      <select name="agent2" onChange={handleFilterChange2}>
        <option value="">All</option>
        {agents.map((agent) => (
          <option key={agent.id} value={agent.UserName}>
            {agent.UserName}
          </option>
        ))}
      </select>
    </div>

      <div>
        <label>Source:</label>
        <select name="source2" onChange={handleFilterChange2}>
          <option value="">All</option>
          <option>Online</option>
                            <option>Walk In</option>
                            <option>Reference</option>
                            <option>Online/Website</option>
                            <option>OTH</option>
                            <option>Paper Ad</option>
        </select>
      </div>

      <div>
        <label>Date:</label>
        <input type="date" name="date2" onChange={handleFilterChange2} />
      </div>

                      <div style={{ fontWeight: "bold" }}>
                        Total Untouched Leads:{" "}
                        {filteredLeadsUn

   
    
    .filter((item) => {
        // Base conditions that are always applied
        const basicConditions = 
          item.intrestTab !== 1 &&
          item.notIntrstTab !== 1 &&
          item.callBackTab !== 1 &&
          item.sitevistTab !== 1;
    
        // Return true only if the base conditions are met
        return basicConditions;
    


  }).length}
                      </div>
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Mobile
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Gender
                            </TableCell>
                            <TableCell
                            align={"start"}
                            style={{
                              minWidth: 0,
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            Source Type
                          </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Comment
                            </TableCell>

                          
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredLeadsUn
  .filter((item) => {
    // Base conditions that are always applied
    const basicConditions = 
      item.intrestTab !== 1 &&
      item.notIntrstTab !== 1 &&
      item.callBackTab !== 1 &&
      item.sitevistTab !== 1;

    // Return true only if the base conditions are met
    return basicConditions;
  })

                            .map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.email}`}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                   {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.mobile}`}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.gender}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.sourceType}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12, width: 100 }}
                                >
                                  {item.notintrstComment}
                                </TableCell>
                               
                  
                                    {/* <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                   
                                                                                    {
                                                                                        (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={convrtintr}>Move To Intrest</MenuItem>
                                                                                        )

                                                                                    }


                                                                                  

                                                                                </Menu>
                                                                            </div> */}
                               
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel value="5">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Interested Leads
                      </Typography>

                      <div
                      className="card mt-2"
                      style={{
                        border: "none",
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
      <label>Agent:</label>
      <select name="agent3" onChange={handleFilterChange3}>
        <option value="">All</option>
        {agents.map((agent) => (
          <option key={agent.id} value={agent.UserName}>
            {agent.UserName}
          </option>
        ))}
      </select>
    </div>

      <div>
        <label>Source:</label>
        <select name="source3" onChange={handleFilterChange3}>
          <option value="">All</option>
          <option>Online</option>
                            <option>Walk In</option>
                            <option>Reference</option>
                            <option>Online/Website</option>
                            <option>OTH</option>
                            <option>Paper Ad</option>
        </select>
      </div>

      <div>
        <label>Date:</label>
        <input type="date" name="date3" onChange={handleFilterChange3} />
      </div>
      
                      <div style={{ fontWeight: "bold" }}>
                        Total Interested :{" "}
                        {filteredLeadsIn.filter((item) => {
        // Base conditions that are always applied
        const basicConditions = 
          item.intrestTab === 1 &&
          item.notIntrstTab !== 1 &&
          item.callBackTab !== 1 &&
          item.sitevistTab !== 1;
    
        // Return true only if the base conditions are met
        return basicConditions;
    


  }).length}
                      </div>
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Mobile
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Call
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Gender
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Source Type
                            </TableCell>

                       
                                                       <TableCell
                                                         align={"left"}
                                                         style={{ top: 57, fontWeight: "600" }}
                                                       >
                                                         Date of site visit
                                                       </TableCell>
                                                       <TableCell
                                                         align={"left"}
                                                         style={{ top: 57, fontWeight: "600" }}
                                                       >
                                                         Comment
                                                       </TableCell>
                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredLeadsIn.filter((item) => {
        // Base conditions that are always applied
        const basicConditions = 
          item.intrestTab === 1 &&
          item.notIntrstTab !== 1 &&
          item.callBackTab !== 1 &&
          item.sitevistTab !== 1;
    
        // Return true only if the base conditions are met
        return basicConditions;
    


  })
                            .map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                                onClick={() => {
                                  console.log("Row clicked:", item.name); // Debug log
                                }}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 ,fontWeight:'bold', cursor:'pointer'}}
                                 
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                 
                                   
                                   {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.mobile}`}
                              
                                
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  <a
                                    href={`tel:${item.mobile}`}
                                    style={{
                                      textAlign: "left",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <FaPhone />
                                  </a>
                                  {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
                                </TableCell>
                                <TableCell
                                                                align={"start"}
                                                                style={{ top: 57, fontSize: 12 }}
                                                              >
                                                                {item.gender}
                                                              </TableCell>

                                <TableCell
                                                                align={"start"}
                                                                style={{ top: 57, fontSize: 12 }}
                                                              >
                                                                {item.sourceType}
                                                              </TableCell>
                              
                                                              <TableCell
                                                                align={"start"}
                                                                style={{ top: 57, fontSize: 12, width: 100 }}
                                                              >
                                                                {moment(item.intrestDate).format(
                                                                  "MMM DD, YYYY hh:mm A"
                                                                )}
                                                              </TableCell>
                                                              <TableCell
                                                                align={"start"}
                                                                style={{ top: 57, fontSize: 12 }}
                                                              >
                                                                {item.intrestComment}
                                                              </TableCell>
                          
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel value="6">
                    <div style={{ width: "62rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Daily Report
                      </Typography>

                      <div
                      className="card mt-2"
                      style={{
                        border: "none",
                        paddingLeft: 15,
                        paddingRight: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                     
                     

    <div>
        <label>Start Date:</label>
        <input type="date" name="startDate"    onChange={(e) => setStartDate(e.target.value)} />
      </div>
      <div>
        <label>End Date:</label>
        <input type="date" name="endDate"    onChange={(e) => setEndDate(e.target.value)} />
      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Total Site Visit:  {siteVisits 
          .length}
                      </div>
                      <div className='d-flex flex-row align-items-center'>
                             <ReactHTMLTableToExcel
                               id="test-table-xls-button"
                               className="download-table-xls-button"
                               table="table-to-xls"
                               filename="lead_report"
                               sheet="Lead Report"
                               buttonText="Excel"
                               style={{ marginLeft: '2rem', height: '50px' }}
                             />

                      </div>
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }} id="table-to-xls">
  <TableHead sx={{ borderTop: "1px solid lightgray" }} >
    <TableRow>
      <TableCell
        align={"start"}
        style={{
          minWidth: 0,
          fontSize: 14,
          fontWeight: "600",
        }}
      >
        Name
      </TableCell>
      <TableCell
        align={"left"}
        style={{ top: 57, fontWeight: "600" }}
      >
        Mobile
      </TableCell>
      <TableCell
        align={"left"}
        style={{ top: 57, fontWeight: "600" }}
      >
        sales Executive
      </TableCell>
      <TableCell
        align={"left"}
        style={{ top: 21, fontWeight: "600" }}
      >
        Occupation
      </TableCell>
      <TableCell
        align={"left"}
        style={{ top: 21, fontWeight: "600" }}
      >
        Budget
      </TableCell>
    
      <TableCell
        align={"left"}
        style={{ top: 21, fontWeight: "600", width: 18 }}
      >
        Interested Floor
      </TableCell>
      <TableCell
        align={"left"}
        style={{ top: 21, fontWeight: "600", width: 18 }}
      >
        Interested Apartment Size
      </TableCell>
     
      <TableCell
        align={"left"}
        style={{ top: 21, fontWeight: "600", width: 18 }}
      >
        Comment
      </TableCell>
      
    </TableRow>
  </TableHead>
  <TableBody>{
  siteVisits
        .map((item) => (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={item.Id}
        
          >
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12 }}
            >
            {item.name}
            </TableCell>
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12, width:'250px' }}
            >
              <button
                style={{
                  fontSize: 10.7,
                  border: "2px solid rgb(126, 110, 228)",
                  backgroundColor: "white",
                  borderRadius: "1.2rem",
                  cursor: "auto",
                }}
              >
                <FaMobileAlt
                  style={{
                    marginRight: 3,
                    color: "rgb(126, 110, 228)",
                    marginTop: "-0.16rem",
                    fontSize: 9,
                  }}
                />
                 {sessionStorage.getItem('userType') === "Sales Executive"?'**********':`${item.mobile}`}
              </button>
            </TableCell>
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12 }}
            >
            {item.salesExecutive}
            </TableCell>
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12 }}
            >
              {item.Occupation}
            </TableCell>
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12 }}
            >
              {item.budget}
            </TableCell>
           
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12,width:'280px' }}
            >
              {item.interestfloor}
            </TableCell>
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12,width:'280px' }}
            >
              {item.aptsize}
            </TableCell>
            <TableCell
              align={"start"}
              style={{ top: 57, fontSize: 12 ,width:'180px'}}
            >
              {item.commentSiteVisit}
            </TableCell>
          </TableRow>
        ))}
  </TableBody>
</Table>


                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div> */}
                    </div>
                  </TabPanel>
                
                </TabContext>
              </Box>
            </Paper>
          </div>
        )}
        {isVeiw && (
          <div
            className="leadManagementTable leadManagementTable1 p-4"
            style={{
              backgroundColor: "white",
              borderRadius: "0.4rem",
              position: "relative",
            }}
          >
            <FaWindowClose
              onClick={() => {
                setIsVeiw(false);
              }}
              style={{
                position: "absolute",
                right: 10,
                top: 12,
                color: "rgb(126, 110, 228)",
                fontSize: 21,
                cursor: "pointer",
                zIndex: 10000,
              }}
            ></FaWindowClose>
            <div
              className="card"
              style={{
                backgroundColor: "white",
                borderRadius: "0.4rem",
                border: "none",
                boxShadow: "none",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 26,
                  fontWeight: "bold",
                  color: "rgb(126, 110, 228)",
                }}
              >
                <img
                  src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                  width="145px"
                />
              </Typography>
              <Typography>Lead customers information details</Typography>
            </div>
            <br />
            <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>
              <div
                className="card"
                style={{
                  paddingTop: 43,
                  border: "1px solid rgb(126, 110, 228)",
                  borderRadius: "0.3rem",
                  padding: 11,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="card"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.4rem",
                    border: "none",
                    boxShadow: "none",
                    alignItems: "start",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                  >
                    <span style={{ fontSize: 17, color: "black" }}> Name:</span>{" "}
                    {pname}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                  >
                    <span style={{ fontSize: 17, color: "black" }}>Email:</span>{" "}
                    {Pemail}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                  >
                    <span style={{ fontSize: 17, color: "black" }}>
                      Mobile:
                    </span>{" "}
                    {pmobile}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                  >
                    <span style={{ fontSize: 17, color: "black" }}>
                      {" "}
                      Gender:
                    </span>{" "}
                    {pgender}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                  >
                    <span style={{ fontSize: 17, color: "black" }}>
                      Preffered Timings:
                    </span>{" "}
                    {ptym}
                  </Typography>
                  <Typography
                    style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
                  >
                    <span style={{ fontSize: 17, color: "black" }}>
                      Source Type :
                    </span>{" "}
                    {pst}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "40rem" }}>
                <Form
                  noValidate
                  validated={Validated}
                  onSubmit={formValidation}
                >
                  <div className="mb-4 mt-1">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Add Lead
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Full Name *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setPname(e.target.value)}
                        placeholder="Enter Full Name"
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Name
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Email *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setPemail(e.target.value)}
                        type="text"
                        placeholder="Enter Email"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid email
                      </Form.Control.Feedback>
                    </Form.Group>
                    {wapp !== "" && (
                      <>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Mobile *
                          </Typography>
                          <Form.Control
                            required
                            placeholder="Enter Mobile"
                            onChange={(e) => setPmobile(e.target.value)}
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter valid mobile
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <label style={{ color: "gray", fontSize: 13 }}>
                            Is This Your WhatsApp Mobile? *
                          </label>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                              <Form.Check
                                inline
                                label="Yes"
                                name="whatsappGroup"
                                type={type}
                                id={`inline-${type}-1`}
                                value="Yes"
                                onChange={handleWhatsappCheckboxChange}
                              />
                              <Form.Check
                                inline
                                label="No"
                                name="whatsappGroup"
                                type={type}
                                id={`inline-${type}-2`}
                                value="No"
                                onChange={handleWhatsappCheckboxChange}
                              />
                            </div>
                          ))}
                          <Form.Control.Feedback type="invalid">
                            Please select WhatsApp option
                          </Form.Control.Feedback>
                        </Form.Group>

                        {isWhatsappNo && (
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom03"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              WhatsApp Mobile *
                            </Typography>
                            <Form.Control
                              required
                              placeholder="Enter Whatsapp Mobile"
                              onChange={(e) =>
                                setWhatsappMobile(e.target.value)
                              }
                              type="number"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid WhatsApp mobile
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </>
                    )}

                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <div class="radio-wrapper">
                        <div
                          className="radio-container d-flex"
                          style={{ marginBottom: 14 }}
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Gender *
                          </Typography>
                          <div style={{ marginRight: "5rem", marginLeft: 30 }}>
                            <input
                              onChange={(e) => setPgender(e.target.value)}
                              type="radio"
                              name="gender"
                              value="Male"
                              id="male"
                            />
                            <label for="male">MALE</label>
                          </div>
                          <div>
                            <input
                              onChange={(e) => setPgender(e.target.value)}
                              type="radio"
                              name="gender"
                              value="Female"
                            />
                            <label for="female">FEMALE</label>
                          </div>
                        </div>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please select valid Gender
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Location *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Enter Location"
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Location
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Lead Created Date
                      </Typography>
                      <Form.Control
                        required
                        value={ptym}
                        onChange={(e) => setPtym(e.target.value)}
                        type="datetime-local"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Timings
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Lead Created Date
                      </Typography>
                      <Form.Control
                        required
                        value={ptym}
                        onChange={(e) => setPtym(e.target.value)}
                        type="datetime-local"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Timings
                      </Form.Control.Feedback>
                    </Form.Group>
                    <>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <div style={{ marginBottom: 14 }}>
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Source Type
                          </Typography>
                          <select
                            onChange={handleSourceTypeChange}
                            style={{
                              height: "2.3rem",
                              color: "gray",
                              borderRadius: "0.3rem",
                              outline: "none",
                              width: "100%",
                              border: "0.7px solid lightgray",
                            }}
                          >
                            <option>--select--</option>
                            <option>Online</option>
                            <option>Walk In</option>
                            <option>Reference</option>
                            <option>Online/Website</option>
                            <option>OTH</option>
                            <option>Paper Ad</option>
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Source type
                        </Form.Control.Feedback>
                      </Form.Group>

                      {showReferenceFields && (
                        <>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Reference Name *
                            </Typography>
                            <Form.Control
                              required
                              value={rname}
                              placeholder="Enter Reference Name"
                              onChange={(e) => setRName(e.target.value)}
                              type="text"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Reference UCIN
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Reference UCIN *
                            </Typography>
                            <Form.Control
                              required
                              value={rucin}
                              placeholder="Enter Reference Name"
                              onChange={(e) => setRucin(e.target.value)}
                              type="text"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Reference Ucin
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Reference Mobile *
                            </Typography>
                            <Form.Control
                              required
                              value={rmobile}
                              placeholder="Enter Reference Mobile"
                              onChange={(e) => setRMobile(e.target.value)}
                              type="number"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter valid Reference Mobile
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      )}
                    </>
                    <>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom02"
                      >
                        <label style={{ color: "gray", fontSize: 13 }}>
                          Channel Partner*
                        </label>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="interest"
                              type={type}
                              id={`inline-${type}-1`}
                              value="Yes"
                              onChange={handleIntrestChange}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="intrest"
                              type={type}
                              id={`inline-${type}-2`}
                              value="No"
                              onChange={handleIntrestChange}
                            />
                          </div>
                        ))}
                        <Form.Control.Feedback type="invalid">
                          Please select Interest option
                        </Form.Control.Feedback>
                      </Form.Group>

                      {intrest === "Yes" && (
                        <>
                         <Form.Group as={Col} md="6" controlId="validationCustom01">
        <Typography style={{ color: "gray", fontSize: 13 }}>
          Channel Partner Mobile
        </Typography>
        <Form.Control
          required
          value={cpMobile}
          onChange={handleMobileChange}
          type="text"
          placeholder="Enter Channel Partner Mobile"
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
        <Form.Control.Feedback type="invalid">
          Please Enter Valid Channel Partner mobile
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="6" controlId="validationCustom01">
        <Typography style={{ color: "gray", fontSize: 13 }}>
          Channel Partner Name
        </Typography>
        <Form.Control
          required
          value={cpName}
          onChange={(e) => setCPName(e.target.value)}
          type="text"
          placeholder="Enter Channel Partner Name"
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
        <Form.Control.Feedback type="invalid">
          Please Enter Valid Channel Partner Name
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="6" controlId="validationCustom01">
        <Typography style={{ color: "gray", fontSize: 13 }}>
          Channel Partner ID
        </Typography>
        <Form.Control
          required
          value={cpId}
          onChange={(e) => setCPId(e.target.value)}
          type="text"
          placeholder="Enter Channel Partner ID"
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
        <Form.Control.Feedback type="invalid">
          Please Enter Valid Channel Partner ID
        </Form.Control.Feedback>
      </Form.Group>
                        </>
                      )}
                    </>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Comment{" "}
                      </Typography>
                      <Form.Control
                        required
                        as="textarea"
                        onChange={(e) => setComment(e.target.value)}
                        style={{
                          padding: "0.42rem",
                          marginBottom: 14,
                          minHeight: "40px",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Comment
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpen(false);
                          setValidated(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>

        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={open1}
            onClose={() => setOpen1(!open1)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "37rem" }}>
                <Form
                  noValidate
                  validated={Validated}
                  onSubmit={formValidation}
                >
                  <div className="mb-4 mt-1">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Add Project
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Name *
                      </Typography>
                      <Form.Control
                        required
                        value={pname}
                        onChange={(e) => setPname(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Name
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Email *
                      </Typography>
                      <Form.Control
                        required
                        value={Pemail}
                        onChange={(e) => setPemail(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid email
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Mobile *
                      </Typography>
                      <Form.Control
                        required
                        value={pmobile}
                        onChange={(e) => setPmobile(e.target.value)}
                        type="number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid moible
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* 
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Whatsapp Mobile *</Typography>
                                            <Form.Control
                                                required
                                                
                                                onChange={(e) => setWhatsappMobile(e.target.value)}
                                                type="number"

                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid whatsapp mobile
                                            </Form.Control.Feedback>
                                        </Form.Group> */}

                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <div class="radio-wrapper">
                        <div
                          className="radio-container d-flex"
                          style={{ marginBottom: 14 }}
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Gender *
                          </Typography>
                          <div style={{ marginRight: "5rem", marginLeft: 30 }}>
                            <input
                              onChange={(e) => setPgender(e.target.value)}
                              type="radio"
                              name="gender"
                              value={pgender}
                              id="male"
                            />
                            <label for="male">MALE</label>
                          </div>
                          <div>
                            <input
                              onChange={(e) => setPgender(e.target.value)}
                              type="radio"
                              name="gender"
                              value={pgender}
                            />
                            <label for="female">FEMALE</label>
                          </div>
                        </div>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please select valid Gender
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Preffered Timings
                      </Typography>
                      <Form.Control
                        required
                        value={ptym}
                        onChange={(e) => setPtym(e.target.value)}
                        type="datetime-local"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Timings
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <div style={{ marginBottom: 14 }}>
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Source Type
                        </Typography>
                        <select
                          onChange={(e) => setPst(e.target.value)}
                          value={pst}
                          style={{
                            height: "2.3rem",
                            color: "gray",
                            borderRadius: "0.3rem",
                            outline: "none",
                            width: "100%",
                            border: "0.7px solid lightgray",
                          }}
                        >
                          <option>Website</option>
                          <option>Tradeshow</option>
                          <option>Walkins</option>
                          <option>Social Media</option>
                          <option>Relatives</option>
                          <option>Freinds</option>
                        </select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Source type
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        onClick={() => editAlert()}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Update
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => setOpen1(false)}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>

        <React.Fragment>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openi}
            onClose={handleClosei}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div style={{ width: "37rem" }}>
                <Form
                  noValidate
                  validated={Validatedtabi}
                  onSubmit={formValidationtabi}
                >
                  <div className="mb-4 mt-1">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Interested
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Date of Visit *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setIdate(e.target.value)}
                        type="datetime-local"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Name
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Comment Box
                      </Typography>
                      <Form.Control
                        re
                        onChange={(e) => setIcomment(e.target.value)}
                        required
                        as="textarea"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Permanent Address
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpeni(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openc}
            onClose={handleClosec}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div style={{ width: "37rem" }}>
                <Form
                  noValidate
                  validated={Validatedtabcb}
                  onSubmit={formValidationtabcb}
                >
                  <div className="mb-4 mt-1">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Call Back
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Date of Visit *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setCbd(e.target.value)}
                        type="datetime-local"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Name
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Comment Box
                      </Typography>
                      <Form.Control
                        re
                        onChange={(e) => setCbc(e.target.value)}
                        required
                        as="textarea"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Permanent Address
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenc(false);
                          setValidatedtabcb(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openin}
            onClose={handleClosein}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div style={{ width: "37rem" }}>
                <Form
                  noValidate
                  validated={Validatedtabin}
                  onSubmit={formValidationtabin}
                >
                  <div className="mb-4 mt-1">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Not Interested
                    </Typography>
                  </div>
                  <Row className="mb-3">
                  {/* <Form.Group as={Col} md="8" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Reason For Not Interest
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setReasonNotIntr(e.target.value)}
                        value={reasonNotIntr}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select A Reason</option>

                  
                          <option>Budget</option>
                          <option>Time</option>
                          <option>Location</option>
                          <option>Save For Later</option>
           
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Interested Apartment Size
                      </Form.Control.Feedback>
                    </Form.Group> */}
                     <Form.Group as={Col} md="8" controlId="validationCustom01">
      <Typography style={{ color: "gray", fontSize: 13 }}>
        Reason For Not Interested
      </Typography>
      <Form.Select
        required
        onChange={handleReasonChange}
        value={reasonNotIntr}
        type="text"
        style={{ padding: "0.42rem", marginBottom: 14 }}
      >
        <option value="">Select A Reason</option>
        <option value="Budget">Budget</option>
        <option value="Time">Time</option>
        <option value="Location">Location</option>
      
      </Form.Select>
      {/* Conditionally render the checkbox */}
      {(reasonNotIntr === 'Budget' || reasonNotIntr === 'Time' || reasonNotIntr === 'Location') && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Check
            type="checkbox"
            id="saveForLater"
            checked={saveForLater}
            onChange={handleCheckboxChange}
            style={{ marginRight: '0.5rem' }}
          />
          <label htmlFor="saveForLater">Save For Later</label>
        </div>
      )}
      <Form.Control.Feedback type="invalid">
        Please Enter Valid Interested Apartment Size
      </Form.Control.Feedback>
    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Comment Box
                      </Typography>
                      <Form.Control
                        re
                        onChange={(e) => setIncb(e.target.value)}
                        required
                        as="textarea"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Permanent Address
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenin(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </React.Fragment>

        {/* Site visit form */}

        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={opensv}
            onClose={handleClosesv}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={Validatedsv}
                  onSubmit={formValidationsv}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Site Visit
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <div style={{ marginBottom: 14 }}>
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Occupation
                        </Typography>
                        <Form.Select
                          onChange={(e) => setOccupation(e.target.value)}
                          style={{
                            height: "2.3rem",
                            color: "gray",
                            borderRadius: "0.3rem",
                            outline: "none",
                            width: "100%",
                            border: "0.7px solid lightgray",
                          }}
                        >
                          <option>--select--</option>
                          <option>Private</option>
                          <option>Public</option>
                          <option>Self Employed</option>
                          <option>Business</option>
                          <option>Others</option>
                        </Form.Select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Select valid Occupation option
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Budget
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setBudget(e.target.value)}
                        type="text"
                        value={budget}
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Budget
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Interested Floor
                      </Typography>
                      <Form.Select
                        required
                        value={intrstFloor}
                        onChange={handleChangeIntrstFloor}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Interested Floor</option>

                        {floorDatap.map((floor, index) => (

                                 
                                     <option key={index}>{`floor${index + 1}`}</option>
                                   
                               
                                  ))} 
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Interested Floor
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Interested Apartment Size
                      </Typography>
                      <Form.Select
                        required
                        value={apartsize}
                        onChange={handleUnitSizeChange}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>select Apartment Size</option>

                        {/* {filteredSizes.map((data, index) => (
                          <option key={index}>{`${data.unitsize}`}</option>
                        ))} */}
                        <option>1100-1300</option>
                        <option>1300-1500</option>
                        <option>1500-1700</option>
                        <option>1700-1900</option>
                        <option>1900-2100</option>
                        <option>2100-2300</option>
                        <option>2300-above</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Interested Apartment Size
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Interested Apartment Facing
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setApartFacing(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>select Facing</option>
                        {/* {uniqueFacings.map((data, index) => (
                          <option key={index}>{`${data}`}</option>
                        ))} */}
                        <option>East</option>
                        <option>West</option>
                        <option>South</option>
                        <option>North</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Interested Apartment Facing
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <div style={{ marginBottom: 14 }}>
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Corner
                        </Typography>
                        {/* <Form.Select
                          onChange={(e) => setCorner(e.target.value)}
                          style={{
                            height: "2.3rem",
                            color: "gray",
                            borderRadius: "0.3rem",
                            outline: "none",
                            width: "100%",
                            border: "0.7px solid lightgray",
                          }}
                        >
                          <option>--select--</option>
                          <option>Yes</option>
                          <option>No</option>
                        </Form.Select> */}


<div>
    
        <Form.Select
         value={Corner}
          onChange={handleCornorChange}
          style={{
            height: "2.3rem",
            color: "gray",
            borderRadius: "0.3rem",
            outline: "none",
            width: "100%",
            border: "0.7px solid lightgray",
            marginBottom: "10px"
          }}
        
        >
          <option>--select--</option>
         
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>

    </div>

                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Select valid Corner option
                      </Form.Control.Feedback>
                    </Form.Group>
                    <>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <div style={{ marginBottom: 14 }}>
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                           Preference
                          </Typography>
                          <select
                            onChange={handleFlatDetailsChange}
                            style={{
                              height: "2.3rem",
                              color: "gray",
                              borderRadius: "0.3rem",
                              outline: "none",
                              width: "100%",
                              border: "0.7px solid lightgray",
                            }}
                          >
                            <option>--select--</option>
                            <option>Under Construction</option>
                            <option>Ready To Move</option>
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Flat Details
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* {flatDetails === "Under Construction" && (
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Under Construction
                          </Typography>
                          <Form.Control
                            required
                            value={underConstruction}
                            onChange={(e) =>
                              setUnderConstruction(e.target.value)
                            }
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Under Construction
                          </Form.Control.Feedback>
                        </Form.Group>
                      )} */}

{flatDetails === "Under Construction" && (
  <Form.Group as={Col} md="4" controlId="validationCustom01">
    <Typography style={{ color: "gray", fontSize: 13 }}>
      Under Construction
    </Typography>
    <Form.Control
      as="select"
      required
      value={underConstruction}
      onChange={(e) => setUnderConstruction(e.target.value)}
      style={{ padding: "0.42rem", marginBottom: 14 }}
    >
      <option value="">Select...</option>
      <option value="1-2 years">1-2 years</option>
      <option value="2-3 years">2-3 years</option>
      <option value="3 and above">3 and above</option>
    </Form.Control>
    <Form.Control.Feedback type="invalid">
      Please Select a Valid Option
    </Form.Control.Feedback>
  </Form.Group>
)}


                      {/* {flatDetails === "Ready To Move" && (
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Ready To Move
                          </Typography>
                          <Form.Control
                            required
                            value={readyToMove}
                            onChange={(e) => setReadyToMove(e.target.value)}
                            type="date"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
</Form.Group>

                      )} */}

{flatDetails === "Ready To Move" && (
  <Form.Group as={Col} md="4" controlId="validationCustom01">
    <Typography style={{ color: "gray", fontSize: 13 }}>
      Ready To Move
    </Typography>
    <Form.Control
      as="select"
      required
      value={readyToMove}
      onChange={(e) => setReadyToMove(e.target.value)}
      style={{ padding: "0.42rem", marginBottom: 14 }}
    >
      <option value="">Select...</option>
      <option value="Immediately">Immediately</option>
      <option value="3-6 Months">3-6 Months</option>
      <option value="6-9 Months">6-9 Months</option>
      <option value="9-12 Months">9-12 Months</option>
    </Form.Control>
  </Form.Group>
)}
                     
                    </>
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                     <Typography style={{ color: "gray", fontSize: 13 }}>
                       Comment{" "}
                     </Typography>
                     <Form.Control
                       required
                       as="textarea"
                       onChange={(e) => setCommentSiteVisti(e.target.value)}
                       style={{
                         padding: "0.42rem",
                         marginBottom: 14,
                         minHeight: "40px",
                       }}
                     />
                     <Form.Control.Feedback type="invalid">
                       Please Enter Valid Comment
                     </Form.Control.Feedback>
                   </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpensv(false);
                          setValidatedsv(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>

        {/* Block Form  */}

        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openbf}
            onClose={handleClosebf}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={Validatedbf}
                  onSubmit={formValidationbf}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Block Form
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Row>
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <div style={{ marginBottom: 14 }}>
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Lead Name
                          </Typography>
                          <select
                            onChange={(e) => getdetls(e.target.value)}
                            style={{
                              height: "2.3rem",
                              color: "gray",
                              borderRadius: "0.3rem",
                              outline: "none",
                              width: "100%",
                              border: "0.7px solid lightgray",
                            }}
                            value={clickedName} 
                          >
                            <option>--Select--</option>
                            {filteredItems.map((item) => (
                              <option key={item.Id}>
                                {item.name},{item.mobile}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Source type
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Name *
                        </Typography>
                        <Form.Control
                          type="text"
                          value={nname}
                          onChange={(e) => setNname(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="number"
                          value={nmobile}
                          onChange={(e)=>setNmobile(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Mobile
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Email{" "}
                        </Typography>
                        <Form.Control
                          value={nemail}
                          type="text"
                          onChange={(e)=>setNemail(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid email
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Alternate Mobile{" "}
                      </Typography>
                      <Form.Control
                        type="number"
                        placeholder="Enter Alternate Mobile Number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                        onChange={(e) => setAlternateMob(e.target.value)}
                      
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Alternate Mobile
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Aadhar Number
                      </Typography>
                      <Form.Control
                        placeholder="Enter Aadhar Number"
                        onChange={(e) => setAadharNumber(e.target.value)}
                        required
                        type="number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid Aadhar Number
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Pan Number
                      </Typography>
                      <Form.Control
                        required
                        placeholder="Enter Pan Number"
                        onChange={(e) => setPanNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Pan Number
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Permanent Address
                      </Typography>
                      <Form.Control
                        re
                        onChange={(e) => setPerAddress(e.target.value)}
                        required
                        as="textarea"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Permanent Address
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Current Address
                      </Typography>
                      <Form.Control
                        onChange={(e) => setAddressCom(e.target.value)}
                        value={addresscom}
                        required
                        as="textarea"
                        disabled={sameaspaddress}
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Check
                        style={{ color: "red", fontSize: 12 }}
                        onChange={handlecheck}
                        label="Same As Permanent Address"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid Address For Communication
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Typography style={{ color: "gray", fontSize: 13 }}>
          Payment Source
        </Typography>

                    <Form.Group as={Col} md="4" controlId="paymentSource" className='d-flex flex-row align-items-center w-100'>
        <div className='d-flex flex-row align-items-center w-100'>
        <Form.Check
          type="checkbox"
          label="UPI"
          onChange={handlePaymentSourceChangeBlock}
          value="UPI"
        />
        <Form.Check
          type="checkbox"
          label="Bank"
          onChange={handlePaymentSourceChangeBlock}
          value="Bank"
        />
        <Form.Check
          type="checkbox"
          label="Cheque"
          onChange={handlePaymentSourceChangeBlock}
          value="Cheque"
        />
        <Form.Check
          type="checkbox"
          label="Online"
          onChange={handlePaymentSourceChangeBlock}
          value="Online"
        />
        </div>
      </Form.Group>

      {selectedPaymentsBlock.includes("Online") && (
        <>
          <Form.Group as={Col} md="4" controlId="transferDetails">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Transaction Details
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Online", "transferDetails")}
              type="text"
              placeholder="Enter Transfer Details"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transfer Details
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineScreenshot">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChangeBlock(e, "Online", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Online Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Online", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPaymentsBlock.includes("Cheque") && (
        <>
          <Form.Group as={Col} md="4" controlId="chequeNumber">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Cheque Number
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Cheque", "chequeNumber")}
              type="text"
              placeholder="Enter Cheque Number"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Cheque Number
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Cheque Image
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChangeBlock(e, "Cheque", "chequeImage")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Cheque Image
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Cheque Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Cheque", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPaymentsBlock.includes("UPI") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              UPI Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "UPI", "transactionId")}
              type="text"
              placeholder="Enter UPI Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChangeBlock(e, "UPI", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            UPI  Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "UPI", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPaymentsBlock.includes("Bank") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Bank Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Bank", "transactionId")}
              type="text"
              placeholder="Enter Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChangeBlock(e, "Bank", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Bank Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Bank", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

<Form.Group as={Col} md="4" controlId="gstPercentage">
        <Typography style={{ color: "gray", fontSize: 13 }}>GST Percentage</Typography>
        <Form.Control
          type="number"
          placeholder="Enter GST Percentage"
          onChange={handleGstChange}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>

      {/* Total Amount with GST */}
      <Form.Group as={Col} md="4" controlId="totalAmount">
        <Typography style={{ color: "gray", fontSize: 13 }}>Total Amount with GST</Typography>
        <Form.Control
          type="text"
          readOnly
          value={totalAmount.toFixed(2)}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>



                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        ProjectName
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setProjectName(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Project</option>
                        <option>{PrName}</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Tower{" "}
                      </Typography>
                      <Form.Select
                        required
                        value={ttowerno}
                        onChange={(e) => setTowardsTowerNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Tower </option>
                        {responset
                          .filter((item) => item.Towers.startsWith(projectName)) // Filter towers that start with the project name
                          .map((item) => (
                            <option>{item.Towers}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Tower Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Floor
                      </Typography>
                      <Form.Select
                        required
                        value={floorb}
                        onChange={(e) => setFloorb(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Floor</option>
                        {responsef
                          .filter(
                            (item) =>
                              item.Floors.startsWith(ttowerno) &&
                              item.Floors.includes(projectName)
                          )
                          .map((item) => (
                            <option>{item.selectType}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Flat/Unit
                      </Typography>
                      <Form.Select
                        required
                        // onChange={(e) => setTowardsFlatNumber(e.target.value)}
                        value={tflatno}
                       onChange={handleUnitChange}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select unit</option>
                        {unitdata
    .filter(
      (item) =>
        item.unit_name.startsWith(floorb + "-") &&
        item.unit_name.includes(ttowerno)
    )
    .map((item) => {
      console.log(`Unit Name: ${item.unit_name}`);
      console.log(`Booking Status: ${item.booking_status}`);
      console.log(`Mortgage Status: ${item.mortgage_status}`);

      // Determine disabled status for booking status
      const isBooked = item.booking_status === "booked";

      // Determine disabled status for mortgage status
      const isMortgage = item.mortgage_status === "Mortgage";

      return (
        <option
          key={item.unit_name}
          value={item.unit_name}
          disabled={isMortgage || isBooked }
        >
          {item.unit_name}
        </option>
      );
    })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Facing {" "}
                      </Typography>

                      <Form.Control
                        value={facingFlat}
                        disabled
                        // onChange={(e) => setSalesSource(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      

                      <Form.Control.Feedback type="invalid">
                        Please Select valid FacingValue
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Corner {" "}
                      </Typography>

                      <Form.Control
                        value={cornerbb}
                        disabled
                        // onChange={(e) => setSalesSource(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      

                      <Form.Control.Feedback type="invalid">
                        Please Select valid Corner
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Sales Source{" "}
                      </Typography>

                      <Form.Control
                        value={excutiveName}
                        disabled
                        // onChange={(e) => setSalesSource(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Select valid Sales Source
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Signature
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setSignature(e.target.files[0])}
                        type="file"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Signature
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenbf(false);
                          setValidatedbf(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>

  {/* Walkin Block Form  */}


        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openblockForm}
            onClose={handleCloseBlockForm}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={validatedblock}
                  onSubmit={formValidationblockForm}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Block Form
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Row>
                      {/* <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <div style={{ marginBottom: 14 }}>
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Lead Name
                          </Typography>
                          <select
                            onChange={(e) => getdetls(e.target.value)}
                            style={{
                              height: "2.3rem",
                              color: "gray",
                              borderRadius: "0.3rem",
                              outline: "none",
                              width: "100%",
                              border: "0.7px solid lightgray",
                            }}
                          >
                            <option>--Select--</option>
                            {filteredItems.map((item) => (
                              <option key={item.Id}>
                                {item.name},{item.mobile}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Source type
                        </Form.Control.Feedback>
                      </Form.Group> */}

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Name *
                        </Typography>
                        <Form.Control
                          type="text"
                          value={nname}
                          onChange={(e) => setNname(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="number"
                          value={nmobile}
                          onChange={(e)=>setNmobile(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Mobile
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Email{" "}
                        </Typography>
                        <Form.Control
                          value={nemail}
                          type="text"
                          onChange={(e)=>setNemail(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid email
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Alternate Mobile{" "}
                      </Typography>
                      <Form.Control
                        type="number"
                        placeholder="Enter Alternate Mobile Number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                        onChange={(e) => setAlternateMob(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Alternate Mobile
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Aadhar Number
                      </Typography>
                      <Form.Control
                        placeholder="Enter Aadhar Number"
                        onChange={(e) => setAadharNumber(e.target.value)}
                        required
                        type="number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid Aadhar Number
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Pan Number
                      </Typography>
                      <Form.Control
                        required
                        placeholder="Enter Pan Number"
                        onChange={(e) => setPanNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Pan Number
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Permanent Address
                      </Typography>
                      <Form.Control
                        re
                        onChange={(e) => setPerAddress(e.target.value)}
                        required
                        as="textarea"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Permanent Address
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Current Address
                      </Typography>
                      <Form.Control
                        onChange={(e) => setAddressCom(e.target.value)}
                        value={addresscom}
                        required
                        as="textarea"
                        disabled={sameaspaddress}
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Check
                        style={{ color: "red", fontSize: 12 }}
                        onChange={handlecheck}
                        label="Same As Permanent Address"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid Address For Communication
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Typography style={{ color: "gray", fontSize: 13 }}>
          Payment Source
        </Typography>

                    <Form.Group as={Col} md="4" controlId="paymentSource" className='d-flex flex-row align-items-center w-100'>
        
        <div className='d-flex flex-row align-items-center w-100'>
        <Form.Check
          type="checkbox"
          label="UPI"
          onChange={handlePaymentSourceChangeBlock}
          value="UPI"
        />
        <Form.Check
          type="checkbox"
          label="Bank"
          onChange={handlePaymentSourceChangeBlock}
          value="Bank"
        />
        <Form.Check
          type="checkbox"
          label="Cheque"
          onChange={handlePaymentSourceChangeBlock}
          value="Cheque"
        />
        <Form.Check
          type="checkbox"
          label="Online"
          onChange={handlePaymentSourceChangeBlock}
          value="Online"
        />
        </div>
      </Form.Group>

      {selectedPaymentsBlock.includes("Online") && (
        <>
          <Form.Group as={Col} md="4" controlId="transferDetails">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Transaction Details
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Online", "transferDetails")}
              type="text"
              placeholder="Enter Transfer Details"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transfer Details
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineScreenshot">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleInputChangeBlock(e, "Online", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Online Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Online", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPaymentsBlock.includes("Cheque") && (
        <>
          <Form.Group as={Col} md="4" controlId="chequeNumber">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Cheque Number
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Cheque", "chequeNumber")}
              type="text"
              placeholder="Enter Cheque Number"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Cheque Number
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Cheque Image
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleInputChangeBlock(e, "Cheque", "chequeImage")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Cheque Image
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Cheque  Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Cheque", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPaymentsBlock.includes("UPI") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              UPI Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "UPI", "transactionId")}
              type="text"
              placeholder="Enter UPI Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleInputChangeBlock(e, "UPI", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            UPI Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "UPI", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPaymentsBlock.includes("Bank") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Bank Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Bank", "transactionId")}
              type="text"
              placeholder="Enter Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleInputChangeBlock(e, "Bank", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Bank  Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChangeBlock(e, "Bank", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      
<Form.Group as={Col} md="4" controlId="gstPercentage">
        <Typography style={{ color: "gray", fontSize: 13 }}>GST Percentage</Typography>
        <Form.Control
          type="number"
          placeholder="Enter GST Percentage"
          onChange={handleGstChange}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>

      {/* Total Amount with GST */}
      <Form.Group as={Col} md="4" controlId="totalAmount">
        <Typography style={{ color: "gray", fontSize: 13 }}>Total Amount with GST</Typography>
        <Form.Control
          type="text"
          readOnly
          value={totalAmount.toFixed(2)}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>

                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        ProjectName
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setProjectName(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Project</option>
                        <option>{PrName}</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Tower{" "}
                      </Typography>
                      <Form.Select
                        required
                        value={ttowerno}
                        onChange={(e) => setTowardsTowerNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Tower </option>
                        {responset
                          .filter((item) => item.Towers.startsWith(projectName)) // Filter towers that start with the project name
                          .map((item) => (
                            <option>{item.Towers}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Tower Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Floor
                      </Typography>
                      <Form.Select
                        required
                        value={floorb}
                        onChange={(e) => setFloorb(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Floor</option>
                        {responsef
                          .filter(
                            (item) =>
                              item.Floors.startsWith(ttowerno) &&
                              item.Floors.includes(projectName)
                          )
                          .map((item) => (
                            <option>{item.selectType}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Flat/Unit
                      </Typography>
                      <Form.Select
                        required
                        // onChange={(e) => setTowardsFlatNumber(e.target.value)}
                        value={tflatno}
                       onChange={handleUnitChange}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select unit</option>
                        {unitdata
    .filter(
      (item) =>
        item.unit_name.startsWith(floorb + "-") &&
        item.unit_name.includes(ttowerno)
    )
    .map((item) => {
      console.log(`Unit Name: ${item.unit_name}`);
      console.log(`Booking Status: ${item.booking_status}`);
      console.log(`Mortgage Status: ${item.mortgage_status}`);

      // Determine disabled status for booking status
      const isBooked = item.booking_status === "booked";

      // Determine disabled status for mortgage status
      const isMortgage = item.mortgage_status === "Mortgage";

      return (
        <option
          key={item.unit_name}
          value={item.unit_name}
          disabled={isMortgage || isBooked }
        >
          {item.unit_name}
        </option>
      );
    })}



                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Facing {" "}
                      </Typography>

                      <Form.Control
                        value={facingFlat}
                        disabled
                        // onChange={(e) => setSalesSource(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Select valid FacingValue
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Corner {" "}
                      </Typography>

                      <Form.Control
                        value={cornerbb}
                        disabled
                        // onChange={(e) => setSalesSource(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      

                      <Form.Control.Feedback type="invalid">
                        Please Select valid Corner
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        {" "}
                        Sales Source{" "}
                      </Typography>

                      <Form.Control
                        value={excutiveName}
                        disabled
                        // onChange={(e) => setSalesSource(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Select valid Sales Source
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Signature
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setSignature(e.target.files[0])}
                        type="file"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Signature
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenblockForm(false);
                          setValidatedblockForm(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>



        {/* Booking Form */}

        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openbok}
            onClose={handleClosebok}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={Validatedbok}
                  onSubmit={formValidationbok}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Book Customer
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <div style={{ marginBottom: 14 }}>
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Lead Name
                          </Typography>
                          <select
                            onChange={(e) => getdetlsb(e.target.value)}
                            style={{
                              height: "2.3rem",
                              color: "gray",
                              borderRadius: "0.3rem",
                              outline: "none",
                              width: "100%",
                              border: "0.7px solid lightgray",
                            }}
                          >
                            <option>--Select--</option>
                            {filteredBookItems.map((item) => (
                              <option>
                                {item.name},{item.mobile}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Source type
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Name
                        </Typography>
                        <Form.Control
                           required
                          type="text"
                          value={pnameb}
                          onChange={(e) => setPNameb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                         Co-Applicant Name
                        </Typography>
                        <Form.Control
                      
                          type="text"
                          value={capname}
                          onChange={(e) => setCapName(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                   
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Mobile{" "}
                        </Typography>
                        <Form.Control
                            required
                          type="number"
                          value={pmobileb}
                          onChange={(e)=>setPMobileb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Mobile
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Alternate Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="text"
                          value={alternatemobb}
                          onChange={(e)=>setAlternateMobb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Email{" "}
                        </Typography>
                        <Form.Control
                          required
                          value={Pemailb}
                          type="text"
                          onChange={(e)=>setPEmailb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid email
                        </Form.Control.Feedback>
                      </Form.Group>



                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Aadhar Number
                        </Typography>
                        <Form.Control
                          value={aadharnumberb}
                          type="text"
                          disabled
                          required
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter valid Aadhar Number
                        </Form.Control.Feedback>
                      </Form.Group>

                  {/*    <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Upload Aadhar
                        </Typography>
                        <Form.Control
                         required
                          onChange={handleAadharImgChange}
                          disabled={Validated}
                          type="file"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        {aadharImg && (
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {aadharImg.name}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            color: "gray",
                            fontSize: 11,
                            marginTop: 12,
                          }}
                        >
                          Note : Image Size Needs to be less than 1mb
                        </Typography>

                        <Form.Control.Feedback type="invalid">
                          Please Upload a Valid Aadhar File
                        </Form.Control.Feedback>
                      </Form.Group>*/}
                      <Form.Group
  as={Col}
  md="3"
  controlId="verificationAadhar"
>
  <Typography style={{ color: "gray", fontSize: 13 }}>
    Upload Aadhar
  </Typography>
  <Form.Control
    required
    onChange={handleAadharImgChange}
    disabled={isAadharVerified}
    type="file"
    style={{ padding: "0.42rem", marginBottom: 14 }}
  />
  {aadharImg && (
    <Typography style={{ color: "gray", fontSize: 11 }}>
      {aadharImg.name}
    </Typography>
  )}
  <Typography
    style={{
      color: "gray",
      fontSize: 11,
      marginTop: 12,
    }}
  >
    Note: Image Size Needs to be less than 1MB
  </Typography>
  <Form.Control.Feedback type="invalid">
    Please Upload a Valid Aadhar File
  </Form.Control.Feedback>
  {!otpSent && (
    <Button
      onClick={handleSendAadharOTP}
      style={{
        width: "9rem",
        height: "2.3rem",
        backgroundColor: "rgb(126, 110, 228)",
        color: "white",
        marginTop: 10,
      }}
    >
      Verify Aadhar
    </Button>
  )}
  {otpSent && !isAadharVerified &&(
    <>
      <Form.Control
        type="text"
        placeholder="Enter OTP"
        value={aadharOTP}
        onChange={(e) => setAadharOTP(e.target.value)}
        style={{ marginTop: 10 }}
      />
      <Button
        onClick={handleVerifyAadharOTP}
        style={{
          width: "9rem",
          height: "2.3rem",
          backgroundColor: "rgb(126, 110, 228)",
          color: "white",
          marginTop: 10,
        }}
      >
        Verify OTP
      </Button>
    </>
  )}
  {isAadharVerified && (
    <Typography style={{ color: "green", fontSize: 13 }}>
      Aadhar Verified
    </Typography>
  )}
</Form.Group>


                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom03"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Pan Number
                        </Typography>
                        <Form.Control
                          value={pannumberb}
                          type="text"
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Pan Number
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Upload PAN
                        </Typography>
                        <Form.Control
                        required
                          onChange={handlePanImgChange}
                          disabled={Validated}
                          type="file"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        {panImg && (
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {panImg.name}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            color: "gray",
                            fontSize: 11,
                            marginTop: 12,
                          }}
                        >
                          Note : Image Size Needs to be less than 1mb
                        </Typography>

                        <Form.Control.Feedback type="invalid">
                          Please Upload a Valid Project Layout File
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Address
                        </Typography>
                        <Form.Control
                          value={addressb}
                          type="text"
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Address
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Current Address
                        </Typography>
                        <Form.Control
                          disabled
                          required
                          value={currentaddressb}
                          type="text"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter valid Current Address
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Tower
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          type="text"
                          value={towerb}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Tower
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Floor
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          type="text"
                          value={floorb}
                        
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Floor
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Flat No
                        </Typography>
                        <Form.Control
                          required
                          type="text"
                          disabled
                          value={flatnob}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Flat No
                        </Form.Control.Feedback>
                      </Form.Group>
                     

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          CRM Head
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          value={crmHead ? crmHead.UserName : ""}
                          type="text"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Towards Flat Number
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Facing
                        </Typography>

                        <Form.Control
                          required
                          type="text"
                          value={facingb}
                          // onChange={(e) => setFacingb(e.target.value)}
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >

                         
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Facing
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Corner
                        </Typography>
                        <Form.Control
                          required
                          type="text"
                          value={Corner}
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                       
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Corner
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-flex flex-row align-items-center">
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Car Parking
                        </Typography>
                        <Form.Select
                            isInvalid={Validatedbok && !carPark}
                          type="text"
                          value={carPark}
                          onChange={handleCarParkChange}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          <option>Select CarParking</option>
                          <option>One Car Park</option>
                          <option>Two Car Park</option>
                          <option>Three Car Park</option>
                        </Form.Select>
                        {carPark && (
    <>
      <Form.Control
        type="number"
        value={amount}
        onChange={handleAmountChange}
        style={{ marginBottom: 14 }}
        disabled
      />
    
    </>
  )}
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Car Park
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-flex flex-row align-items-center">
                      {totalValue !== null && (
  <div className="d-flex flex-row align-items-center">
    <p style={{ display: 'inline',color: 'blue', marginRight: '8px',marginLeft:'25px',width:'180px' }}>
      Total Value: <span style={{ color: 'black' }}>{totalValue}</span>
    </p>
    <p style={{ display: 'inline',color: 'green' }}>
      20% Amount: <span style={{ color: 'black' }}>{(totalValue * 0.20).toFixed(2)}</span>
    </p>
    <p style={{ display: 'inline',color: 'blue', marginRight: '8px',marginLeft:'25px',width:'180px' }}>
      Blocked Amount: <span style={{ color: 'black' }}>{blockTotal}</span>
    </p>
    <Button onClick={handleUpdate} style={{ marginTop: '1px' }}>
      Update
    </Button>
  </div>
)}
</div>    
</div>    
                    </Row>
                    <Typography style={{ color: "gray", fontSize: 13 }}>
          Payment Source
        </Typography>
                    <Form.Group as={Col} md="4" controlId="paymentSource" className='d-flex flex-row align-items-center'>
       
        <div className='d-flex flex-row align-items-center'>
        <Form.Check
          type="checkbox"
          label="UPI"
          onChange={handlePaymentSourceChange}
          value="UPI"
        />
        <Form.Check
          type="checkbox"
          label="Bank"
          onChange={handlePaymentSourceChange}
          value="Bank"
        />
        <Form.Check
          type="checkbox"
          label="Cheque"
          onChange={handlePaymentSourceChange}
          value="Cheque"
        />
        <Form.Check
          type="checkbox"
          label="Online"
          onChange={handlePaymentSourceChange}
          value="Online"
        />
        </div>
      </Form.Group>

      {selectedPayments.includes("Online") && (
        <>
          <Form.Group as={Col} md="4" controlId="transferDetails">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Transaction Details
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Online", "transferDetails")}
              type="text"
              placeholder="Enter Transfer Details"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transfer Details
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineScreenshot">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "Online", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Online Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Online", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPayments.includes("Cheque") && (
        <>
          <Form.Group as={Col} md="4" controlId="chequeNumber">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Cheque Number
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Cheque", "chequeNumber")}
              type="text"
              placeholder="Enter Cheque Number"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Cheque Number
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Cheque Image
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "Cheque", "chequeImage")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Cheque Image
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Cheque  Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Cheque", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPayments.includes("UPI") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              UPI Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "UPI", "transactionId")}
              type="text"
              placeholder="Enter UPI Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "UPI", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            UPI Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "UPI", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPayments.includes("Bank") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Bank Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Bank", "transactionId")}
              type="text"
              placeholder="Enter Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "Bank", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Bank Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Bank", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      

<Form.Group as={Col} md="4" controlId="gstPercentage">
        <Typography style={{ color: "gray", fontSize: 13 }}>GST Percentage</Typography>
        <Form.Control
          type="number"
          placeholder="Enter GST Percentage"
          onChange={handleGstChangeBook}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>

      {/* Total Amount with GST */}
      <Form.Group as={Col} md="4" controlId="totalAmount">
        <Typography style={{ color: "gray", fontSize: 13 }}>Total Amount with GST</Typography>
        <Form.Control
          type="text"
          readOnly
          value={totalAmountBook.toFixed(2)}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>





                  
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <div style={{ marginBottom: 14 }}>
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Finance Type
                        </Typography>
                        <Form.Select
                     isInvalid={Validatedbok && !financeTypeb}
                          onChange={handleFinanceTypeChange}
                          style={{
                            height: "2.3rem",
                            color: "gray",
                            borderRadius: "0.3rem",
                            outline: "none",
                            width: "100%",
                            border: "0.7px solid lightgray",
                            ...(Validatedbok && !financeTypeb ? { borderColor: 'red' } : {}),
                          }}
                        >
                          <option>--select--</option>
                          <option>PRE EMI</option>
                          <option>RERA</option>
                          <option>Customized</option>
                        </Form.Select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid finance Type
                      </Form.Control.Feedback>
                    </Form.Group>
                    {financeTypeb === "Customized" && (
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom02"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Installment Count
                        </Typography>
                        <Form.Control
                        required
                          type="number"
                          onChange={handleInstallmentCountChange}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Installment Count
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    {financeTypeb === "Customized" &&
                      installments.map((installment, index) => (
                        <Row key={index}>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`dueAmount${index}`}
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Due Amount
                            </Typography>
                            <Form.Control
                            required
                              type="text"
                              value={installment.dueAmount}
                              onChange={(e) =>
                                handleInstallmentChange(
                                  index,
                                  "dueAmount",
                                  e.target.value
                                )
                              }
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Due Amount
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`expectedDate${index}`}
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Expected Date
                            </Typography>
                            <Form.Control
                            required
                              type="date"
                              value={installment.expectedDate}
                              onChange={(e) =>
                                handleInstallmentChange(
                                  index,
                                  "expectedDate",
                                  e.target.value
                                )
                              }
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Expected Date
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      ))}
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenbok(false);
                          setValidatedbok(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>

  {/* WalkinBooking Form */}


      {/* <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openbookForm}
            onClose={handleCloseBookForm}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={validatedbook}
                  onSubmit={formValidationbookForm}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Book Customer
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Row>
                   

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Name
                        </Typography>
                        <Form.Control
                          type="text"
                          value={pnameb}
                          onChange={(e) => setPNameb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="number"
                          value={pmobileb}
                          onChange={(e)=>setPMobileb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Mobile
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Alternate Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="text"
                          value={alternatemobb}
                          onChange={(e)=>setAlternateMobb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Email{" "}
                        </Typography>
                        <Form.Control
                          value={Pemailb}
                          type="text"
                          onChange={(e)=>setPEmailb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid email
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Aadhar Number
                        </Typography>
                        <Form.Control
                          value={aadharnumberb}
                          type="text"
                          onChange={(e)=>
                            setAadharNumberb(e.target.value)}
                          required
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter valid Aadhar Number
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Upload Aadhar
                        </Typography>
                        <Form.Control
                          onChange={handleAadharImgChange}
                          disabled={Validated}
                          type="file"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        {aadharImg && (
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {aadharImg.name}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            color: "gray",
                            fontSize: 11,
                            marginTop: 12,
                          }}
                        >
                          Note : Image Size Needs to be less than 1mb
                        </Typography>

                        <Form.Control.Feedback type="invalid">
                          Please Upload a Valid Aadhar File
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom03"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Pan Number
                        </Typography>
                        <Form.Control
                          value={pannumberb}
                          type="text"
                          onChange={(e)=>setPanNumberb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Pan Number
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Upload PAN
                        </Typography>
                        <Form.Control
                          onChange={handlePanImgChange}
                          disabled={Validated}
                          type="file"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        {panImg && (
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {panImg.name}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            color: "gray",
                            fontSize: 11,
                            marginTop: 12,
                          }}
                        >
                          Note : Image Size Needs to be less than 1mb
                        </Typography>

                        <Form.Control.Feedback type="invalid">
                          Please Upload a Valid Project Layout File
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Permanent Address
                        </Typography>
                        <Form.Control
                          value={addressb}
                          type="text"
                          onChange={(e)=>setAddressb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Address
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Current Address
                        </Typography>
                        <Form.Control
                          
                          required
                          value={currentaddressb}
                          onChange={(e)=>setCurrentAddressb(e.target.value)}
                          disabled={sameaspaddress}
                          type="text"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                          <Form.Check
                        style={{ color: "red", fontSize: 12 }}
                        onChange={handlecheck}
                        label="Same As Permanent Address"
                      />
                        <Form.Control.Feedback type="invalid">
                          Please Enter valid Current Address
                        </Form.Control.Feedback>
                      </Form.Group>
                    
                      <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        ProjectName
                      </Typography>
                      <Form.Select
                        required
                        onChange={(e) => setProjectName(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Project</option>
                        <option>{PrName}</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Tower{" "}
                      </Typography>
                      <Form.Select
                        required
                        value={towerb}
                        onChange={(e) => setTowerb(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Tower </option>
                        {responset
                          .filter((item) => item.Towers.startsWith(projectName)) // Filter towers that start with the project name
                          .map((item) => (
                            <option>{item.Towers}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Tower Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Floor
                      </Typography>
                      <Form.Select
                        required
                        value={floorb}
                        onChange={(e) => setFloorb(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select Floor</option>
                        {responsef
                          .filter(
                            (item) =>
                              item.Floors.startsWith(towerb) &&
                              item.Floors.includes(projectName)
                          )
                          .map((item) => (
                            <option>{item.selectType}</option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Flat/Unit
                      </Typography>
                      <Form.Select
                        required
                        value={flatnob}
                        onChange={handleUnitChangeBook}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      >
                        <option>Select unit</option>
  {unitdata
    .filter(
      (item) =>
        item.unit_name.startsWith(floorb + "-") &&
        item.unit_name.includes(ttowerno)
    )
    .map((item) => {
      console.log(`Unit Name: ${item.unit_name}`);
      console.log(`Booking Status: ${item.booking_status}`);
      console.log(`Mortgage Status: ${item.mortgage_status}`);

      // Determine disabled status for booking status
      const isBooked = item.booking_status === "booked";

      // Determine disabled status for mortgage status
      const isMortgage = item.mortgage_status === "Mortgage";

      return (
        <option
          key={item.unit_name}
          value={item.unit_name}
          disabled={isMortgage || isBooked }
        >
          {item.unit_name}
        </option>
      );
    })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Towards Flat Number
                      </Form.Control.Feedback>
                    </Form.Group>



                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Facing
                        </Typography>

                        <Form.Control
                          required
                          type="text"
                          value={facingFlatB}
                          // onChange={(e) => setFacingb(e.target.value)}
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >

                         
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Facing
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          CRM Executive
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          value={crmHead ? crmHead.UserName : ""}
                          type="text"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Towards Flat Number
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Corner
                        </Typography>
                        <Form.Control
                          disabled
                          type="text"
                          value={cornerbb}
                        
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Corner
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="paymentSource">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
        Payment Source
      </Typography>
        <Form.Select
          required
          onChange={(e) => setPaymentSourceBok(e.target.value)}
          value={paymentsourceb}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        >
          <option>select payment type</option>
          <option>UPI</option>
          <option>Online</option>
          <option>Cheque</option>
          <option>Bank</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please Enter Valid Payment Source
        </Form.Control.Feedback>
      </Form.Group>
      {paymentsourceb === "Online" && (
        <>
          <Form.Group as={Col} md="4" controlId="chequeNumber">
        <Typography style={{ color: "gray", fontSize: 13 }}>
        Transaction Details
      </Typography>
      <Form.Control
            required
            onChange={(e) => setTransferDetailsBok(e.target.value)}
            type="text"
            placeholder="Enter Transfer Details"
            style={{ padding: "0.42rem", marginBottom: 14 }}
          />
          </Form.Group>
            <Form.Group as={Col} md="4" controlId="upiImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => setScreenshotBok(e.target.files[0])}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          </>

      )}

      {paymentsourceb === "Cheque" && (
        <Form.Group as={Col} md="4" controlId="chequeNumber">
          <Typography style={{ color: "gray", fontSize: 13 }}>
            Cheque Number
          </Typography>
          <Form.Control
            required
            onChange={(e) => setChequeNumberBok(e.target.value)}
            type="text"
            placeholder="Enter Cheque Number"
            style={{ padding: "0.42rem", marginBottom: 14 }}
          />
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Cheque Number
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {paymentsourceb === "Cheque" && (
        <Form.Group as={Col} md="4" controlId="chequeImage">
          <Typography style={{ color: "gray", fontSize: 13 }}>
            Upload Cheque Image
          </Typography>
          <Form.Control
            required
            type="file"
            onChange={(e) => setScreenshotBok(e.target.files[0])}
            style={{ padding: "0.42rem", marginBottom: 14 }}
          />
          <Form.Control.Feedback type="invalid">
            Please Upload Cheque Image
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {paymentsourceb === "UPI" && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              UPI Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => setTransactionIdBok(e.target.value)}
              type="text"
              placeholder="Enter UPI Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="upiImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => setScreenshotBok(e.target.files[0])}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {paymentsourceb === "Bank" && (
        <>
        <Form.Group as={Col} md="4" controlId="transferDetails">
        
          <Typography style={{ color: "gray", fontSize: 13 }}>
              Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => setTransactionIdBok(e.target.value)}
              type="text"
              placeholder="Enter Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
       
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Transfer Details
          </Form.Control.Feedback>
        </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiImage">
          <Typography style={{ color: "gray", fontSize: 13 }}>
            Upload Screenshot
          </Typography>
          <Form.Control
            required
            type="file"
            onChange={(e) => setScreenshotBok(e.target.files[0])}
            style={{ padding: "0.42rem", marginBottom: 14 }}
          />
          <Form.Control.Feedback type="invalid">
            Please Upload Screenshot
          </Form.Control.Feedback>
        </Form.Group>
        </>
      )}

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Car Parking
                        </Typography>
                        <Form.Select
                          required
                          type="text"
                          value={carPark}
                          onChange={(e) => setCarPark(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          <option>Select CarParking</option>
                          <option>One Car Park</option>
                          <option>Two Car Park</option>
                          <option>Three Car Park</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Corner
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Amount Paid
                      </Typography>
                      <Form.Control
                        required
                        type="text"
                        value={amountpaidb}
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                        onChange={(e) => setAmountPaidb(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Amount Paid
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <div style={{ marginBottom: 14 }}>
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Finance Type
                        </Typography>
                        <select
                          onChange={handleFinanceTypeChange}
                          style={{
                            height: "2.3rem",
                            color: "gray",
                            borderRadius: "0.3rem",
                            outline: "none",
                            width: "100%",
                            border: "0.7px solid lightgray",
                          }}
                        >
                          <option>--select--</option>
                          <option>PRE EMI</option>
                          <option>RERA</option>
                          <option>Customized</option>
                        </select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid finance Type
                      </Form.Control.Feedback>
                    </Form.Group>
                    {financeTypeb === "Customized" && (
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom02"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Installment Count
                        </Typography>
                        <Form.Control
                          type="number"
                          onChange={handleInstallmentCountChange}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Installment Count
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    {financeTypeb === "Customized" &&
                      installments.map((installment, index) => (
                        <Row key={index}>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`dueAmount${index}`}
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Due Amount
                            </Typography>
                            <Form.Control
                              type="text"
                              value={installment.dueAmount}
                              onChange={(e) =>
                                handleInstallmentChange(
                                  index,
                                  "dueAmount",
                                  e.target.value
                                )
                              }
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Due Amount
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`expectedDate${index}`}
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Expected Date
                            </Typography>
                            <Form.Control
                              type="date"
                              value={installment.expectedDate}
                              onChange={(e) =>
                                handleInstallmentChange(
                                  index,
                                  "expectedDate",
                                  e.target.value
                                )
                              }
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Expected Date
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      ))}
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenbok(false);
                          setValidatedbok(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div> */}


        {/* Booking for multiple payments */}

        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openbokOne}
            onClose={handleClosebokOne}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={ValidatedbokOne}
                  onSubmit={formValidationbokOne}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Book Customer
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <div style={{ marginBottom: 14 }}>
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Lead Name
                          </Typography>
                          <select
                            onChange={(e) => getdetlsb(e.target.value)}
                            style={{
                              height: "2.3rem",
                              color: "gray",
                              borderRadius: "0.3rem",
                              outline: "none",
                              width: "100%",
                              border: "0.7px solid lightgray",
                            }}
                            value={clickedBookNameOne}
                          >
                            <option>--Select--</option>
                            {filteredBookItemsOne.map((item) => (
                              <option>
                                {item.customerName},{item.mobileNumber}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Source type
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Name
                        </Typography>
                        <Form.Control
                        required
                          type="text"
                          value={pnameb}
                          onChange={(e) => setPNameb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                   
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Mobile{" "}
                        </Typography>
                        <Form.Control
                          required
                          type="number"
                          value={pmobileb}
                          onChange={(e)=>setPMobileb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Mobile
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Alternate Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="text"
                          value={alternatemobb}
                          onChange={(e)=>setAlternateMobb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Email{" "}
                        </Typography>
                        <Form.Control
                        required
                          value={Pemailb}
                          type="text"
                          onChange={(e)=>setPEmailb(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid email
                        </Form.Control.Feedback>
                      </Form.Group>



                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Aadhar Number
                        </Typography>
                        <Form.Control
                          value={aadharnumberb}
                          type="text"
                          disabled
                          required
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter valid Aadhar Number
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Upload Aadhar
                        </Typography>
                        <Form.Control
                        required
                          onChange={handleAadharImgChange}
                          disabled={Validated}
                          type="file"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        {aadharImg && (
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {aadharImg.name}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            color: "gray",
                            fontSize: 11,
                            marginTop: 12,
                          }}
                        >
                          Note : Image Size Needs to be less than 1mb
                        </Typography>

                        <Form.Control.Feedback type="invalid">
                          Please Upload a Valid Aadhar File
                        </Form.Control.Feedback>
                      </Form.Group> */}

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom03"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Pan Number
                        </Typography>
                        <Form.Control
                          value={pannumberb}
                          type="text"
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Pan Number
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Upload PAN
                        </Typography>
                        <Form.Control
                        required
                          onChange={handlePanImgChange}
                          disabled={Validated}
                          type="file"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        {panImg && (
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {panImg.name}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            color: "gray",
                            fontSize: 11,
                            marginTop: 12,
                          }}
                        >
                          Note : Image Size Needs to be less than 1mb
                        </Typography>

                        <Form.Control.Feedback type="invalid">
                          Please Upload a Valid Project Layout File
                        </Form.Control.Feedback>
                      </Form.Group> */}

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Address
                        </Typography>
                        <Form.Control
                          value={addressb}
                          type="text"
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Address
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          {" "}
                          Current Address
                        </Typography>
                        <Form.Control
                          disabled
                          required
                          value={currentaddressb}
                          type="text"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter valid Current Address
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Tower
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          type="text"
                          value={towerb}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Tower
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Floor
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          type="text"
                          value={floorb}
                        
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Floor
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Flat No
                        </Typography>
                        <Form.Control
                          required
                          type="text"
                          disabled
                          value={flatnob}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Flat No
                        </Form.Control.Feedback>
                      </Form.Group>
                     

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          CRM Head
                        </Typography>
                        <Form.Control
                          required
                          disabled
                          value={crmHead ? crmHead.UserName : ""}
                          type="text"
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Towards Flat Number
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Facing
                        </Typography>

                        <Form.Control
                          required
                          type="text"
                          value={facingb}
                          // onChange={(e) => setFacingb(e.target.value)}
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >

                         
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Facing
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Corner
                        </Typography>
                        <Form.Control
                          required
                          type="text"
                          value={Corner}
                          disabled
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                       
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Corner
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Car Parking
                        </Typography>
                        <Form.Select
                          required
                          isInvalid={ValidatedbokOne && !carPark}
                          type="text"
                          value={carPark}
                          onChange={handleCarParkChange}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          <option>Select CarParking</option>
                          <option>One Car Park</option>
                          <option>Two Car Park</option>
                          <option>Three Car Park</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid CarParking
                        </Form.Control.Feedback>
                      </Form.Group> */}
                    </Row>
                    <Typography style={{ color: "gray", fontSize: 13 }}>
          Payment Source
        </Typography>
                    <Form.Group as={Col} md="4" controlId="paymentSource" className='d-flex flex-row align-items-center'>
       <div className='d-flex flex-row align-items-center'>
        <Form.Check
          type="checkbox"
          label="UPI"
          onChange={handlePaymentSourceChange}
          value="UPI"
        />
        <Form.Check
          type="checkbox"
          label="Bank"
          onChange={handlePaymentSourceChange}
          value="Bank"
        />
        <Form.Check
          type="checkbox"
          label="Cheque"
          onChange={handlePaymentSourceChange}
          value="Cheque"
        />
        <Form.Check
          type="checkbox"
          label="Online"
          onChange={handlePaymentSourceChange}
          value="Online"
        />
        </div>
      </Form.Group>

      {selectedPayments.includes("Online") && (
        <>
          <Form.Group as={Col} md="4" controlId="transferDetails">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Transaction Details
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Online", "transferDetails")}
              type="text"
              placeholder="Enter Transfer Details"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transfer Details
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineScreenshot">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "Online", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="onlineAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Online Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Online", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPayments.includes("Cheque") && (
        <>
          <Form.Group as={Col} md="4" controlId="chequeNumber">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Cheque Number
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Cheque", "chequeNumber")}
              type="text"
              placeholder="Enter Cheque Number"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Cheque Number
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Cheque Image
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "Cheque", "chequeImage")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Cheque Image
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="chequeAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Cheque Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Cheque", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPayments.includes("UPI") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              UPI Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "UPI", "transactionId")}
              type="text"
              placeholder="Enter UPI Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "UPI", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="upiAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            UPI Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "UPI", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      {selectedPayments.includes("Bank") && (
        <>
          <Form.Group as={Col} md="4" controlId="transactionId">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Bank Transaction ID
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Bank", "transactionId")}
              type="text"
              placeholder="Enter Transaction ID"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Transaction ID
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankImage">
            <Typography style={{ color: "gray", fontSize: 13 }}>
              Upload Screenshot
            </Typography>
            <Form.Control
              required
              type="file"
              onChange={(e) => handleFileChange(e, "Bank", "screenshot")}
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Upload Screenshot
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="bankAmtPaid">
            <Typography style={{ color: "gray", fontSize: 13 }}>
            Bank Amount Paid
            </Typography>
            <Form.Control
              required
              onChange={(e) => handleInputChange(e, "Bank", "amtPaid")}
              type="number"
              placeholder="Enter Amount Paid"
              style={{ padding: "0.42rem", marginBottom: 14 }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Valid Amount Paid
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      

<Form.Group as={Col} md="4" controlId="gstPercentage">
        <Typography style={{ color: "gray", fontSize: 13 }}>GST Percentage</Typography>
        <Form.Control
          type="number"
          placeholder="Enter GST Percentage"
          onChange={handleGstChangeBook}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>

      {/* Total Amount with GST */}
      <Form.Group as={Col} md="4" controlId="totalAmount">
        <Typography style={{ color: "gray", fontSize: 13 }}>Total Amount with GST</Typography>
        <Form.Control
          type="text"
          readOnly
          value={totalAmountBook.toFixed(2)}
          style={{ padding: "0.42rem", marginBottom: 14 }}
        />
      </Form.Group>
                  
                    {/* {/* <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <div style={{ marginBottom: 14 }}>
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Finance Type
                        </Typography>
                        <select
                          onChange={handleFinanceTypeChange}
                          style={{
                            height: "2.3rem",
                            color: "gray",
                            borderRadius: "0.3rem",
                            outline: "none",
                            width: "100%",
                            border: "0.7px solid lightgray",
                            ...(ValidatedbokOne && !financeTypeb ? { borderColor: 'red' } : {}),
                          }}
                        >
                          <option>--select--</option>
                          <option>PRE EMI</option>
                          <option>RERA</option>
                          <option>Customized</option>
                        </select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid finance Type
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    {/* {financeTypeb === "Customized" && (
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom02"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Installment Count
                        </Typography>
                        <Form.Control
                        required
                          type="number"
                          onChange={handleInstallmentCountChange}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Installment Count
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    {financeTypeb === "Customized" &&
                      installments.map((installment, index) => (
                        <Row key={index}>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`dueAmount${index}`}
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Due Amount
                            </Typography>
                            <Form.Control
                               required
                              type="text"
                              value={installment.dueAmount}
                              onChange={(e) =>
                                handleInstallmentChange(
                                  index,
                                  "dueAmount",
                                  e.target.value
                                )
                              }
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Due Amount
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`expectedDate${index}`}
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Expected Date
                            </Typography>
                            <Form.Control
                               required
                              type="date"
                              value={installment.expectedDate}
                              onChange={(e) =>
                                handleInstallmentChange(
                                  index,
                                  "expectedDate",
                                  e.target.value
                                )
                              }
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Expected Date
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      ))}  */}
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenbokOne(false);
                          setValidatedbokOne(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>


        {/*ChannelPartner Form*/}
   
        <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={openChannerlPForm}
            onClose={handleCloseChannelPForm}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <div style={{ width: "74rem" }}>
                <Form
                  noValidate
                  validated={validatedChannelP}
                  onSubmit={formValidationChannelPForm}
                >
                  <div className="mb-4 mt-1">
                    <center>
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                        width="145px"
                      />
                    </center>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Channel Partner Form
                    </Typography>
                  </div>
                  <Row className="mb-3">
                    <Row>
                     
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Name *
                        </Typography>
                        <Form.Control
                          type="text"
                          value={cpname}
                          onChange={(e) => setCpname(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Name
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Mobile{" "}
                        </Typography>
                        <Form.Control
                          
                          type="number"
                          value={cpmobile}
                          onChange={(e)=>setcpmobile(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Mobile
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Email{" "}
                        </Typography>
                        <Form.Control
                          value={cpemail}
                          type="text"
                          onChange={(e)=>setcpemail(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid email
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        RERA NO{" "}
                      </Typography>
                      <Form.Control
                        type="text"
                        placeholder="Enter RERA Number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                        value={reraNo}
                        onChange={(e) => setReraNo(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid RERA NO
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Aadhar Number
                      </Typography>
                      <Form.Control
                        placeholder="Enter Aadhar Number"
                        onChange={(e) => setCpAadharNumber(e.target.value)}
                        value={cpaadhar}
                        required
                        type="number"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter valid Aadhar Number
                      </Form.Control.Feedback>
                    </Form.Group>
{/* 
                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Channel Id
                      </Typography>
                      <Form.Control
                        required
                        placeholder="Enter Channel ID"
                        // onChange={(e) => setPanNumber(e.target.value)}
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Channel ID Number
                      </Form.Control.Feedback>
                    </Form.Group> */}
                  </Row>
                  <div className="d-flex">
                    <div>
                      <Button
                        type="submit"
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "rgb(126, 110, 228)",
                          color: "white",
                          marginRight: 3,
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setOpenChannelPForm(false);
                          setValidatedChannelPForm(false);
                        }}
                        style={{
                          width: "9rem",
                          height: "2.3rem",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
          </Dialog>
        </div>

{/*Bulk Upload Form*/}   


<div>
<Dialog
      style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
      maxWidth="sm"
      open={openBulkUploadForm}
      onClose={handleCloseBulkUploadForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div style={{ width: '34rem' }}>
          <Form noValidate  validated={validatedBulkUplaod}
                  onSubmit={formValidationBulkUplaodForm}>
            <div className="mb-4 mt-1">
              <center>
                <img
                  src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                  width="145px"
                  alt="User Icon"
                />
              </center>
              <Typography
                style={{
                  textAlign: 'center',
                  fontWeight: '500',
                  color: 'rgb(126, 110, 228)',
                  fontSize: 21,
                }}
              >
                Bulk Upload Form
              </Typography>
            </div>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Typography style={{ color: 'gray', fontSize: 13 }}>
                  Upload Excel File
                </Typography>
                <Form.Control
                  type="file"
              
                  accept=".xlsx, .xls"
                  onChange={(e)=>setBfile(e.target.files[0])} 
                  style={{ padding: '0.42rem', marginBottom: 14 }}
                />
              
              </Form.Group>
            </Row>
            <div className="d-flex">
              <div>
                <Button
                  type="submit"
                  style={{
                    width: '9rem',
                    height: '2.3rem',
                    backgroundColor: 'rgb(126, 110, 228)',
                    color: 'white',
                    marginRight: 3,
                  }}
                >
                  Submit
                </Button>
              </div>
              <div>
                <Button
                  onClick={handleCloseBulkUploadForm}
                  style={{
                    width: '9rem',
                    height: '2.3rem',
                    backgroundColor: 'gray',
                    color: 'white',
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
        </div>


        <Dialog open={openblockPop} onClose={handleCloseblockPop} maxWidth="md" fullWidth>
        <DialogTitle>Customer Details</DialogTitle>
        <DialogContent>
        {selectedCustomerblockPop && (
            <Box sx={{ padding: 2 }}>
              <div className="d-flex flex-row aling-items-center justify-content-around">
                <div>
              <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
                <strong>Name:</strong> {selectedCustomerblockPop.name}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Email:</strong> {selectedCustomerblockPop.email}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Mobile:</strong> {selectedCustomerblockPop.mobile}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Aadhar No:</strong> {selectedCustomerblockPop.aadharNo}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Sales Executive:</strong> {selectedCustomerblockPop.salesSource}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Flat No:</strong> {selectedCustomerblockPop.tflatNo}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Floor No:</strong> {selectedCustomerblockPop.tfloor}
              </Typography> 
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Tower No:</strong> {selectedCustomerblockPop.ttowerNo}
              </Typography> 
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Permanent Address:</strong> {selectedCustomerblockPop.permanentAddress}
              </Typography>
              </div>

            <div>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Bank Amount Paid:</strong> ₹{selectedCustomerblockPop.bank_amtPaid}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Cheque Amount Paid:</strong> ₹{selectedCustomerblockPop.cheque_amtPaid}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Online Amount Paid:</strong> ₹{selectedCustomerblockPop.online_amtPaid || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>UPI Amount Paid:</strong> ₹{selectedCustomerblockPop.upi_amtPaid || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Total Amount Paid:</strong> ₹{selectedCustomerblockPop.blockTotal || 'N/A'}
              </Typography> 
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Overall Status:</strong> {selectedCustomerblockPop.overall_status || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>GST Amount:</strong> ₹{selectedCustomerblockPop.gstAmount || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>GST Percentage:</strong> {selectedCustomerblockPop.gstPercentage || 'N/A'}%
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Signature:</strong> <a href={selectedCustomerblockPop.signature} target="_blank" rel="noopener noreferrer">View Signature</a>
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Bank Approval Date:</strong> {selectedCustomerblockPop.bank_approvalDate ? new Date(selectedCustomerblockPop.bank_approvalDate).toLocaleDateString() : 'N/A'}
              </Typography>
              </div>
              </div>
              {/* Add more fields as needed */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseblockPop } color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSitePop} onClose={handleCloseSitePop} maxWidth="md" fullWidth>
        <DialogTitle style={{Color: 'rgb(126, 110, 228)'}}>Site Visit Lead Details</DialogTitle>
        <DialogContent>
        {selectedCustomerSitePop && (
            <Box sx={{ padding: 2 }}>
              <div className="d-flex flex-row aling-items-center justify-content-around">
                <div>
              <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
                <strong>Name:</strong> {selectedCustomerSitePop.name}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Email:</strong> {selectedCustomerSitePop.email}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Mobile:</strong> {selectedCustomerSitePop.mobile}
              </Typography>
            
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Sales Executive:</strong> {selectedCustomerSitePop.salesExecutive}
              </Typography>
             
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Address:</strong> {selectedCustomerSitePop.location}
              </Typography>
              </div>
              
              <div>
              <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
                <strong>Apt Size:</strong> {selectedCustomerSitePop.aptsize}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Facing:</strong> {selectedCustomerSitePop.facing}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Cornor:</strong> {selectedCustomerSitePop.cover}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Comment:</strong> {selectedCustomerSitePop.commentSiteVisit}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Under Construction:</strong> {selectedCustomerSitePop.ucon}
              </Typography>
             
            
              </div>
        
              </div>
              {/* Add more fields as needed */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSitePop } color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {selectedCustomerBookPop && (
        <CustomerDetailDialog
          open={openBookPop}
          onClose={handleCloseBookPop}
          customer={selectedCustomerBookPop}
        />
      )}

    </>
  );
}
