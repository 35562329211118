import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, TableBody, TableCell, TableHead, TableRow, Box, Typography, Pagination, Modal, Button } from "@mui/material";
import { FaPhone } from "react-icons/fa";
import { makeStyles } from "@mui/styles";

// Custom Styles for Modal
const useStyles = makeStyles({

    modalContanier:{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center'
    },

  modalContent: {
    display: 'flex',
    flexDirection: 'column',

    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    maxWidth: '600px',

    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflowY:'auto',
    height:'100vh',
    margin:'20px'
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  sectionContent: {
    marginBottom: '15px',
  },
});

const LeadHistory = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [cpage, setCpage] = useState(1); // Current page
  const [openModal, setOpenModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null); // For storing selected lead details
  const page = 10; // Number of items per page
  const classes = useStyles();

  const PrName = sessionStorage.getItem("ProjectName");

  useEffect(() => {
    // Function to fetch data from API
    const getlm = () => {
      axios.get("https://buildfastapi.kollagroup.in/api/v1/lead-data").then((res) => {
        setData(res.data.data);
        localStorage.setItem("adminCount", res.data.data.length); // Store the total count only once
      });
    };

    getlm();  // Call the function to fetch data initially
  }, []);  // Empty dependency array to run once on mount

  const leadDataSearch = (text) => {
    axios
      .post("https://buildfastapi.kollagroup.in/api/v1/search-lead", { text })
      .then((res) => {
        setData(res.data);
        localStorage.setItem("adminCount", res.data.length); // Update count on search
      });
  };

  const pagehandle = (event, newPage) => {
    setCpage(newPage); // Update the current page when a new page is selected
  };

  const openLeadDetails = (lead) => {
    setSelectedLead(lead);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const paginatedData = data
    .filter((item) => item.ProjectName === PrName)
    .slice((cpage - 1) * page, cpage * page);

  return (
    <div>
      <div
        className="card mt-2"
        style={{
          border: "none",
          paddingLeft: 15,
          paddingRight: 15,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <input
            onChange={(e) => leadDataSearch(e.target.value)}
            style={{
              height: "2.3rem",
              color: "gray",
              borderRadius: "0.3rem",
              outline: "none",
              width: "26rem",
              border: "0.7px solid lightgray",
            }}
            placeholder="Search..."
          />
        </div>
        <div
          style={{
            width: "9rem",
            height: "2.3rem",
            fontWeight: "bold",
          }}
        >
          Total Leads : {data.filter((item) => item.ProjectName === PrName).length}
        </div>
      </div>

      <Table style={{ position: "relative", top: "1.3rem" }}>
        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TableCell align={"start"} style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>
              Name
            </TableCell>
            <TableCell align={"start"} style={{ top: 57, fontWeight: "600" }}>
              Call
            </TableCell>
            <TableCell align={"left"} style={{ top: 57, fontWeight: "600" }}>
              Gender
            </TableCell>
            <TableCell align={"start"} style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>
              Location
            </TableCell>
            <TableCell align={"left"} style={{ top: 57, fontWeight: "600" }}>
              Created Date
            </TableCell>
            <TableCell align={"start"} style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>
              Source Type
            </TableCell>
            <TableCell align={"start"} style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}>
              Channel Partner
            </TableCell>
            <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
              Comment
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((item) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={item.Id} onClick={() => openLeadDetails(item)}>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                {item.name}
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                <a href={`tel:${item.mobile}`} style={{ textAlign: "center", marginTop: "20px" }}>
                  <FaPhone />
                </a>
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                {item.gender}
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                {item.location}
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12, width: 100 }}>
                {item.pretime}
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12, width: 70 }}>
                {item.sourceType}
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12 }}>
                {item.channelPartner === "Yes" ? item.cpName : "No"}
              </TableCell>
              <TableCell align={"start"} style={{ top: 57, fontSize: 12, width: 100 }}>
                {item.commentBox}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: 56,
          marginTop: 16,
        }}
      >
        <Pagination
          count={Math.ceil(data.filter((item) => item.ProjectName === PrName).length / page)}
          page={cpage}
          onChange={pagehandle}
          color="secondary"
          sx={{ "& .Mui-selected": { backgroundColor: "blue" } }}
        />
      </div>

      {/* Modal for Lead Details */}
      <Modal open={openModal} onClose={closeModal} className={classes.modalContanier}>
        <Box className={classes.modalContent}>
          <Typography variant="h6" className={classes.sectionTitle} style={{textAlign:'center',color:'rgb(126, 110, 228)'}}>Lead History</Typography>
          <div className={classes.sectionContent}>
            <strong>Name:</strong> {selectedLead?.name}
          </div>
          <div className={classes.sectionContent}>
            <strong>Email:</strong> {selectedLead?.email}
          </div>
          <div className={classes.sectionContent}>
            <strong>Gender:</strong> {selectedLead?.gender}
          </div>
          <div className={classes.sectionContent}>
            <strong>Location:</strong> {selectedLead?.location}
          </div>
          <div className={classes.sectionContent}>
            <strong>Source Type:</strong> {selectedLead?.sourceType}
          </div>
          <div className={classes.sectionContent}>
            <strong>Sales Executive:</strong> {selectedLead?.salesExecutive}
          </div>

          <Typography variant="h6" className={classes.sectionTitle} style={{color:'rgb(126, 110, 228)'}}>Interest</Typography>
          <div className={classes.sectionContent}>
            <strong>Interest Date:</strong> {selectedLead?.intrestDate}
          </div>
          <div className={classes.sectionContent}>
            <strong>Interest Comment:</strong> {selectedLead?.intrestComment}
          </div>

          <Typography variant="h6" className={classes.sectionTitle} style={{color:'rgb(126, 110, 228)'}}>Callback</Typography>
          <div className={classes.sectionContent}>
            <strong>Callback Date:</strong> {selectedLead?.cbDate}
          </div>
          <div className={classes.sectionContent}>
            <strong>Callback Comment:</strong> {selectedLead?.cbComment}
          </div>

          <Typography variant="h6" className={classes.sectionTitle} style={{color:'rgb(126, 110, 228)'}}>Not Interested</Typography>
          <div className={classes.sectionContent}>
            <strong>Not Interested Comment:</strong> {selectedLead?.notintrstComment}
          </div>
          <div className={classes.sectionContent}>
            <strong>Reason:</strong> {selectedLead?.notintrstReason}
          </div>

          <Typography variant="h6" className={classes.sectionTitle} style={{color:'rgb(126, 110, 228)'}}>Site Visit</Typography>
          <div className={classes.sectionContent}>
            <strong>Site Visit Date:</strong> {selectedLead?.sitevisitDate}
          </div>
          <div className={classes.sectionContent}>
            <strong>Site Visit Comment:</strong> {selectedLead?.commentSiteVisit}
          </div>
          <div className={classes.sectionContent}>
            <strong>Apt Size:</strong> {selectedLead?.aptsize}
          </div>
          <div className={classes.sectionContent}>
            <strong>Interest Floor:</strong> {selectedLead?.interestfloor}
          </div>
          <div className={classes.sectionContent}>
            <strong>Facing:</strong> {selectedLead?.facing}
          </div>
          <div className={classes.sectionContent}>
            <strong>RM Date:</strong> {selectedLead?.rmdate}
          </div>

          <Button onClick={closeModal} variant="contained" color="primary" style={{ marginTop: "20px" }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default LeadHistory;
